@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* General css  */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Google Sans";
  src: url(../fonts/Pangram-Regular.otf);
}

@font-face {
  font-family: Pangram-Light;
  src: url(../fonts/Pangram-Light.otf);
}

@font-face {
  font-family: Pangram-Medium;
  src: url(../fonts/Pangram-Medium.otf);
}

.annual_income_tooltip {
  display: flex !important;
  align-items: center;
  gap: 6px;
}

.annual_income_tooltip .infodorpdown ul.tooltip {
  width: fit-content;
  padding: 0px 0.5rem !important;
}

.annual_income_tooltip .infodorpdown ul.tooltip li p {
  text-align: left;
  white-space: nowrap;
  font-size: 0.94vw;
}

@font-face {
  font-family: Pangram-Bold;
  src: url(../fonts/Pangram-Bold.otf);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #253837;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}

select {
  appearance: none !important;
  /* --------------------new responsive css (heena)----------------- */
  /* background: url("../img/down_arrow.svg") no-repeat right 16px center !important; */
  background: url("../img/down_arrow.svg") no-repeat right 1.17vw center !important;
  background-size: 1vw 1vw !important;
}

.form-select:focus {
  box-shadow: none;
  border: 1px solid #253837 !important;
}

select.form-select {
  box-shadow: none;
}

.link-bar,
.terms-bar a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}

.terms-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sub-link.disabled_nav {
  opacity: 0.4;
  pointer-events: none;
}

.link-bar:hover,
.terms-bar a:hover {
  color: #a3eb64;
}

.auth-form input:-webkit-autofill,
.auth-form input:-webkit-autofill:hover,
.auth-form input:-webkit-autofill:focus {
  -webkit-text-fill-color: #000000;
}

:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

button:focus {
  outline: none;
}

#header .header-left .firstaside .tooltipbar {
  display: none;
}

a {
  text-decoration: none;
}

#header .header-left .firstaside {
  flex: 0 0 50%;
}

.firstaside h6 {
  display: inline-block;
  margin: 10px 0 0 0;
  border-radius: 8px;
  background: rgb(243 70 62 / 8%);
  padding: 12px;
  color: #f3463e;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

a:hover {
  text-decoration: none;
}

p {
  font-size: 24px;
  margin-bottom: 0;
  color: #212121;
}

.auth-email-div .mail-icon {
  height: 1.528vw;
}

.resend-otp-text {
  font-size: 0.972vw;
}

.otp-Verification .login-otp-box.forminput input[type="tel"] {
  width: 5.208vw !important;
}

.auth-email-div {
  display: flex;
  padding: 0.972vw 1.597vw;
  border-radius: 0.694vw;
  background: #f5f5f5;
  gap: 1.806vw;
  margin: 1.944vw 0vw;
  align-items: center;
}

.margin-top-12px {
  margin-top: 0.833vw;
}

body .done-btn {
  padding: 0.556vw 1.042vw;
  gap: 0.556vw;
  align-items: center;
  position: absolute;
  right: 0;
}

.otp-Verification .otp-heading {
  color: #121212;
  font-family: Poppins;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-bottom: 0.833vw;
}

.auth-email-div-label {
  color: #9a9a9a;
  font-family: Poppins;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.auth-email-div-p {
  color: #121212;
  font-family: Poppins;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.auth-email-div-inner {
  justify-items: left;
}

.paymentmodalblock h2 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
}

a.whatsapp-logo {
  position: fixed;
  right: 2.083vw;
  bottom: 2.083vw;
  z-index: 9999;
  display: block !important;
}

a.whatsapp-logo img {
  height: 3.333vw;
  width: 3.333vw;
}

.paymentmodalblock .paymentmodal form {
  max-width: 60%;
  margin: 0 auto;
}

.paymentmodalblock .paymentmodal form button {
  background: var(--base-primary);
  border: 1px solid var(--text-white);
  border-radius: 14px;
  box-shadow: none !important;
  padding: 12px 30px;
  color: #ffff;
  margin-top: 20px !important;
  max-width: 100px !important;
  margin: 0 auto;
}

.provide-doc-info {
  margin: 0px !important;
  margin-bottom: 0.694vw !important;
  background: #fdf1eb !important;
  padding: 0.556vw !important;
  border-radius: 0.556vw !important;
}

.provide-doc-info p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.provide-doc-info svg {
  width: 1.389vw;
  height: 1.389vw;
  margin-right: 0.556vw;
}

.provide-doc-info span {
  font-size: 0.833vw;
  line-height: 2.083vw;
  display: inline-block;
}

.iconinfo svg {
  height: 1.667vw;
  width: 1.667vw;
}

.uploadDocPlaceholder {
  height: 3.75vw;
  width: 3.75vw;
}

.tennat-left-box-top .sidebar-inner-heading {
  margin-bottom: 0.556vw;
}

.paymentmodalblock .paymentmodal h1 {
  font-size: 24px;
  color: #121c1b;
  margin: 0 0 5px 0;
}

.paymentmodalblock .paymentmodal p {
  margin-bottom: 30px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

section,
header,
footer {
  width: 100%;
}

section {
  padding: 50px 0px;
}

body {
  /*background-color: red;*/
  font-family: "Google Sans" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--base-default-text);
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  /* background-color: #fafafa; */
}

/* Colors */
:root {
  /* --base-primary: #002358; */
  --base-primary: #253837;
  --base-primary-light: #fff3f0;
  --base-secondry: #212121;
  --base-darkgray: #797979;
  --base-lightgray: #ececec;
  --form-border: #c4c4c4;
  --form-placeholder: #cacaca;
  --text-label: #9a9a9a;
  --tab-bg-gray: #fafafa;
  --border-light: #dddddd;
  --text-white: #ffffff;
  --text-black: #000000;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

ul.autocomplete_ul {
  position: absolute;
  background: #ffffff;
  padding: 0;
  z-index: 99;
  max-height: 13.889vw;
  overflow: scroll;
  border-radius: 0.694vw;
  border: 1px solid #c4c4c4;
  width: 100%;
  left: 0px;
}

li.autocomplete_li:hover {
  background: lightgray;
}

li.autocomplete_li {
  padding: 0.417vw 1.042vw;
  text-align: left;
  border-bottom: 1px solid lightgrey;
  font-size: 0.972vw;
  line-height: 1;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--base-secondry);
}

.Auth-wrapper .logobar {
  margin-bottom: 30px;
}

.Auth-wrapper-3 .logobar > .container {
  display: flex;
  justify-content: space-between;
}

p {
  font-weight: 400;

  font-size: 18px;
  line-height: 30px;
  color: var(--base-primary);
}

ul li,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body .padding-full-zero {
  padding: 0 !important;
}

a {
  cursor: pointer;
  color: var(--base-primary);
  text-decoration: none;
}

img {
  max-width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.onfido-sdk-ui-Theme-modalOverlay {
  z-index: 999 !important;
}

body .hover-dropdown a:hover {
  background-color: unset;
}

.btn {
  padding: 0.972vw 3vw;
  border-radius: 0.833vw;
  font-weight: 500;
  font-size: 1.042vw;
  line-height: 1.667vw;
  text-align: center;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}

#left-menu ul li a span svg {
  font-size: 1.806vw;
  margin-right: 0.694vw;
}

a.btnlink {
  color: #ef7833;
  /* font-family: Pangram; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

ul.numbering .list {
  position: absolute;
  color: #b8b8b8;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

ul.numbering .list1 {
  position: absolute;
  bottom: 120px;
  left: 52px;
}

ul.numbering .list2 {
  position: absolute;
  bottom: 204px;
  left: 70px;
}

ul.numbering .list3 {
  bottom: 270px;
  left: 150px;
}

ul.numbering .list4 {
  bottom: 270px;
  left: inherit;
  right: 155px;
}

ul.numbering .list5 {
  bottom: 204px;
  left: inherit;
  right: 70px;
}

ul.numbering .list6 {
  bottom: 120px;
  left: inherit;
  right: 34px;
}

.gradient-progress-block {
  position: relative;
  height: 300px;
}

.forminput.auth-form.phonenumber-input {
  margin: 0;
}

body .property-filter > .filterDiv {
  flex: 0 0 25%;
}

.awarded-btn {
  background: #a3eb64 !important;
  border-color: #a3eb64 !important;
  color: #253837 !important;
  border-radius: 8px;
  padding: 6px 24px;
  font-size: 14px;
  border: none;
  outline: none;
  margin-block-start: 6px;
}

.btn.skip-btn {
  border-radius: 18px;
  background: #f4f4f4;
  padding: 15px;
  color: #253837;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.forminput.auth-form.phonenumber-input .react-tel-input .form-control {
  border-radius: 12px;
}

.forminput.auth-form.phonenumber-input .react-tel-input .flag-dropdown,
.forminput.auth-form.phonenumber-input
  .react-tel-input
  .flag-dropdown
  .selected-flag {
  border-radius: 12px 0 0 12px;
}

.custom-input.disabled-select {
  background: #f2efef;
}

.CircularProgressbar {
  height: 17.361vw !important;
}

#navbar_ll_image {
  width: 100%;
  /* Make the image fill the div */
  height: 100%;
  /* Make the image fill the div */
  object-fit: cover;
  /* Ensure the image maintains aspect ratio and covers the whole div */
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: url(#progressGradient) !important;
}

select.custom-input.input-selected {
  /* border-radius: 14px; */
  font-weight: 400;
  outline: none;
  padding-bottom: 0;
  /* width: 54px; */
  width: 100%;
  background: white;
  color: black;
  /* --------------------new responsive css (heena)----------------- */
  /* border: 1px solid #c4c4c4 !important; */
  border: 0.066vw solid #c4c4c4 !important;
  /* border-radius: 14px; */
  /* border-radius: 12px; */
  border-radius: 0.8vw;
  /* font-size: 14px; */
  font-size: 0.933vw;
  /* height: 62px; */
  height: 4.133vw;
  /* margin-bottom: 20px; */
  margin-bottom: 1.333vw;
  /* padding-left: 30px; */
  padding-left: 2vw;
}

.landlord_homepage .addpropertybar h4 {
  color: #223635;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.landlord_homepage .addpropertybar p {
  color: #b8b8b8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.Property-wrapper .authbar-inner .resend-text .auth-form {
  margin: 1.667vw 0 0 0;
}

.Property-wrapper .authbar-inner .resend-text .auth-form .form-control:focus {
  border-color: #c1f099;
}

.Property-wrapper .authbar-inner .resend-text .auth-form .forminput {
  position: relative;
}

.tabs-profile {
  margin-top: 30px;
}

.shareQr .btngreen {
  background: #a3eb64 !important;
  border-color: #a3eb64 !important;
}

.btngreen {
  background: #a3eb64 !important;
  border-color: #a3eb64 !important;
  color: var(--Dark-green, #253837) !important;
}

.btndarkgreen {
  background: #253837 !important;
  border-color: #253837 !important;
  color: #a3eb64 !important;
}

.calvepin-option-div p {
  color: #fff;
  font-size: 0.972vw;
  line-height: 2.083vw;
}

.calvepin-option-div h4 {
  color: #fff;
  font-size: 1.389vw;
  font-weight: 700;
}

.calvepin-option-div h5 {
  color: #fff;
  font-size: 1.111vw;
  font-weight: 600;
}

.white-text {
  color: #ffffff;
}

.underline {
  text-decoration: underline;
}

.doc_upload_div_frame {
  background: #f6fdef;
  border-radius: 0.556vw;
  box-shadow: 0vw 0.278vw 2.361vw 0.069vw rgba(0, 0, 0, 0.03);
  justify-items: center;
}

.calvepin-option-div {
  background: #253837;
  border-radius: 1.042vw;
  padding: 1.667vw;
}

.flag-dropdown .selected-flag .flag {
  width: 15px !important;
  height: 11px !important;
}

.react-tel-input .country-list .flag {
  width: 15px !important;
  height: 11px !important;
}

.ledger_data {
  color: #7f7f7f !important;
  font-family: "Google Sans" !important;
  font-size: 0.833vw !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.UnstableText {
  color: #fff;
  font-weight: 700;
}

.redbg {
  border-radius: 8px;
  padding: 12px 18px;
  background: #f3483e;
}

.redbg p {
  color: #fff;
  font-weight: 700;
}

.skor_info p {
  font-size: 1.25vw;
}

.VariableText {
  color: #fff;
}

.pinkbg {
  border-radius: 8px;
  padding: 12px 18px;
  background: #f77841;
}

.pinkbg p {
  color: #fff;
  font-weight: 700;
}

.StableText {
  color: #fff;
  font-weight: 700;
}

.yellowbg {
  border-radius: 8px;
  padding: 12px 18px;
  background: linear-gradient(270deg, #b4cc23 28.33%, #f5dd01 70.82%);
}

.yellowbg p {
  color: #fff;
  font-weight: 700;
}

.ReliableText {
  color: #fff;
  font-weight: 700;
}

.lightbg {
  border-radius: 8px;
  padding: 12px 18px;
  background: #99de04;
}

.lightbg p {
  color: #fff;
  font-weight: 700;
}

.ExtremelyText {
  color: #fff;
  font-weight: 700;
}

.greenbg {
  border-radius: 8px;
  padding: 12px 18px;
  background: #05c200;
}

.greenbg p {
  color: #fff;
}

.skor_info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
}

.summary-sub-heading {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.status-bar h3 {
  font-size: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  margin: 0px !important;
}

.btn-fill {
  /* background: var(--base-primary); */
  background: #f27833 !important;
  border: 1px solid var(--text-white);
  border-radius: 14px;
  box-shadow: none !important;
}

.btn-fill:hover,
.btn-fill:active {
  background: #ffffff !important;
  /* color: var(--base-primary) !important;
  border-color: var(--base-primary) !important; */
  color: #f27833 !important;
  border-color: #f27833 !important;
}

.btn-fill svg {
  margin-right: 10px;
}

.Auth-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-control {
  background: #fff;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-family: "Google Sans";

  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 12px;
  border: 1px solid #c4c4c4; */
  border-radius: 0.88vw;
  border: 0.073vw solid #c4c4c4;
  /* padding: 16px; */
  padding: 1.17vw;
  /* font-size: 14px; */
  font-size: 1.02vw;
  /* line-height: 24px; */
  line-height: 1.76vw;
}

.gurantor_box {
  padding: 15px;
  background: #fff4ed;
  border-radius: 8px;
}

.gurantor_box h4 {
  text-align: left !important;
}

.email_flex {
  display: flex;
  gap: 5px;
}

.input-with-eye {
  position: relative;
}

.input-with-eye .eye-icons-reset {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0px, -50%);
}

.input-with-eye .form-control {
  padding-right: 35px;
}

.incomes_main_div {
  border-radius: 8px;
  background: #fff4ed;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
}

.rent-slider .MuiSlider-thumb {
  height: 1vw !important;
  width: 1vw !important;
  border: 2px solid #253837;
}

.css-1o63r9r-MuiSlider-root {
  margin-inline: 0.694vw !important;
}

.rent-slider .MuiSlider-markLabel {
  font-size: 1.111vw;
}

.MuiSlider-thumb {
}

.form-control:hover,
.form-control:focus,
.form-control:active {
  outline: 0;
  box-shadow: none;
}

.step-form-main-wrapper .steps-wrapper .step-btns-group button.bg-none {
  background: transparent !important;
  color: #223635 !important;
}

.forminput {
  margin-bottom: 1.389vw;
  position: relative;
  font-size: 1.389vw;
}

.step-form-main-wrapper .resend-text {
  padding: 1.25vw;
}

.step-form-main-wrapper .resend-text .step-inner-head {
  color: #9a9a9a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.step-form-main-wrapper .resend-text .step-inner-desc {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 11px 0 24px 0;
}

.step-form-main-wrapper .resend-text .help-text {
  border-radius: 6px;
  border: 1px solid var(--Light-Green, #a3eb64);
  background: rgba(163, 235, 100, 0.1);
  color: #253837;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  text-transform: capitalize;
  padding: 12px 15px;
}

.step-form-main-wrapper .resend-text,
.step-form-main-wrapper .resend-text p {
  text-align: left;
}

.btngroup .btnoutline.bg-none {
  background: transparent !important;
  color: #223635 !important;
}

.cust-card-wrapper {
  background-image: url("../../../public/images/card-bg.png");
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1.667vw 1.944vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 1.25vw;
}

.Milestones-dates {
  border-radius: 0.833vw;
  border: 1px solid #ddd;
  background: #fff;
  padding: 1.042vw;
}

.overflow-for-mob {
  overflow: auto;
}

.Milestones-dates .Milestones-head {
  color: #7f7f7f;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Milestones-dates.mobile {
  padding: 0;
}

.Milestones-dates .mobile-address {
  background: #f4f4f4;
  border-radius: 11.995px 11.995px 1rem 1rem;
  padding: 1rem 0.5rem;
}

.Milestones-dates .mobile-address .image-container {
  flex: 1;
}

.Milestones-dates .mobile-address .image-container .image {
  width: 48px;
}

.Milestones-dates .mobile-address .heading {
  flex: 3;
}

.cust-card-wrapper * {
  color: #fff;
}

.form-control::placeholder {
  color: #cacaca;
}

.stepper-wrapper {
  margin-top: 0.347vw;
  display: flex;
  /* justify-content: space-between; */
  /* margin-bottom: 0.694vw; */
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 16%;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 0.139vw solid #ccc;
  width: 100%;
  top: 0.903vw;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 0.139vw solid #ccc;
  width: 100%;
  top: 0.903vw;
  left: 50%;
  z-index: 2;
}

.stepper-item:not(.completed) .step-counter::after {
  content: "";
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 2px;
  left: 2px;
  background: #fff;
  border-radius: 50%;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.806vw;
  height: 1.806vw;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 0.417vw;
}

.upload_doc_info .info_div h4 {
  color: #223635;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upload_doc_info .info_div p {
  color: #7f7f7f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.document_preview_div h5 {
  color: #253837;
  font-style: normal;
  font-weight: 500;

  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  /* line-height: 160%; 22.4px */
  /* padding-top: 18px; */
  font-size: 1.17vw;
  line-height: 1.64vw;
  padding-top: 1.32vw;
}

.document_preview_div {
  /* --------------------new responsive css (heena)----------------- */
  /* border-top: 1px solid #d7dce4;
  margin-top: 18px; */
  border-top: 0.07vw solid #d7dce4;
  margin-top: 1.32vw;
}

.boxes .empty_checkbox {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #e0e0e0;
}

.share_landlord_info {
  color: #aaaaaa;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 24px; */
  font-size: 1.76vw;
  text-align: center;
  align-items: center;
}

.customradiobar:not(.card-fee) .boxes img,
.customradiobar:has(.boxes) input {
  display: none;
}

.customradiobar .boxes {
  display: flex;
  gap: 12px;
  align-items: center;
}

.customradiobar.card-fee .boxes {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.833vw;
}

.customradiobar.card-fee {
  padding: 0 0.833vw;
}

.customradiobar.card-fee img,
.customradiobar.card-fee svg {
  height: 1.944vw;
  width: 1.944vw;
}

.customradiobar.card-fee .boxes .orange_text {
  width: 100%;
  border-radius: 0.417vw;
  background-color: #fdf2eb;
  padding: 0.694vw 0.833vw;
  font-size: 1.111vw;
  font-weight: 700;
  color: #ef7833 !important;
}

.customradiobar.card-fee .boxes p {
  font-size: 1.25vw;
  line-height: 2.083vw;
}

.clave-checboxes .customradiobar.forminput {
  margin-bottom: 12px;
}

.customradiobar input:checked + .boxes {
  border: 2px solid #a3eb64;
}

.customradiobar input:checked + .boxes img {
  display: inline-block;
}

.customradiobar input:checked + .boxes span {
  display: none;
}

.stepper-item .step-counter img {
  width: 0.833vw;
  height: 0.694vw;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #a392ef;
}

.Milestones-dates {
  position: relative;
  z-index: 1;
}

.step-form-main-wrapper .steps-wrapper:has(.step-sub-head) .step-name {
  margin-bottom: 8px;
}

.icon-text-box {
  display: flex;
  gap: 1.25vw;
  margin-bottom: 1.667vw;
}

.icon-text-box > img {
  width: 2vw;
  height: 2vw;
}

.icon-text-box .text-box .box-heading {
  color: #253837;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.icon-text-box .text-box .box-desc {
  color: #7f7f7f;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-main-heading + .btn-close {
  padding: 0.556vw;
  height: 0.2vw;
  width: 0.2vw;
  background-size: cover;
}

.step-form-main-wrapper .step-absolute-header {
  position: relative;
  justify-content: center;
  margin-bottom: 2.361vw;
}

.spacing-setting-wrapper.step-form-main-wrapper .step-absolute-header {
  margin-bottom: 0;
}

.spacing-setting-wrapper.step-form-main-wrapper {
  padding: 1.042vw 3.056vw;
}

.step-form-main-wrapper .step-absolute-header .back-btn {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left !important;
}

.step-form-main-wrapper .steps-wrapper .note-text {
  color: #7f7f7f;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 24px;
}

.step-form-main-wrapper .steps-wrapper .step-sub-head {
  color: #7f7f7f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 34px;
  text-align: center;
}

/* .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #a392ef;
    width: 100%;
    top: 13px;
    left: 50%;
    z-index: 3;
  } */

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.stepper-item.completed:not(:first-of-type)::before {
  border-bottom: 2px solid #a392ef;
}

/* Custom Css start here */

.Auth-bar {
  padding: 0px;
}

.Auth-wrapper {
  min-height: 100vh;
  background-color: var(--base-primary);
}

.Auth-wrapper-3 {
  min-height: 100vh;
  background-color: #a392ef;
}

#wrapper-3-logo {
  margin: 0 auto;
}

.rightbar {
  padding-left: 0px !important;
}

.card_select_modal div.modal-dialog {
  max-width: 55.556vw;
}

.Auth-bar .authbar .container {
  height: 100%;
  display: block;
  max-width: 100%;
  padding: 0 5vw;
}

.hero-foot {
  display: flex;
  align-items: flex-end;
  padding-top: 2.09vw;
}

.btn-100 {
  width: 100%;
}

.Auth-bar .authbar .container .authbar-inner .leftbar img.ie_student_pic {
  width: 40vw !important;
  position: fixed;
  left: 0vw;
}

.step-form-main-wrapper .steps-wrapper .step-btns-group button.btnoutline {
  background: transparent !important;
  color: #223635 !important;
  white-space: nowrap;
}

.cards-wrapper {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 26px; */
  /* flex-wrap: wrap; */
  /* margin-top: 1.25vw; */
}

.stepper-item .step-name {
  color: #a392ef;
  text-align: center;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cards-wrapper > div {
  flex: 0 0 16%;
  padding: 0.556vw !important;
}

.address-p {
  color: #253837;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cust-card-wrapper .card-heading {
  color: #fff;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cust-card-wrapper .card-content .card-content-image {
  margin-bottom: 1.111vw;
  width: 3.75vw;
  height: 3.75vw;
}

.view-all-rent {
  font-size: 1.25vw;
}

.cust-card-wrapper .card-content .card-content-heading {
  color: #fff;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.417vw;
}

.cust-card-wrapper .card-content .card-desc {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cards-wrapper .date-card {
  border-radius: 0.833vw;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  text-align: center;
}

.cards-wrapper .date-card .date-card-header {
  padding: 0.556vw 1.25vw;
  background-color: #a3eb64;
  color: #253837;
  text-align: center;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.padd-set-for-col {
  padding: 0 0.833vw;
}

.cards-wrapper .date-card .date-card-footer {
  padding: 0.833vw 0.625vw 0.625vw;
}

.cards-wrapper .date-card .date-card-footer .card-date {
  color: #253837;
  text-align: center;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cards-wrapper .date-card .date-card-footer .card-year {
  color: #7f7f7f;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
}

.hero-foot .terms-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 1.3vw;
}

.hero-foot .terms-bar .link-bar {
  color: #9c9c9c;
  font-size: 0.98vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.Auth-bar .authbar .container .authbar-inner .leftbar .hero-center h2 {
  font-family: "Google Sans";
  margin-bottom: 65px;
}

.Auth-bar .authbar .container .authbar-inner .rightbar .logo-img {
  display: none;
}

.Auth-wrapper-3 .Auth-bar .authbar .container .authbar-inner {
  height: auto;
}

.Auth-wrapper-3 .logobar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Auth-wrapper-3 .hero-foot .terms-bar .link-bar {
  color: #ffffff !important;
}

.custom-input.phone_input.PhoneInput .PhoneInputInput {
  border: none !important;
}

.react-tel-input.custom-input.phone_input {
  border: none !important;
  padding-left: 0px !important;
}

.react-tel-input.custom-input.phone_input .form-control {
  height: 100% !important;
  width: 100% !important;
}

.react-tel-input.phone_input .form-control {
  width: 100% !important;
}

/* --------------------new responsive css (heena)----------------- */
.info-wapper .react-tel-input .form-control {
  font-size: 0.933vw;
  padding-left: 3.2vw;
  border: 0.066vw solid #cacaca;
  border-radius: 0.333vw;
  line-height: 1.667vw;
}

.info-wapper .react-tel-input .flag-dropdown {
  border: 0.066vw solid #cacaca;
  border-radius: 0.2vw 0 0 0.2vw;
}

.expiry-skor-date span:first-child {
  border-right: 1px solid #c5d5d4;
}

.expiry-skor-date span {
  padding: 0px 12px;
  color: #728685;
  text-align: right;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.expiry-skor-date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.278vw;
}

.blur-info-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(225, 225, 225, 0.72);
  color: #253837;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  flex-direction: column;
  backdrop-filter: blur(5px);
}

.info-wapper .react-tel-input .selected-flag {
  width: 2.533vw;
  padding: 0 0 0 0.533vw;
  border-radius: 0.2vw 0 0 0.2vw;
}

.info-wapper .react-tel-input .selected-flag .arrow {
  margin-top: -0.133vw;
  left: 1.333vw;
  border-left: 0.2vw solid transparent;
  border-right: 0.2vw solid transparent;
  border-top: 0.266vw solid #555;
}

.info-wapper .react-tel-input .flag-dropdown .country-list {
  margin: 0.667vw 0 0.667vw -0.067vw;
  box-shadow: 0.067vw 0.133vw 0.667vw rgba(0, 0, 0, 0.35);
  width: 20vw;
  max-height: 13.333vw;
  border-radius: 0 0 0.2vw 0.2vw;
}

.info-wapper .react-tel-input .flag-dropdown .country-list .country {
  padding: 0.467vw 0.6vw;
}

.info-wapper .react-tel-input {
  font-size: 1vw;
}

.info-wapper .react-tel-input .flag-dropdown .country-list .country .flag {
  margin-right: 0.467vw;
  margin-top: 0.133vw;
  width: 1.067vw;
  height: 0.733vw;
}

.info-wapper .react-tel-input .flag-dropdown .country-list .country-name {
  margin-right: 0.4vw;
}

/* --------------------end new responsive css (heena)----------------- */

.Auth-wrapper-3.otp-page {
  display: flex;
  flex-direction: column;
  padding: 45px 0 !important;
}

.Auth-wrapper-3 .otp-page-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Auth-bar .authbar .container .authbar-inner {
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 00px 0px 0 0;
  position: relative;
}

.Auth-bar .authbar .container .authbar-inner .leftbar,
.Auth-bar .authbar .container .authbar-inner .rightbar {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 0px;
}

.Auth-bar .authbar .container .authbar-inner .leftbar h2 {
  margin: 0;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-transform: capitalize;
  color: var(--text-white);
}

h4.text-none {
  text-transform: none !important;
}

.Auth-wrapper .logobar img.company-logo {
  height: 5.5vw ;
  display: block;
}

.Auth-wrapper .logobar img.power-by-logo {
  height: 2vw !important;
  width: auto !important;
  display: block;
  margin-left:auto;
  margin-top:0.556vw;
}


.Auth-wrapper .logobar img:not(.company-logo) {
  width: 20vw;
  height: 3.7vw;
  object-fit: contain;
}

.Auth-bar .authbar .container .authbar-inner .leftbar img {
  width: 32vw;
  position: absolute;
  bottom: 0;
  left: -3vw;
}

.Auth-bar .authbar .container .authbar-inner .leftbar img.signp_img {
  left: -8vw;
  bottom: 12vw;
  width: 50vw;
}

.Auth-bar .authbar .container .authbar-inner .rightbar .auth-shapetopright {
  position: absolute;
  top: 3vw;
  right: 5vw;
  width: 8vw;
}

.property_details_100 {
  width: 100% !important;
}

.property_details_100 span.cl-text {
  max-width: 100% !important;
}

#property_reference {
  border-radius: 0.694vw;
  padding: 0.694vw;
  margin-top: 1.389vw;
  font-size: 1.111vw;
}

.address-popup-mobile .btnoutline svg {
  width: 0.972vw;
  height: 0.972vw;
}

.filter-div {
  background: #ffffff;
  border: 0.6px solid #dddddd;
  padding: 0.694vw;
  border-radius: 0.556vw;
  margin-bottom: 0.694vw;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
}

.filter-div .filterDiv label {
  font-size: 0.833vw;
  line-height: 1;
  display: block;
  font-weight: 400;
  color: #121212;
  margin-bottom: 0.556vw;
}

.filter-div .filterDiv select {
  padding: 0.694vw;
  font-weight: 400;
  font-size: 0.972vw;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 0.833vw;
  font-family: "Google Sans";
  line-height: 2.083vw !important;
}

.properties-box a img {
  width: 1.181vw;
  height: 1.181vw;
}

.filter-div .filter-wrapper {
  flex: 0 0 100%;
  font-weight: 700;
  color: #253837;
  font-size: 16px;
  margin-bottom: 12px;
  padding-left: 8px;
}

.filter-div .filterDiv {
  flex: 0 0 33.33%;
  padding: 0 0.556vw;
}

.filter-div {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  border: 0;
  background-color: #f6f7f7;
  border-radius: 8px;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
}

.filter-div .filterDiv {
  flex: 0 0 33.33%;
  padding: 0 0.556vw;
}

.filter-div {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  border: 0;
  background-color: #f6f7f7;
  border-radius: 8px;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
}

.Auth-bar .authbar .container .authbar-inner .leftbar {
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.Auth-bar .authbar .container .authbar-inner .rightbar .right-contentbar {
  max-width: 40vw;
  margin: 0 0 0px auto;
  text-align: center;
  border-radius: 1.2vw;
  border: 1px solid #e2e2e2;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 2.3vw 1.6vw;
}

.unilogin .authbar .container {
  padding: 1vw 10vw 0vw 10vw;
}

.withdownloadbtn {
  position: relative;
}

.download_profile_btn {
  position: absolute;
  z-index: 99;
  outline: none;
  border: none;
  background: transparent;
  /* --------------------new responsive css (heena)----------------- */
  right: 4.39vw;
  top: 1.1vw;
  /* right: 60px; */
  /* top: 15px; */
}

.download_profile_btn svg {
  height: 2.778vw;
  width: 2.778vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .form-check-input:checked {
  border: 1px solid #a6c860 !important;
  background: #a6c860 !important;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .form-check-input {
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

img.upload_doc_icons {
  position: absolute;
  /* --------------------new responsive css (heena)----------------- */
  /* width: 80px;
  height: 80px;
  top: 5px;
  left: 5px; */
  width: 5.86vw;
  height: 5.86vw;
  top: 0.37vw;
  left: 0.37vw;
}

.inlinecheckbar .form-check {
  margin-right: 34px;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.setgridbarnotify {
  gap: 30px;
}

.primaryaccordion .primaryaccordion-header .accordion-button span.submain {
  color: var(--Dark-green, #253837);
  text-align: right;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 1.17vw;
}

.setgridbarnotify .rytyshare {
  flex: 0 0 15%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  flex-direction: row-reverse;
}

.connect_bank_infotext {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0px 0 0;
  display: inline-block;
}

.Guarantorbar .divider {
  position: relative;
}

.didgitally-bars .vreify-box .Guarantorbar .divider::before {
  left: 0;
}

.didgitally-bars .vreify-box .Guarantorbar .divider::after {
  right: 0;
}

.didgitally-bars .vreify-box .Guarantorbar .divider::after,
.didgitally-bars .vreify-box .Guarantorbar .divider::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 44%;
  height: 1px;
  background: #eeeeee;
}

.view_doc_btn {
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 14px 15px !important; */
  padding: 1.03vw 1.1vw !important;
  justify-content: center !important;
}

.primaryaccordion
  .primaryaccordion-header
  .accordion-button
  span.accordion_score {
  background: var(--Dark-green, #253837);
  color: var(--Light-Green, #a3eb64);
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 9px 60px;
  border-radius: 8px;
  font-size: 34px; */
  padding: 0.66vw 4.39vw;
  border-radius: 0.59vw;
  font-size: 2.49vw;
}

.doc-uploaded-from {
  border-radius: 34px;
  border: 0.6px solid #ddd;
  background: #fff;
  box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.18);
  position: absolute;
  left: 15px;
  top: 15px;
  padding: 0.556vw 0.833vw;
  font-size: 0.833vw;
  font-weight: 700;
  line-height: normal;
  color: #253837;
}

.primaryaccordion .primaryaccordion-header .accordion-button .maintitle {
  color: #223635;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  display: inline-flex;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 20px; */
  font-size: 1.46vw;
  /* gap: 4px; */
  gap: 0.29vw;
}

.secondrysearchbar .custom-input {
  border-radius: 0.833vw;
  border: 1px solid var(--Grey, #7f7f7f);
  background: #fff;
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw !important;
  /* 171.429% */
  padding-left: 2.778vw;
}

.secondrysearchbar {
  margin-bottom: 20px;
}

#uelz-widget-modal {
  position: fixed !important;
}

.secondryaccordion .accordion-item {
  filter: none !important;
  border-radius: 0 !important;
  fill: none !important;
  background: transparent;
}

.primaryaccordion .accordion-item .accordion-button {
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  display: inline-flex;
  width: auto !important;
  color: #223637;
  text-align: right;
  font-family: "Google Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0 0 20px 0 !important;
}

.secondryaccoridonbody.accordion-body {
  padding: 0 !important;
  display: grid;
  gap: 16px;
}

.secondryaccoridonbody.accordion-body .blockbookpayment {
  border-radius: 0.833vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 1.111vw 1.389vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Search-bar.secondrysearchbar {
  display: inline-flex;
  width: 29.861vw;
  margin-bottom: 3.472vw;
}

.profile-page .Search-bar input.custom-input.mb-0 {
  padding-left: 30px !important;
}

.secondryaccordion {
  gap: 50px !important;
}

.paymentdeailbar {
  border-radius: 0.833vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 1.111vw 1.389vw;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin: 2.083vw 0;
}

.secondryaccoridonbody.accordion-body .blockbookpayment .lleftblock h5 {
  color: #253837;
  font-family: "Google Sans";
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 5px 0;
}

.secondryaccoridonbody.accordion-body .blockbookpayment .rytblock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  color: var(--Dark-green, #253837);
  text-align: right;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.secondryaccoridonbody.accordion-body .blockbookpayment .lleftblock p {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Notifications-wapper .sharingcardblock h4 {
  color: #253837;
  font-family: "Google Sans";
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 5px 0;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

.Notifications-wapper .sharingcardblock p {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.setgridbarnotify .rytyshare h6.mb-0 {
  margin: 0;
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-control:focus {
  border-color: #ef7833;
}

.img-diff img {
  filter: contrast(0.5);
}

.inlinecheckbar .form-check.mr-0 {
  margin-right: 0;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  .form-check-label
  .contentbar
  h5 {
  text-align: left;
  color: #9c9c9c;
  font-size: 1.53vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 0.34vw 0;
  font-family: "Google Sans";
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  .form-check-label
  .contentbar
  p {
  color: #656875;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input {
  position: absolute;
  left: 0;
  top: 0;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked {
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label {
  background: #a3eb64;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .contentbar {
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .contentbar
  h5,
.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .contentbar
  p {
  color: #ffffff;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  img {
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  .form-check-label
  img {
  width: 6.21vw;
  height: 6.21vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .btngroup
  .btn {
  width: 100%;
  color: #ffffff;
}

.btnorange {
  background: #a3eb64 !important;
  color: #253837 !important;
}

.summaryinfobar:has(.col-md-6):not(.padding10) {
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 0px 10px 0 24px; */
  padding: 0vw 0.73vw 0vw 1.76vw;
}

/* --------------------new responsive css (heena)----------------- */
.summaryinfobar img,
.Score-content-list img {
  width: 1.02vw;
  height: 1.02vw;
}

.padding10 {
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 10px; */
  padding: 0.73vw;
}

.btngrey {
  border-radius: 0.556vw;
  background: #f4f4f4;
}

.step-go-home-btn svg {
  height: 1.25vw;
  width: 1.25vw;
  margin-right: 0.556vw;
}

.btngrey svg {
  height: 1.25vw;
  width: 1.042vw;
  margin-right: 0.556vw;
}

.step-go-home-btn {
  border-radius: 0.833vw;
  border: 0.069vw solid #a3eb64;
}

.ieTooltip {
  width: 400px !important;
  right: -310px !important;
}

.btnoutline {
  border-radius: 0.833vw;
  border: 0.069vw solid #223635 !important;
  background: #223635 !important;
  color: #ffffff !important;
}

.file-upload-wrapper .btn.btnoutline.bg-none {
  background: transparent !important;
  color: #223635 !important;
  font-size: 1.111vw;
  flex: none;
  padding: 0.694vw 1.389vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .btngroup
  .btn {
  padding: 0.7vw;
  font-size: 1.11vw;
  line-height: 1.67vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .btngroup
  .btn.btnoutline {
  background: transparent !important;
  color: #223635 !important;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .btngroup
  .btn.btnorange {
  margin-top: 0.833vw !important;
}

.hero-foot .container {
  padding: 0 !important;
}

.icon-eye {
  position: relative;
}

.password-div {
  position: relative;
}

.password-div .form-control {
  padding-right: 40px;
}

.password-div svg.eye-icons {
  position: absolute;
  top: 50%;
  right: 1vw;
  transform: translate(0px, -50%);
  font-size: 1.11vw;
}

.second-aisde .slideryt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0px;
}

/* .inlinecheckbar .form-check .form-check-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: var(--text-white);
} */

/* .inlinecheckbar .form-check-input[type="radio"] {
  background: transparent;
  border: 1px solid var(--text-white);
  margin: 0 10px 0 0;
  width: 22px;
  height: 22px;
  box-shadow: none;
} */

/* .inlinecheckbar .form-check-input:checked[type="radio"] {
  background: var(--text-white);
} */

/* .inlinecheckbar .form-check-input:checked[type="radio"]:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
} */
.Auth-bar .authbar .container .authbar-inner .rightbar .right-contentbar h4 {
  color: #223635;
  /* font-family: Butler; */
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 0.905vw 0 !important;
}

.form-check-input[type="radio"] {
  border-color: #253837;
  outline: none;
  box-shadow: none;
}

.form-check-input[type="radio"] {
  border-color: #253837;
  outline: none;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #253837;
  --bs-form-check-bg-image: none !important;
  border: 4px solid #ffffff;
  outline: 1px solid #253837;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar {
  margin-bottom: 2.09vw;
}

.primaryaccordion div#main-content {
  min-height: auto !important;
}

.inlinecheckbar .form-check {
  margin-right: 34px;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.inlinecheckbar .form-check.mr-0 {
  margin-right: 0;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  img {
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  .imgbar {
  background: #f3f3f3;

  display: flex;
  align-items: center;
  justify-content: center;

  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 10px; */
  border-radius: 0.73vw;
  /* width: 89px;
  height: 89px; */

  width: 6.51vw;
  height: 6.51vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  .imgbar
  img {
  /* --------------------new responsive css (heena)----------------- */
  /* width: 44px; */
  width: 3.22vw !important;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label {
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .contentbar
  h5 {
  color: #253837;
}

.auth-shape-center {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  height: 100%;
  z-index: 1;
}

.heading_lng_div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.headling_lng_div .menu-togglebar {
  background: #ffffff !important;
}

.heading_lng_div .menu-togglebar {
  background: #ffffff !important;
}

.sign-up-spacing button.iconinfo.d-flex {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 0.83vw;
}

.view_document {
  display: flex;
  gap: 10px;
  align-items: center;
}

.view_document_spinner.spinner-border {
  height: 20px !important;
  width: 20px !important;
}

span.placeholder_string {
  background: #ecebeb;
  margin: 0.347vw;
  padding: 0.347vw;
  border-radius: 0.694vw;
}

button.iconinfo.guarantor_tooltip {
  display: flex;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px !important; */
  font-size: 0.83vw !important;
}

.Auth-wrapper .logobar {
  position: absolute;
  /* width: 100%; */
  top: 3vw;
  left: 0;
  z-index: 999;
}

.Auth-bar {
  position: relative;
  z-index: 2;
}

.inlinecheckbar .form-check .form-check-label {
  font-weight: 500;
  text-transform: capitalize;
  color: var(--text-white);
  font-size: 18px;
  line-height: 22px;
}

/* --------------------new responsive css (3brain)----------------- */
.form-check .form-check-label {
  font-size: 1.32vw;
  line-height: 1.61vw;
}

/* --------------------end new responsive css (3brain)----------------- */

.form-check-label-orange:hover {
  color: #f27833 !important;
}

.gradient-progress-block {
  position: relative;
  z-index: 1;
}

.name-box,
.scorebtnbar {
  position: relative;
  z-index: 3;
}

.name-box-new {
  position: relative;
  z-index: 2;
}

.forminput.checkboxtc {
  display: flex;
  /* --------------------new responsive css (3brain)----------------- */
  /* gap: 10px; */
  gap: 0.732vw;
  align-items: center;
  margin-top: 0.5rem;
}

.landloard-address-form {
  width: 42vw;
  max-width: 42vw;
  margin: auto;
}

p.password_condition.font_design {
  font-family: "Google Sans";
  font-weight: 400;
  margin: 0;
  /* --------------------new responsive css (3brain)----------------- */
  /* font-size: 14px; */
  font-size: 1.03vw;
  line-height: 2.083vw;
}

.sign-up-spacing {
  margin-bottom: 0.5vw !important;
}

.password_condition {
  color: #808080 !important;
  /* --------------------new responsive css (3brain)----------------- */
  /* margin-bottom: 20px !important; */
  margin-bottom: 1.47vw !important;
}

.checkboxtc .setNoInfoColor {
  font-size: inherit;
  font-style: inherit;
}

.checkboxtc .forminput {
  margin: 0px !important;
}

.inlinecheckbar .form-check-input[type="radio"] {
  background: transparent;
  border: 1px solid var(--text-white);
  margin: 0 10px 0 0;
  width: 22px;
  height: 22px;
}

.inlinecheckbar .form-check-input:checked[type="radio"] {
  background: var(--text-white);
}

.inlinecheckbar .form-check-input:checked[type="radio"]:focus {
  /* box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%); */
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .linkedbtn
  button {
  width: 100%;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar {
  margin: 0.977vw 0px;
  position: relative;
}

h3.rental_detail_heading {
  color: #7f7f7f;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  white-space: nowrap;
}

.rental_details svg {
  width: 1.667vw;
  height: 1.667vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar
  p {
  margin: 0 auto;
  max-width: 20%;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #223635;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .backbtnbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.39vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .backbtnbar
  .backbtn {
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.69vw;
  color: #ef7833;
  text-align: center;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  form
  .linkedbtn {
  margin-bottom: 1.25vw;
}

p.skip_modal svg {
  height: 10px !important;
}

div#flatmate_div {
  max-height: 136px;
  overflow-y: auto;
  overflow-x: hidden;
}

h3.heading-student-edu {
  color: black;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.no-score-heading {
  font-size: 1.25vw !important;
  padding: 0.625vw 1.667vw !important;
}

label.edit-edu-heading {
  font-size: 15px !important;
  font-weight: 600;
}

#flatmate_div .row100 {
  display: flex;
  padding: 0;
  margin: 0 -10px 10px -10px;
}

label.flatmate-title {
  /* --------------------new responsive css (heena)----------------- */
  /* margin-bottom: 12px; */
  margin-bottom: 0.8vw;
  color: var(--Light-Text, #586363);
  font-family: "Google Sans";
  /* font-size: 14px; */
  font-size: 0.933vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: normal;
}

.flatmate-rybar {
  display: grid;
  grid-template-columns: 10fr 2fr;
  align-items: center;
  gap: 12px;
}

.flatmate-rybar .iconslot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flatmate-rybar .iconslot svg {
  /* --------------------new responsive css (heena)----------------- */
  /* width: 38px; */
  width: 2.533vw;
  /* height: 38px; */
  height: 2.533vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  padding: 0.666vw;
  /* border: 1px solid #a3eb64; */
  border: 0.066vw solid #a3eb64;
  /* border-radius: 50px; */
  border-radius: 3.333vw;
  cursor: pointer;
}

/* --------------------new responsive css (heena)----------------- */
/* label.flatmate-title {
  margin-bottom: 12px;
  color: var(--Light-Text, #586363);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

.btngroup.row100.gridbar.fullwidthbtn {
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 10px;
}

p.skip_modal {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
}

.flatmate_div_class {
  display: flex;
  padding: 0 !important;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  form
  .linkedbtn
  .btn-fill {
  padding: 0.97vw 4vw;
  border-radius: 0.83vw;
  border: 0.097px solid #223635 !important;
  background: #223635 !important;
  color: #ffffff !important;
  font-size: 1.11vw;
  line-height: 1.67vw;
}

.skor_limits {
  /* --------------------new responsive css (heena)----------------- */
  /* left: 70px;
  right: 60px;
  bottom: 5px; */
  left: 5.12vw;
  right: 4.39vw;
  bottom: 0vw;
  /* top: 19.5vw; */
  /* justify-content: space-between; */
  gap: 14.65vw;

  display: flex;
  align-items: center;
  position: absolute;
}

/* --------------------new responsive css (heena)----------------- */
.skor_limits p {
  font-size: 1.32vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  form
  .linkedbtn
  .btn-fill:hover {
  background: #ffffff !important;
  color: #223635 !important;
}

.link-property.prime-selectmulti > div {
  flex: 0 0 85%;
}

.link-property.prime-selectmulti > div button.css-48ayfv {
}

.link-property.prime-selectmulti > div button.css-48ayfv > div {
}

.link-property.prime-selectmulti > div > div > div {
  width: 100% !important;
  max-width: 100% !important;
}

.link-property.prime-selectmulti > div > div {
  width: 100%;
}

.css-1jllj6i-control.select__control.select__control--menu-is-open {
}

.link-property.prime-selectmulti .form-control .select__menu {
  box-shadow: none !important;
}

.link-property.prime-selectmulti .form-control .select__menu .select__option {
  padding: 18px 0 18px 0 !important;
  border-bottom: 1px solid #f1f1f1;
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.link-property.prime-selectmulti
  .form-control
  .select__menu
  .select__option
  input[type="checkbox"] {
  height: 20px;
  width: 20px;
  border: 1px solid #7f7f7f;
  margin: 0 !important;
}

.link-property.prime-selectmulti
  .form-control
  .select__menu
  .select__option
  input[type="checkbox"]:checked {
  accent-color: #253837;
}

.dropbtn {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::after,
.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::before {
  content: "";
  width: 40%;
  height: 1px;
  background: #eeeeee;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::before {
  right: 0;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::after {
  left: 0;
}

.auth-form .gridbar_block {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 1.39vw;
}

.forminput label.forminput {
  color: #121212;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-align: left;
  font-family: "Google Sans";
}

.li_disabled {
  opacity: 0.4;
  pointer-events: none;
}

.auth-form .checkboxtc input[type="checkbox"] {
  width: 1.3vw;
  height: 1.3vw;
}

.menu-togglebar {
  border-radius: 0.694vw;
  background: #f5f5f5;
  padding: 0.278vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 8.889vw;
}

.menu-togglebar label.customradiobtn {
  width: 50%;
  position: relative;
}

.menu-togglebar label.customradiobtn input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.menu-togglebar label.customradiobtn input[type="radio"]:checked ~ .checkmark1 {
  border-radius: 0.417vw;
  background: var(--Light-Green, #a3eb64);
  color: #000000;
}

.progress-li {
  background: #899c9b !important;
  min-height: 2px !important;
}

.progress-li {
  flex: 1 1 0;
}

.logobarwrapper ul.progress-ul {
  /* margin-top: 40px; */
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.current-progress {
  background: #ef7833 !important;
}

.current-completed {
  background: #a3eb64 !important;
}

.progress-bar.container {
  width: 100%;
  z-index: 1;
}

.progress-ul li {
}

.search-property a img {
  position: absolute;
  right: 15px !important;
  /* top: 20px !important; */
  top: 50%;
  transform: translateY(-50%);
}

.search-property .dropdown .filterBtn {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffff;
}

.search-property .dropdown .dropdown-content {
  top: 48px;
  padding: 12px;
  left: 0;
  border-radius: 12px;
}

.search-property {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.link-property.prime-selectmulti {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.link-property.prime-selectmulti {
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
}

.link-property.prime-selectmulti .form-control {
  max-width: 100% !important;
  padding: 6px 12px;
}

.link-property.prime-selectmulti .form-control .select__control {
  border-radius: 12px !important;
  border: 1px solid var(--Grey, #7f7f7f) !important;
  background: #ffffff !important;
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 18px;
  height: auto;
  margin-left: 0;
  margin-right: 0;
}

.link-property.prime-selectmulti
  .form-control
  .select__control
  .select__value-container {
  padding: 0 !important;
}

.ux-zindabad ul.step-num {
  gap: 0;
  justify-content: space-between;
  flex-direction: row;
  max-width: 100%;
  margin-top: 30px;
}

.link-property.prime-selectmulti .btn {
  height: 3.681vw;
  padding: 0.694vw 1.736vw !important;
  min-width: 12.5vw;
  position: relative;
  z-index: 100;
}

.status-bar.declinebar h6 {
  padding: 10px 0px 0px 0px;
  color: #fa1313 !important;
  font-size: 15px;
  font-weight: 700;
}

.btn-selectbox {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 2px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 10px !important;
  width: 100% !important;
}

.set_gap_2 img {
  padding: 5px;
}

.prime-summary-sec {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-togglebar a.customradiobtn {
  width: 50%;
  text-decoration: none;
}

span.property_condition {
  color: black;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 15px; */
  font-size: 1vw;
  /* margin: 10px; */
  margin: 0.666vw;
}

.menu-togglebar .checkmark1 {
  color: var(--white, #253837);
  text-align: center;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  height: 1.944vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
}

.auth-form label.forminput {
  color: #121212;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-align: left;
  font-family: "Google Sans";
  margin-bottom: 0.9vw;
}

.steps-wrapper .css-b62m3t-container {
  margin-bottom: 1.389vw;
}

.step-btns-group {
  padding-top: 0;
}

.btn.skip-btn {
  margin-top: 1.2rem;
}

.css-13cymwt-control:focus,
.css-t3ipsp-control:focus {
  border-color: #ef7833 !important;
  outline: none !important;
  box-shadow: none !important;
}

.auth-form .form-control {
  border-radius: 0.83vw;
  padding: 0 1.11vw;
  font-size: 0.85vw;
  min-height: 4.028vw;
  line-height: 1.3vw;
}

.form-control.uni_select {
  border: none;
  padding: 0;
}

.form-control.uni_select .css-13cymwt-control,
.css-t3ipsp-control {
  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 12px !important; */
  border-radius: 0.8vw !important;
  /* border: 1px solid #c4c4c4 !important; */
  border: 0.066vw solid #c4c4c4 !important;
  /* height: 58px; */
  height: 3.866vw;
  outline: none !important;
  box-shadow: none !important;
}

/* --------------------new responsive css (heena)----------------- */
.form-control.uni_select
  .css-13cymwt-control
  .css-1wy0on6
  .css-1u9des2-indicatorSeparator {
  width: 0.066vw;
  margin-bottom: 0.533vw;
  margin-top: 0.533vw;
}

.form-control.uni_select
  .css-13cymwt-control
  .css-1wy0on6
  .css-1xc3v61-indicatorContainer {
  padding: 0.533vw;
}

.form-control.uni_select
  .css-13cymwt-control
  .css-1wy0on6
  .css-1xc3v61-indicatorContainer
  svg {
  height: 1.333vw;
  width: 1.333vw;
}

.form-control.uni_select .css-13cymwt-control .css-hlgwow {
  padding: 0.133vw 0.533vw;
}

/* --------------------end new responsive css (heena)----------------- */

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .auth-form
  .btngroup
  .signup-text {
  color: #223635;
  text-align: center;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 1.3vw;
  font-family: inherit;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .auth-form
  .btngroup
  .signup-text
  a {
  color: #ef7833;
  font-weight: 500;
}

.authbar-inner {
  width: 100%;
}

/* rm css */

.signup-text a {
  color: var(--text-white);
  text-decoration: underline;
}

.signup-text a:hover {
  color: #a695f2 !important;
}

.signup-text {
  color: var(--text-white);
  font-size: 16px;
  font-weight: inherit;
  font-family: inherit;
  margin-bottom: 0px !important;
}

.sign-wapper {
  width: 362px;
  height: 111px;
  border: 1px solid var(--text-white);
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-wapper a {
  font-size: 18px;
  color: var(--text-white);
  margin-top: 13px;
  margin-bottom: 0;
  font-weight: inherit;
  font-family: inherit;
}

.sign-wapper:hover {
  background-color: #f27833 !important;
}

.already_guarantor h4 {
  text-align: center !important;
}

/*New UI design accordion*/
.primaryaccordion .primaryaccordion-header .accordion-button {
  background: transparent !important;
  box-shadow: none !important;
  color: #223635;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 30px;
  font-size: 20px;
  gap: 12px; */
  padding: 2.19vw;
  font-size: 1.46vw;
  gap: 0.88vw;
}

/* --------------------new responsive css (heena)----------------- */
.primaryaccordion .accordion-button::after {
  width: 1.65vw !important;
  height: 1.65vw !important;
  background-size: 1.65vw !important;
}

.primaryaccordion .primaryaccordion-body {
  border: none !important;
  padding-top: 10px;
}

.primaryaccordion {
  /* --------------------new responsive css (heena)----------------- */
  /* gap: 18px; */
  gap: 1.32vw;
  display: grid;
}

.select__option.select__option--is-focused.select__option--is-selected,
.select__option.select__option--is-selected {
  background-color: transparent !important;
}

.primaryaccordion .primaryaccordion-body h4.titlebar_new {
  margin: 0 0 10px 0;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.head-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* --------------------new responsive css (heena)----------------- */
  /* gap: 5px; */
  gap: 0.37vw;
}

.head-block .leftbartext {
  flex: 0 0 85%;
}

.head-block svg {
  /* --------------------new responsive css (heena)----------------- */
  /* flex: 0 0 20px;
  font-size: 20px; */
  flex: 0 0 1.46vw;
  font-size: 1.46vw;
}

.edit-profile .primaryaccordion .primaryaccordion-body button.css-1r4vtzz,
.primaryaccordion .primaryaccordion-body button.css-48ayfv,
.primaryaccordion
  .primaryaccordion-body
  .link-property.prime-selectmulti
  div
  button {
  border-radius: 0.75rem;
  border: 1px solid #e2e2e2;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(35, 27, 27, 0.08);
  flex-wrap: wrap;
  width: 100% !important;
  position: relative;
}

.css-1r4vtzz {
  font-size: 0.972vw !important;
  padding: 0.556vw 0.556vw 0.347vw 0.556vw !important;
}

.edit-profile .css-1gpjby2 {
  position: absolute;
  right: 0;
}

.primaryaccordion .accordion-item {
  margin: 0 !important;
  border: none !important;
  fill: #fff;
  filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.05));
  border-radius: 12px;
}

.primaryaccordion .primaryaccordion-body .tabs-item {
  padding: 0;
  background: transparent;
  box-shadow: none !important;
}

.primaryaccordion .accordion-item .primaryaccordion-header ul.tooltip {
  /* --------------------new responsive css (heena)----------------- */
  /* top: -35px; */
  top: -2.56vw;
}

.primaryaccordion
  .primaryaccordion-header
  .accordion-button
  .guarantor_tooltip
  svg {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 18px; */
  font-size: 1.32vw;
}

.primaryaccordion
  .primaryaccordion-header
  .accordion-button
  .guarantor_tooltip
  svg {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 18px; */
  font-size: 1.32vw;
}

.primaryaccordion .primaryaccordion-body .accordion-title {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}

.primaryaccordion .primaryaccordion-body .hstack {
  display: grid;
  grid-template-columns: 6fr 1fr;
  /* --------------------new responsive css (heena)----------------- */
  /* gap: 18px !important; */
  gap: 1.32vw !important;
}

.primaryaccordion .primaryaccordion-body .hstack .form-select {
  padding: 12px !important;
  border-radius: 12px !important;
  border: 1px solid var(--Grey, #7f7f7f) !important;
  background: #ffffff !important;
  color: var(--Grey, #7f7f7f) !important;
  font-family: "Google Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: 100%;
}

.card-listing {
  /* --------------------new responsive css (heena)----------------- */
  /* margin-top: 24px; */
  margin-top: 1.76vw;
}

.card-listing .slick-slider > svg {
  color: #223635;
}

.card-listing .slick-slider > .slick-dots {
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

/* --------------------new responsive css (heena)----------------- */
.card-listing .slick-slider > .slick-dots li {
  width: 1.46vw;
  height: 1.46vw;
  margin: 0 0.37vw;
}

.card-listing .slick-slider .slick-prev,
.card-listing .slick-slider .slick-next {
  width: 1.5vw;
  height: 1.5vw;
}

.card-listing .slick-slider .slick-prev {
  left: -2vw;
}

.card-listing .slick-slider .slick-next {
  right: -2vw;
}

.card-listing .slick-slider > .slick-dots li button {
  width: 1.46vw;
  height: 1.46vw;
  padding: 0.37vw;
  padding-block: 0.07vw;
  padding-inline: 0.44vw;
}

pre {
  font-size: 80px;
}

/* --------------------end new responsive css (heena)----------------- */

.pdf-listing {
  padding: 0 10px;
}

.max_rent_title {
  color: var(--Grey, #7f7f7f);
  padding: 0.556vw 0.694vw;
  text-align: start;
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.max_rent_val {
  color: var(--Dark-green, #253837);
  text-align: start;
  font-family: "Google Sans";
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-block-end: 0.694vw;
}

.max_rent_div {
  border-radius: 12px;
  background: #f3f3f3;
}

.linked_tenant_div {
  border-radius: 0.417vw;
  background: #edfbe0;
  padding: 1.25vw;
  cursor: pointer;
}

.dark-green-text {
  color: #253837;
}

.btn.btndarkgreen.edit-status {
  padding: 0.208vw 0.556vw !important;
  margin-left: 2.222vw !important;
  font-size: 0.833vw;
}

.btn.btngreen.edit-status {
  padding: 0.556vw 1.042vw !important;
}

.other_docs_div {
  padding-top: 1.667vw;
}

.linked_p {
  margin: 0px 10px;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.assigned_details_body {
  padding: 1.667vw 0;
}

.linked_li_rate {
  margin-block-end: 1.528vw;
}

.linked_li_details .btn.btngreen {
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 12px 24px !important; */
  padding: 0.88vw 1.76vw !important;
}

.linked_li_div .linked_li_name {
  text-align: left;
  color: #253837;
  font-family: "Google Sans";
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.linked_li_rate {
  align-content: center;
  margin-block-end: 22px;
}

.linked_li_rate p {
  border-radius: 0.417vw;
  padding: 1vw 0.833vw;
  background: #edfbe0;
  font-size: 1.25vw;
}

.linked_li_div .linked_li_score {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.linked_li_div {
  padding: 20px 0px;
}

.linked_li_details {
  border-bottom: 1px solid #ddd;
  padding-top: 0.694vw;
  justify-content: space-between;
  width: 100%;
}

.max-rent-span {
  border-radius: 6px;
  background: #edfbe0;
  padding: 6px 12px;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 15px;
}

.linked_p_details {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  float: inline-start;
}

.linked_p_details span {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.assigned_details_modal {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 22px;
}

.assigned_details_modal .assigned_address {
  margin: 0px 0.833vw;
  font-size: 1.25vw !important;
}

.percentage_salary_p {
  color: #121212;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px;
}

.percentage_salary {
  color: #121212;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.694vw;
}

.card-listing .card {
  background: #fff !important;
  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 8px !important;
  border: 0.6px solid #ddd !important;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03) !important; */
  border-radius: 0.59vw !important;
  border: 0.044vw solid #ddd !important;
  box-shadow: 0px 0.29vw 2.49vw 0.073vw rgba(0, 0, 0, 0.03) !important;
}

.card-listing .videoframe {
  overflow-y: scroll;
  border-bottom: 0;
  /* --------------------new responsive css (heena)----------------- */
  /* height: 230px; */
  height: 16.84vw;
  /* border: 2px solid #000; */
  border: 0.15vw solid #000;
}

.card-listing .videoframe iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 !important;
}

.card-listing .videoframe img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.card-listing .card-body {
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 12px; */
  padding: 0.88vw;
  min-height: 124px;
}

.card-listing .card-body .listing-head h5 {
  color: #121212;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 18px;
  margin-bottom: 4px; */
  font-size: 1.32vw;
  margin-bottom: 0.29vw;
}

.card-listing .card {
  min-height: 334px;
}

.card-listing .card-body .listing-head p {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 12px; */
  font-size: 0.88vw;
}

.card-listing .card-body .listing-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-listing .card-body .listing-foot span {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 14px; */
  font-size: 1.02vw;
}

.primaryaccordion .primaryaccordion-body .hstack .form-select option {
  font-size: 14px;
  padding: 10px;
}

.primaryaccordion .primaryaccordion-body .hstack .btn-primary {
  border-radius: 12px !important;
  background: var(--Light-Green, #a3eb64) !important;
  border: 1px solid var(--Light-Green, #a3eb64) !important;
  color: var(--Dark-green, #253837) !important;
  font-family: "Google Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 14px !important;
}

.card-listing .card-body .listing-foot span.del-icon {
  width: 2.639vw;
  height: 2.639vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.6px solid #a3eb64 !important;
  border-radius: 50%;
}

.modal-uploadpdf .formcontrolbar input {
  background: #f7f7f7;
  padding: 14px;
  width: 100%;
  margin-bottom: 20px;
}

.modal-uploadpdf .formcontrolbar .btn-green {
  width: 100%;
  border-radius: 12px;
  background: var(--Light-Green, #a3eb64) !important;
  border: 1px solid var(--Light-Green, #a3eb64) !important;
  color: var(--Dark-green, #253837) !important;
  font-family: "Google Sans" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.already_guarantor_footer {
  text-align: center;
  width: 100% !important;
  padding: 44px;
}

.someonegaurentor p {
  color: var(--Light-Text, #586363);
  text-align: center;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.someonegaurentor p a {
  color: #ef7833;
}

.position_relative {
  position: relative !important;
}

.row.edittitle {
  position: sticky;
  background: #ffffff;
  display: flex;
  justify-content: center;
  z-index: 1000;
  align-items: center;
  /* --------------------new responsive css (heena)----------------- */
  top: 5.12vw;
  padding: 1.1vw 0vw;
  /* top: 70px; */
  /* padding: 15px 0px; */
}

.orange_text {
  color: #f27833 !important;
}

.wrapper-score {
  width: 50%;
}

.login-bottom {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.d-blockform {
  margin: 0 !important;
  display: block !important;
}

.orangetext {
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.customradiobar {
  display: block;
  position: relative;
}

.customradiobar .boxes {
  border-radius: 10px;
  border: 2px solid #e0e0e0;
  background: #fff;
  padding: 1.111vw;
  color: #5b5b5b;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw;
}

.upload-doc .modal-content {
  padding: 15px;
}

.tenant-skore .skore-heading {
  color: #253837;
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.667vw;
}

.tenant-skore .skore-heading + a {
  color: #253837;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.tenant-skore > div {
  margin-bottom: 0.347vw;
}

.tenant-skore .score-card .score-card-head {
  color: #7f7f7f;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.042vw;
}

.tenant-skore {
  align-items: stretch;
}

.icon-button {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  border-radius: 50%;
}

.border-wrapper {
  border-radius: 0.833vw;
  border: 0.069vw solid #ddd;
  background: #fff;
  height: 100%;
  padding: 1.111vw;
}

.skore-image-wrapper img {
  height: 12.5vw;
  width: 25.625vw;
}

.skore-details-content img {
  height: 5.139vw;
  width: 5.139vw;
}

.skore-details-content .btn.btnorange.complete-btn {
  border-radius: 12px;
  background: #f6f6f6 !important;
  color: #ef7833 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.upload-doc .modal-content .modal-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.progress-bar-wrapper .progress-bar-inner-wrapper .progress .progress-color {
  /* border-radius: 20px; */
  margin-left: auto;
  background: #efefef;
}

/* ////////////////////////////////// */

/* ////////////////////////////// */

.progress-bar-wrapper
  .progress-bar-inner-wrapper
  .progress:has(.progress-color) {
  background: linear-gradient(
    90deg,
    #f23d3d 0%,
    #ffed47 45.3%,
    #ffed47 79.4%,
    #c3db5a 100%
  );
  border-radius: 1.389vw;
  height: 1.111vw;
}

.progress-bar-wrapper
  .progress-bar-inner-wrapper
  .progress
  .progress-simple-color {
  border-radius: 1.389vw;
  background: #a392ef;
}

.progress-bar-wrapper .progress-head {
  color: #253837;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.progress-bar-wrapper .progress-score {
  border-radius: 0.556vw;
  background: #f4f4f4;
  padding: 0.833vw 3.611vw;
  margin-bottom: 0.833vw;
  color: #253837;
  text-align: center;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  min-width: 9.583vw;
}

.tenant-skore .row > div,
.tenant-skore .d-flex > div {
  padding: 0 0.833vw;
}

.tenant-skore .row > div.col-12 {
  padding-top: 1.25vw;
}

.tenant-skore .row > div.col-12 .btn.btngreen {
  padding: 0.972vw 3.819vw;
  font-size: 1.042vw;
  border-radius: 0.833vw;
  gap: 0.556vw;
}

.tenant-skore .row > div.col-12 .btn.btngreen svg {
  height: 1.389vw;
  width: 1.25vw;
}

.progress-bar-inner-wrapper .progress {
  height: 1.111vw;
  border-radius: 2.778vw;
}

.progress-bar-inner-wrapper .score-limit {
  color: #7f7f7f;
  text-align: right;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0.556vw 0;
}

.customradiobar input[type="radio"]:checked ~ .boxes {
  border-radius: 10px;
  border: 2px solid #253837;
  /* box-shadow: 0px 4px 18px 1px rgba(0, 0, 0, 0.12); */
  color: #253837;
}

.customradiobar input[type="radio"] {
  display: none;
}

.inlinecheckboxes {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 20px;
}

.input-dropdown-inline {
  display: grid;
  grid-template-columns: 73% 23%;
  gap: 20px;
}

.orangetext a {
  color: #ef7833;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.auth-form .gridbar {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 1.389vw;
}

.newmodalui.connectbankmodal .modal-dialog {
  max-width: 55.556vw !important;
}

.none-under {
  text-decoration: none !important;
}

.auth-form .btngroup .btnoutline,
.cancel-btn {
  background: transparent !important;
  color: #223635 !important;
}

ul.infodorpdown.summarydropdum.position-relative ul.tooltip {
  /* --------------------new responsive css (heena)----------------- */
  /* left: 20px; */
  left: 1.46vw;
}

select.form-control {
  appearance: auto;
  /* --------------------new responsive css (heena)----------------- */
  /* height: 58px; */
  height: 4.25vw;
}

.loginform.otp-Verification {
  display: flex;
  justify-content: center;
}

.resend-text form .auth-form label.forminput {
  margin-bottom: 10px;
}

.auth-form {
  display: flex;
  flex-wrap: wrap;
  margin: 34px -20px 0 -20px;
}

.auth-form .row50 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px;
}

.auth-form .row100 {
  padding: 0 0.694vw;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}

.auth-form .row100 .btnorange {
  width: 100%;
}

.resend-text {
  border-radius: 1.042vw;
  border: 0.069vw solid #e2e2e2;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 3.056vw;
  text-align: center;
  max-width: 42vw;
  width: 100%;
  margin: 0 auto;
}

.resend-text h4 {
  color: #223635;
  text-align: center;
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.resend-text p {
  color: var(--Grey, #686767);
  text-align: center;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.landlord_homepage {
  width: 100% !important;
  max-width: 100% !important;
}

.landlord_homepage img {
  padding: 0px;
}

.PhoneInputInput {
  font-size: 16px;
  font-family: "Google Sans";
}

.landlord_sidebar li.has-sub.active {
  background: #a3eb64;
}

.PhoneInput--focus {
  border-color: #a3eb64 !important;
  box-shadow: 0 0 0 1px #a3eb64 !important;
  outline: none;
  background: #ffffff;
}

.landlord_sidebar li.has-sub.active a span {
  color: #000000 !important;
}

.landlord_homepage {
  min-height: calc(100vh - 235px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.landlord_homepage .addpropertybar {
  max-width: 340px;
  text-align: center;
}

.step-name.with-less-margin {
  margin-bottom: 8px !important;
}

.otp-timer {
  text-align: center;
  color: #223635;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 14px;
}

.otp-timer .timer-count {
  color: #ef7833;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.otp-step-desc {
  color: var(--Grey, #7f7f7f);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landlord_homepage p {
  margin-bottom: 25px;
  margin-bottom: 18px;
}

.otp-Verification {
  margin: 34px 0 14px 0;
}

.otp-Verification .forminput {
  margin: 0;
  gap: 12px;
}

.resend-text p.timer {
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.resend-text p.timer span {
  color: #ef7833;
}

.otp-Verification .forminput input[type="tel"] {
  border-radius: 0.833vw;
  border: 0.069vw solid #c4c4c4;
  background: #fff;
  width: 4.028vw !important;
  height: 4.028vw !important;
  margin: 0 !important;
  transition: 0.3s ease;
}

.otp-Verification .forminput input[type="tel"]:focus {
  outline-color: #f27833;
}

.otp-links {
  margin-top: 0.694vw;
}

.resend-text .btn-fill {
  width: 100%;
}

.info-wapper h5 {
  font-family: "Google Sans";
  font-weight: 400;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 24px; */
  font-size: 1.6vw;
  /* line-height: 30px; */
  line-height: 2vw;
}

.info-wapper p {
  font-family: "Google Sans";
  font-weight: 400;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 1.066vw;
  /* margin: 10px 0px; */
  margin: 0.666vw 0px;
  color: var(--base-darkgray);
}

.Personal-Information-content input {
  background: #ffffff;
  border: 1px solid #c4c4c4 !important;
  border-radius: 12px;
  color: #121212 !important;
  padding-bottom: 0 !important;
  height: 56px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 16px;
}

.Personal-Information-content input::placeholder {
  color: #cacaca !important;
  font-size: 14px;
}

.Personal-Information-content label {
  font-weight: 400;
  font-size: 12px;
  color: #121212;
  margin-bottom: 10px;
}

.left-info-wapper a {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: var(--base-secondry) !important;
  padding: 0;
  background: transparent !important;
}

.left-info-wapper .nav-item {
  background: transparent;
  color: var(--base-primary) !important;
}

.left-info-wapper .nav-item {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.active-icon {
  width: 40px;
  height: 40px;
  background: var(--base-primary);
  border-radius: 50px;
  text-align: center;
  line-height: 37px;
  margin-left: 10px;
}

.link-property {
  display: flex;
  gap: 10px;
  max-width: 450px;
  justify-content: space-between;
}

.link-property .btn {
  padding: 10px !important;
}

.link-property .form-control {
  max-width: 300px !important;
}

.right-space-wapper {
  padding-left: 60px;
  border-left: 1px solid var(--border-light);
}

.Button-section button {
  width: 200px;
  height: 52px;
  background: var(--base-primary);
  border-radius: 12px;
  color: var(--text-white);
  float: right;
  margin-top: 30px;
}

.profile-block {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.row .Button-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.left-info-wapper {
  padding-right: 60px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.left-info-wapper .nav-item .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
}

.tenant_zero_notification {
  border-radius: 8px;
  border: 1px solid var(--Light-Green, #a3eb64);
  background: rgba(163, 235, 100, 0.12);
  padding: 18px 24px;
  position: relative;
  margin-bottom: 24px;
}

.zero_deposit_a {
  color: #223637;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

a.close_zero_deposit {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.tenant_zero_notification p span {
  margin-left: 18px;
}

.Personal-Information-content select {
  border: 1px solid #c4c4c4 !important;
  border-radius: 12px;
  color: #cacaca !important;
  padding-bottom: 0 !important;
  height: 56px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 16px;
  font-size: 14px;
}

.share-it-profile:hover {
  color: #000000;
}

button.btn-fill-border {
  background: var(--text-white);
  color: var(--base-primary);
  float: right;

  /* --------------------new responsive css (3brain)----------------- */
  /* width: 220px; */
  /* height: 52px; */
  /* border-radius: 12px; */
  /* margin-top: 40px;
  margin-right: 20px;
  border: 1px solid var(--base-primary); */

  width: 16.09vw;
  height: 3.81vw;
  border-radius: 0.88vw;
  margin-top: 2.93vw;
  margin-right: 1.47vw;
  border: 0.073vw solid var(--base-primary);
  font-size: 1.32vw;
}

button.btn-fill-border:hover {
  background: var(--base-primary);
  color: var(--text-white);
  border: 0px;
  /* --------------------new responsive css (3brain)----------------- */
  /* border-radius: 12px; */
  border-radius: 0.88vw;
}

.authbar.hide-bg {
  background: #fff;
}

/* bottom-img-code */
.logo-img {
  display: none;
}

.finacial {
  width: 80%;
}

/* spinner-clock-code */
.spinner-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 50vh;
}

.small-logo {
  padding-left: 0px;
}

.icon-logo-user {
  color: white !important;
  padding-right: 20px;
}

.tabs-item-rental {
  background: #ffffff;
  padding: 30px;
  margin-bottom: 25px;
  background: #ffffff;
  border: 0.5px solid #dddddd;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: auto;
}

.feedback-star-div .react-stars {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-w p {
  position: relative;
}

.infotext svg.infoicon {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  font-size: 20px;
}

.location-w .infotext {
  position: relative;
}

.Notifications-wapper.opacity-wapper {
  position: relative;
}

.Notifications-wapper.opacity-wapper svg.infoicon {
  top: 20px;
  right: 30px;
}

.auth-form .row100 .css-13cymwt-control {
  height: 58px;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  background: #fff;
}

.auth-form .row100 .css-13cymwt-control .css-qbdosj-Input {
  border: none;
  outline: none;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  text-align: left;
  display: block;
}

.status-bar {
  text-align: center;
}

.status-bar h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ef7833;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.status-bar.inprogressbar h3 {
  color: #ef7833;
}

.status-bar.declinebar h4 {
  color: #fa1313 !important;
}

.status-bar p {
  color: var(--Grey, #7f7f7f);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.newmodalui .modal-body .auth-form .forminput {
  border-radius: 0.833vw;
}

.newmodalui .modal-body .auth-form .forminput input,
.newmodalui .modal-body .auth-form .forminput select {
  border-radius: 0.833vw;
  border: 0.069vw solid #c4c4c4;
  background: #fff;
  padding: 1.111vw;
  width: 100%;
  color: #000000;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw;
  /* 171.429% */
}

/* --------------------new responsive css (3brain)----------------- */
.newmodalui .modal-header .modal-title {
  font-size: 1.32vw;
  line-height: 1.76vw;
}

/* --------------------end new responsive css (3brain)----------------- */

.card_select_modal {
  padding: 34px !important;
}

.card_select_modal h5 {
  margin-bottom: 24px;
}

.status-bar .verify-btn,
.marginTop10 {
  margin-top: 10px;
}

.qr-code-div {
  display: flex;
  justify-content: center;
}

.max-w-lg .modal-dialog {
  max-width: 51.875vw;
}

.qr-link-copi {
  max-width: 70% !important;
  margin: 1.389vw auto !important;
  height: 3.472vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code-div svg {
  border: 1px solid black;
  padding: 1.528vw;
  border-radius: 1.389vw;
  width: 17.986vw;
  height: 17.986vw;
}

.qr-code-download {
  text-align: center;
}

.qr-link-copi {
  text-align: center;
  background: #f3f3f3;
  margin: 20px 0px;
  border-radius: 0.833vw;
}

.qr-link-copi input {
  width: 70%;
  padding: 0.694vw;
  border-radius: 0.694vw;
  background: none;
  border: none;
  font-size: 0.972vw;
}

.qr-link-copi button {
  background: #a3eb64;
  padding: 0.478vw 0.694vw;
  border: none;
  border-radius: 0.556vw;
  color: #253837;
}

.newmodalui .modal-header button.btn-close {
  width: 2.778vw;
  height: 2.778vw;
  font-size: 1.2vw;
}

.newmodalui .modal-body .auth-form .forminput input::placeholder {
  color: #cacaca;
}

/* bottom-img-code-end */
/* rm css */

#skor_navbar_logo {
  height: 2.569vw !important;
  width: 9.931vw !important;
}

.margin-tenant .margin-tenant-div {
  padding-left: 3.472vw !important;
}

.margin-div {
  margin-top: 6.667vw !important;
  padding-left: 1.736vw;
  /* padding-left: calc(300px + 25px) !important; */
  /* margin: 10px; */
  padding-right: 1.736vw !important;
}

/* dasborad */
#left-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 20.833vw;
  background-color: #253837;
  overflow-y: auto;
  height: calc(100vh - 9.028vw);
  border-right: 1px solid #e6ecf5;
  margin-top: 9.028vw;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  z-index: 2;
}

body:has(.hassidebar) .margin-div {
  padding-left: calc(22.75vw) !important;
}

.landlord_sidebar .has-sub a span {
  font-size: 1.389vw;
}

body .margin-div {
  padding-left: 0 !important;
}

body:has(.hassidebar) #header .header-left {
  padding-left: calc(22.92vw);
}

.sidebarKey {
  width: 15.278vw;
  position: absolute;
  bottom: 0;
  left: -50px;
  height: 9.028vw;
}

#left-menu.small-left-menu,
#logo.small-left-menu {
  width: 12.5vw;
}

#left-menu ul {
  padding: 0;
  margin: 0;
}

#left-menu ul li {
  display: block;
  position: relative;
}

#left-menu > ul > li {
  padding: 1.25vw 0;
  border-bottom: 0.6px solid #ffffff1f;
  padding-left: 1.389vw;
  width: 100%;
}

#left-menu ul li a {
  color: #fff;
  width: 100% !important;
  display: inline-block;
  width: 215px;
  position: relative;
  font-family: "Google Sans";
}

#left-menu ul li a:hover {
  color: #fff;
}

#left-menu ul li a i {
  font-size: 22px;
  text-align: center;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#left-menu ul li a span {
  width: 100%;
  height: 35px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #ffffff;
}

#left-menu ul li a span img {
  margin-right: 10px;
  opacity: 0.5;
}

#left-menu ul li.active a span img {
  opacity: 1;
}

#left-menu select#lngType {
  width: 100%;
  text-align: left;
}

#left-menu select#lngType option {
  background: #253837;
  border-radius: 0;
}

.has-sub.active {
  background-color: #a3eb64;
}

#left-menu ul li.active a span {
  color: #253837;
}

#left-menu ul li.active a i {
  color: #fff;
}

#left-menu li.has-sub ul {
  background-color: #454e62;
  margin: 0 -10px;
  padding-left: 45px;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#left-menu li.has-sub ul > li {
  padding-top: 10px;
}

a:hover {
  text-decoration: none;
}

#logo img {
  width: 11.111vw;
}

#logo {
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  background-color: #253837;
  height: 9.028vw;
  width: 20.833vw;
  font-size: 1.944vw;
  line-height: 2em;
  z-index: 4;
  color: #fff;
  padding-left: 2.778vw;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.top-fix-head.nobar {
  padding-left: 285px;
  width: 100%;
}

#header {
  background-color: #fff;
  /* height: 130px; */
  position: fixed;
  top: 0px;
  width: 100%;

  z-index: 3;
  left: -2px;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
}

#header .header-tenant-left {
  padding-left: 4.167vw !important;
}

#header .header-left {
  /* padding-left: calc(300px + 30px); */
  padding-left: 1.736vw;
  transition: all 0.3s ease-in-out;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
}

#header .header-right {
  padding-right: 40px;
}

#header .header-right i,
#header .header-left i {
  font-size: 28px;
  line-height: 2em;
  padding: 0 5px;
  cursor: pointer;
}

#main-content {
  min-height: calc(100vh - 60px);
  clear: both;
}

#page-container {
  /* padding-left: 285px;
	padding-top: 128px; */
  padding-right: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#page-container.small-left-menu,
#header .header-left.small-left-menu {
  padding-left: 80px;
}

.card {
  border: 1px solid #e6ecf5;
  margin-bottom: 1em;
  font-weight: 300;
}

.card .title {
  padding: 15px 20px;
  border-bottom: 1px solid #e6ecf5;
  margin-bottom: 10px;
  color: #000;
  font-size: 18px;
}

#show-lable {
  opacity: 0;
  visibility: hidden;
  left: 80px;
  font-weight: 300;
  padding: 6px 15px;
  background-color: #0e9aee;
  position: fixed;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#left-menu.small-left-menu li.has-sub::after {
  content: "";
}

#left-menu.small-left-menu li.has-sub ul {
  position: fixed;
  width: 280px;
  z-index: 123;
  height: 0;
  left: 69px;
  padding-left: 15px;
}

/* switch-btn */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4b1770;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*  */

/* custom css raman */

.custom-header {
  display: flex;
  /* height: 130px; */
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  flex-wrap: wrap;
  padding: 0.694vw 2.083vw;
}

.badge {
  background-color: #de4724;
  border-radius: 50%;
  height: 1.389vw;
  width: 1.389vw;
  text-align: center;
  line-height: 0.903vw;
  position: relative;
  top: -0.833vw;
  left: 0.833vw;
  display: inherit;
  z-index: 2;
  /* display: inline-block; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.903vw;
}

.badge + img {
  height: 2.153vw;
  width: 1.875vw;
}

.custom-header h5 {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: sans-serif;
  color: var(--base-secondry);
  font-size: 1.528vw;
  white-space: nowrap;
}

.custom-header h5 img,
.second-aisde img {
  /* filter: invert(1); */
}

.tenant-details {
  display: flex;
  gap: 1.389vw;
  align-items: center;
}

.tenant-badge-name {
  width: 5.556vw;
  height: 5.556vw;
  background: #253837;
  border-radius: 50%;
  text-align: center;
  align-content: center;
  font-size: 1.667vw;
  margin-right: 0;
  line-height: 3.958vw;
  color: #a3eb64;
}

.admin-name {
  width: 3.889vw;
  height: 3.889vw;
  background: #a3eb64;
  border-radius: 50%;
  text-align: center;
  margin-right: 0;
  line-height: 3.958vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25vw;
  overflow: hidden;
}

.admin-name a {
  color: #253837;
  font-family: "Google Sans";
  font-size: 15px;
}

.second-aisde {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.389vw;
}

.mainwraper {
  padding: 54px;
  border-radius: 12px;
}

.shadow {
  filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.05));
}

.titlebar {
  text-align: center;
  margin-bottom: 34px;
}

.titlebar h4 {
  color: #223635;
  text-align: center;
  font-family: Butler;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0 0 5px 0;
}

.titlebar p {
  color: #b8b8b8;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.didgital_step_form {
  max-width: 540px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 7% 90%;
  gap: 20px;
}

.didgital_step_form ul.step-num {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.didgital_step_form.flex-col {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.didgital_step_form.flex-col .verify-heading {
  margin-top: 40px;
  margin-bottom: 20px;
}

.didgital_step_form.flex-col .verify-heading h4 {
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.didgitally-bars {
  max-width: 478px;
  margin: 0 auto;
}

.didgitally-bars h3.setNoInfoColor {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 1.389vw;
  display: flex !important;
  justify-content: center;
}

.didgitally-bars .verify-btn,
.marginTop20 {
  margin-top: 20px;
}

.didgitally-bars .verify-detail {
}

.didgitally-bars .verify-detail svg {
  font-size: 24px !important;
}

.didgital_step_form.flex-col ul.step-num {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0px 60px;
}

.DigitalVerification .titlebar {
  margin-bottom: 18px;
}

.DigitalVerification .titlebar h4 {
  font-family: "Google Sans" !important;
}

.didgital_step_form ul.step-num li svg {
  font-size: 24px;
  width: 20px;
  height: 20px;
  fill: #2f2f2f;
}

.didgital_step_form ul.step-num li.iconadd {
  width: 64px;
  height: 64px;
}

.didgital_step_form ul.step-num::after {
  content: "";
  position: absolute;
  left: 50%;
  width: 100px;
  height: 2px;
  background: #a4eb63;
  transform: translate(-50%, -50%);
  top: 50%;
}

.didgital_step_form ul.step-num li {
  width: 24px;
  height: 24px;
  background: #7f7f7f;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 2;
  position: relative;
  margin: 0 auto;
}

.Guarantorbar {
  margin-top: 20px;
}

.Guarantorbar .divider {
  text-align: center;
  margin-bottom: 20px;
  color: #223635;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.Guarantorbar .btnoutline {
  width: 100%;
  background: transparent !important;
  color: #223635 !important;
}

.newmodalui .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.modal-content:has(.set-cross-icon) {
  padding: 30px;
}

.newmodalui .modal-header button.btn-close {
  margin: 0;
  position: absolute;
  right: 20px;
  z-index: 1 !important;
  top: 20px;
  padding: 0;
  /* filter: invert(1); */
  opacity: 1;
}

.newmodalui .modal-header.set-cross-icon button.btn-close {
  filter: unset;
}

.already_guarantor {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.already_guarantor img {
  height: 216px;
  /*! max-width: 216px; */
  width: 100% !important;
}

.newmodalui .modal-body .auth-form {
  margin: 0;
}

.newmodalui .modal-body .auth-form .forminput label.forminput {
  margin-bottom: 0.833vw;
}

.newmodalui .modal-body .auth-form .forminput .bankname {
  border-radius: 0.833vw;
  border: 1.4px solid #223635;
  background: #fff;
  padding: 1.111vw;
  min-height: 4.028vw;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.694vw;
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.newmodalui .modal-body {
  padding: 0px 20px 20px 20px;
  border: none !important;
}

.didgitally-bars .vreify-box .verify-btn .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.verfiedbar .didgitally-bars .disabled {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

.verfiedbar ul.step-num li.active,
.verfiedbar li.disabled {
  background: #a3eb64 !important;
}

.verfiedbar .active .vreify-box .verify-btn .btnorange {
  background: #a3eb64 !important;
}

.didgitally-bars {
  display: grid;
  gap: 20px;
}

.didgital_step_form ul.step-num li.active {
  background: #a3eb64;
}

.backbtn1 {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 20px;
}

.didgitally-bars .disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.didgitally-bars .vreify-box {
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background: transparent;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
}

.didgitally-bars .vreify-box .verify-detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 15px;
}

.didgitally-bars .vreify-box .verify-detail svg {
  font-size: 32px;
}

.didgitally-bars .vreify-box .verify-detail .detailin {
  display: grid;
  gap: 5px;
}

.didgitally-bars .vreify-box .verify-detail .detailin h4 {
  color: #223635;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.didgitally-bars .vreify-box .verify-detail .detailin p {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.second-aisde a {
  position: relative;
  top: 0px;
}

.hover-dropdown a {
  display: flex;
  font-size: 1.042vw;
  padding: 0.417vw 1.389vw;
  color: #000000;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0px;
  height: 2.778vw;
}

.tabs-item {
  display: flex;
  justify-content: space-between;
}

.profile-page.marless {
  padding-left: 0;
  margin-left: 0;
}

.hover-dropdown {
  width: 157px;
  border-radius: 8px;
  background-image: url(../img/dropdown.png);
  /* position: absolute; */
  width: 11.458vw;
  height: 7.917vw;
  content: "";
  top: 0px;
  z-index: -1;
  background-size: 11.4vw 7.917vw;
  background-repeat: no-repeat;
  left: -90px;
  right: 0;
  display: none;
}

.hover-dropdown img {
  width: 1.042vw;
  height: 0.972vw;
  object-fit: contain;
  margin-right: 0.694vw;
}

.re-div {
  position: relative;
}

.tabs-item {
  background: #ffffff;
  padding: 25px;
  margin-bottom: 25px;
  background: #ffffff;
  border-radius: 8px;
  height: auto;
  /* filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.05)); */
}

.right-wapper-inner h3 {
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  font-family: "Google Sans";
  margin-bottom: 20px;
}

.buy-section {
  background: #ffffff;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
}

.buy-section h5 {
  font-family: "Google Sans";
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 10px 0px;
}

.buy-section p {
  font-family: "Google Sans";
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
  margin: 10px 0px;
}

.inner-content {
  display: flex;
  justify-content: space-between;
}

.buy-section a {
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #253837;
  font-family: "Google Sans";
}

.buy-info {
  display: flex;
  justify-content: space-between;
}

.buy-info h4 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
  color: #000000;
  font-family: "Google Sans";
  position: relative;
  left: 0px;
}

.content-disable h5 {
  font-family: "Google Sans";
  font-size: 16px;
  line-height: 19px;
  color: #cacaca;
  margin: 30px 0px;
}

.content-disable p {
  font-family: "Google Sans";
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #cacaca;
  margin-bottom: 10px;
}

.content-disable button {
  background: #253837;
  border-radius: 12px;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: "Google Sans";
  width: -webkit-fill-available;
  height: 52px;
  max-width: 350px;
  height: 52px;
}

.Score-content {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 20px;
}

#rent_from_income_select {
  border-radius: 0.833vw;
  border: 1px solid var(--Grey, #7f7f7f);
  background: #fff;
  width: 300px;
  height: 48px;
  flex-shrink: 0;
  line-height: 2.083vw !important;
}

.max-rent-div {
  border-radius: 0.417vw;
  background: #edfbe0;
  margin: 1.25vw 0;
  padding: 1.667vw 1.25vw;
}

.rent-slider .MuiSlider-valueLabelOpen.MuiSlider-valueLabel {
  transform: translate(50%, -14%) rotate(134deg) scale(1) !important;
  font-size: 0.972vw;
  width: 2.361vw !important;
  height: 2.361vw !important;
}

.rent-slider
  .MuiSlider-valueLabelOpen.MuiSlider-valueLabel
  .MuiSlider-valueLabelCircle {
  transform: rotate(-135deg) !important;
}

.css-1o63r9r-MuiSlider-root .MuiSlider-valueLabel {
  width: 2.639vw !important;

  height: 2.639vw !important;
}

#viewDocumentBtn svg {
  width: 1.25vw !important;
  height: 1.25vw !important;
}

.css-1bvr8oc-MuiSlider-markLabel {
  font-size: 0.972vw !important;
}

.PhoneInput {
  background-color: #ffffff;
}

.PhoneInputInput {
  background: transparent;
  border: none !important;
}

.process-bar-wapper {
  text-align: center;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-box,
.name-box-new {
  /* margin-top: -90px; */
}

.Score-content-list h3 {
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  /* --------------------new responsive css (heena)----------------- */
  /* gap: 7px; */
  gap: 0.513vw;
}

.infodorpdown .tooltip .verify-btn .btn {
  background: #ef7833;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 8px;
  padding: 11px;
  gap: 6px;
  font-size: 12px; */
  border-radius: 0.58vw;
  padding: 0.8vw;
  gap: 0.44vw;
  font-size: 0.87vw;
}

.Score-content-list h3 ul.infodorpdown.scrorebardrop {
  position: relative;
}

.userscrollprofile-topbar .border-pd h4 {
  color: #121212;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 18px; */
  font-size: 1.32vw;
}

ul.infodorpdown.scrorebardrop ul.tooltip {
  /* --------------------new responsive css (heena)----------------- */
  /* right: -201px; */
  right: -10.46vw;
}

ul.score-textgray span {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.summaryinfobar {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  /* --------------------new responsive css (heena)----------------- */
  /* gap: 10px; */
  gap: 0.73vw;
}

ul.infodorpdown.summarydropdum {
  position: relative;
}

.summaryinfobar ul.tooltip {
  /* --------------------new responsive css (heena)----------------- */
  /* left: 172px; */
  left: 0;
}

.summaryinfobar ul.tooltip p,
.infodorpdown .tooltip li {
  color: #728685;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 12px;
  margin: 0 0 10px 0; */
  font-size: 0.88vw;
  margin: 0 0 0.73vw 0;
}

.tooltip-heading {
  font-size: 0.972vw;
}

.step-form-name.mobile-model-heading img {
  height: 3.75vw;
  width: 3.75vw;
}

.tennat-left-box-top .infodorpdown .tooltip ul,
.upload_doc_tooltip .infodorpdown .tooltip ol {
  padding-left: 1.736vw;
  margin-top: 0.556vw;
}

.tennat-left-box-top .infodorpdown .tooltip ul li {
  list-style: disc;
}

.upload_doc_tooltip .infodorpdown .tooltip ol li {
  list-style: decimal;
}

.btnupdate {
  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 8px;
  padding: 11px;
  gap: 6px;
  font-size: 12px; */
  border-radius: 0.42vw;
  padding: 0.57vw;
  gap: 0.31vw;
  font-size: 0.62vw;
  background: #ef7833;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  font-family: "Google Sans";
}

.infodorpdown:hover .tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
}

.btnupdate svg {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 15px; */
  font-size: 1.1vw;
}

ul.infodorpdown li button.iconinfo {
  border: none;
  background: transparent;
  color: #fd7131;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 20px; */
  font-size: 1.46vw !important;
}

ul.infodorpdown.scrorebardrop ul.tooltip li {
}

ul.infodorpdown.scrorebardrop ul.tooltip li:last-child {
  margin: 0;
}

ul.score-textgray span {
  position: relative;
}

ul.infodorpdown li {
  position: relative;
}

.infodorpdown .tooltip {
  position: absolute;
  background: #fff;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  display: block;
  top: 0;
  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 14px;
  border: 1px solid rgba(221, 221, 221, 0.6);
  box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.06);
  padding: 12px;
  width: 200px; */
  border-radius: 1.02vw;
  border: 0.07vw solid rgba(221, 221, 221, 0.6);
  box-shadow: 0px 0.59vw 1.76vw 0.15vw rgba(0, 0, 0, 0.06);
  padding: 0.88vw;
  width: 20vw;
}

.score-below-btn {
  flex: 1;
  max-width: 50%;
  justify-content: center;
}

.btn-combo-wrapper {
  /* margin:0 -15px; */
  padding: 0 !important;
  padding-top: 0.347vw !important;
}

.btn-combo-wrapper > div {
  padding: 0 12px;
}

ul.score-textgray {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  margin-bottom: 14px;
  margin-top: 7px;
}

ul.score-textgray span {
  font-family: "Google Sans";
  line-height: normal;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 700;
  color: #728685;
}

.whenaddgurantor {
  text-align: center;
}

.manual-card {
  background: #e2dddd;
  padding: 10px;
  border-radius: 10px;
}

.manual-card .manual-card-header {
  display: flex;
  justify-content: space-between;
}

.manual-card .manual-card-header .manual-card-heading {
  text-align: left;
  color: #253837;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.manual-card .manual-card-header .regularrent-date span {
  color: #6a6a6a;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.manual-card .manual-card-header .regularrent-date {
  color: #6a6a6a;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.manual-card.paymentbetween .regularrent-amount {
  text-align: left;
  color: #ef7833;
  font-size: 1.944vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.manual-card.paymentbetween .manual-card-desc {
  text-align: left;
  color: #7f7f7f;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.4vw;
}

.manual-card.paymentbetween {
  border-radius: 12px;
  background: #f1f1f1;
  padding: 12px;
}

.whenaddgurantor h3 {
  color: #121212;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.whenaddgurantor h5 {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scorebtnbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* --------------------new responsive css (heena)----------------- */
  /* margin-top: 20px; */
  margin-top: 1.46vw;
}

.scorebtnbar .lleftbarbtn,
.scorebtnbar .rytbarbtn {
  flex: 0 0 50%;
  max-width: 50%;
}

.scorebtnbar .lleftbarbtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.scorebtnbar .rytbarbtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.scorebtnbar button {
  color: #ef7833;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 15px;
  column-gap: 5px; */
  font-size: 1.1vw;
  column-gap: 0.37vw;
}

.left-wapper50 {
  width: 50%;
}

.left-wapper100 {
  width: 100%;
}

.disable-add {
  opacity: 0.5;
}

.btn-bro {
  background: transparent !important;
  color: #253837 !important;
  /* --------------------new responsive css (heena)----------------- */
  /* border: 2px solid #253837 !important; */
  border: 0.133vw solid #253837 !important;
  /* padding: 14.863px 74.326px; */
  padding: 0.991vw 4.955vw;
}

.btn-bro:hover {
  background: #253837 !important;
  color: #ffffff !important;
  /* --------------------new responsive css (3brain)----------------- */
  /* border: 2px solid #253837 !important; */
  border: 0.133vw solid #253837 !important;
}

.card-error {
  color: red;
}

.has-sub.active a span img {
  opacity: 1;
}

.process-bar-wapper img {
  width: 200px;
}

.left-upload-wapper label {
  font-family: "Google Sans";
  font-size: 12px;
  line-height: 14px;
  color: #121212;
  width: 100%;
  margin-bottom: 16px;
}

.title-kyc h2 {
  font-family: "Google Sans";
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #121212;
  padding-bottom: 60px;
}

.upload-box {
  max-width: 361px;
  height: 100px;
  background: rgba(231, 87, 53, 0.04);
  border: 1px dashed rgba(231, 87, 53, 0.6);
  border-radius: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  padding: 10px;
}

.upload-box h5 {
  font-family: "Google Sans";
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
  color: #f09783;
}

.connect-bank {
  width: 100%;
  /* --------------------new responsive css (heena)----------------- */
  /* height: 56px; */
  height: 3.733vw;
  /* border-radius: 12px; */
  border-radius: 0.8vw;
}

.custom-input {
  outline: none;
  font-weight: 400;
  padding-bottom: 0;
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* width: 54px;
  height: 62px; */
  width: 100% !important;
  /* width: 25vw !important; */
  height: 4vw !important;
  /* border-radius: 14px; */
  border-radius: 1.02vw;
  /* font-size: 14px; */
  font-size: 1.02vw;
  /* border: 1px solid #c4c4c4; */
  border: 0.073vw solid #c4c4c4;
  /* border-radius: 12px; */
  border-radius: 0.88vw;
  /* padding-left: 30px; */
  padding-left: 1vw !important;
  /* margin-bottom: 20px; */
  margin-bottom: 1.46vw;
}

/* --------------------new responsive css (heena)----------------- */
.custom-input option {
  padding-inline: 0.133vw;
  padding-block-end: 0.067vw;
}

.status_bar h5 {
  color: #fa1313;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-align: center;
}

.addRedColor {
  color: red !important;
}

.custom-input::placeholder {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 14px; */
  font-size: 1.02vw;
  color: #cacaca;
}

.left-upload-wapper .form-check-input {
  border: 2px solid #253837 !important;
}

.form-check-input:checked {
  background-color: #253837;
}

.select-radio {
  display: flex;

  align-items: center;
  flex-wrap: wrap;
}

.select-radio .form-check label.form-check-label {
  margin: 0;
}

.select-radio .form-check {
  padding: 0 !important;
  /* --------------------new responsive css (heena)----------------- */
  /* margin: 0 10px 0 0 !important; */
  margin: 0 0.666vw 0 0 !important;
}

.select-radio .form-check input.form-check-input {
  float: none !important;
  /* --------------------new responsive css (heena)----------------- */
  /* margin: 0 7px 0 0 !important; */
  margin: 0 0.466vw 0 0 !important;
  /* flex: 0 0 20px; */
  flex: 0 0 1.333vw;
  /* width: 20px; */
  width: 1.333vw;
  /* height: 20px; */
  height: 1.333vw;
}

.select-radio .form-check {
  margin-bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.select-radio .form-check:last-child {
  margin-left: 20px;
}

.img-bank {
  vertical-align: middle;
  margin-bottom: 4px;
}

.select-radio label {
  margin-bottom: 0;
}

.form-check {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.main-wapper {
  padding: 65px 0px;
  height: 100vh;
  display: flex;
  align-items: center;
}

.modal-cancel-btn,
.modal-submit-btn {
  padding: 14px 80px;
}

.flex-wapper {
  display: flex;
  justify-content: space-around;
}

.width-section {
  max-width: 362px;
}

button.btn-fill-border.change-password-btn {
  background: #f8f8f8 !important;
  border: none !important;
  color: #ef7833 !important;

  /* --------------------new responsive css (3brain)----------------- */
  /* border-radius: 12px; */
  border-radius: 0.88vw;
}

.fetching_algoan_modal .modal-content {
  padding: 20px;
}

.fetching_algoan_modal h3 {
  padding: 15px;
  color: #121212;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.fetching_algoan_modal .modal-content {
  overflow: hidden;
  position: relative;
}

label.forminput.password_error {
  color: red;
}

.fetching_algoan_modal .modal-content {
  overflow: hidden;
  position: relative;
}

.fetching_algoan_modal .modal-content::before {
  content: "";
  -webkit-animation: shine 3s infinite;
  animation: shine 3s infinite;
  background: #ffffff;
  -webkit-filter: blur(150px);
  filter: blur(150px);
  left: -100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  border-radius: 0;
  content: "";
  display: block;
  height: 100%;
  /*! -webkit-transform: rotate(30deg); */
  /*! transform: rotate(30deg); */
}

@-webkit-keyframes shine {
  from {
    left: 0px;
  }

  to {
    left: 100%;
  }
}

@keyframes shine {
  from {
    left: 0px;
  }

  to {
    left: 100%;
  }
}

.fetching_algoan_modal h4 {
  color: var(--Light-Text, #586363);
  text-align: center;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.go-back-home {
  position: absolute;
  left: 0;
  margin-left: 50px !important;
}

.already_guarantor_someone {
  background-image: url("../../../public/images/alreadyGurantorBack.svg");
}

.already_guarantor_someone h4 {
  color: #a3eb64;
  text-align: center;
  font-style: normal;
  font-size: 40px;
  font-weight: 900;
}

.already_guarantor_someone h5 {
  color: var(--white, #fff);
  text-align: center;
  font-family: "Google Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.someonegaurentor .modal-header {
  padding: 0;
}

.someonegaurentor .modal-body {
  padding: 0;
}

.someonegaurentor .modal-body .already_guarantor_someone {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 54px 34px;
}

.someonegaurentor .modal-content {
  position: relative;
}

.someonegaurentor .modal-dialog {
  max-width: 576px;
}

.someonegaurentor .modal-content .btn-close {
  position: absolute;
  top: 0;
  right: 0;
}

.go-back-home span {
  color: var(--white, #fff);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.go-back-home a {
  display: flex;
  gap: 10px !important;
  justify-content: center;
  align-items: center;
}

.tab-with-editbtn {
  width: 100% !important;
  position: relative;
  /* --------------------new responsive css (3brain)----------------- */
  /* margin-bottom: 34px; */
  margin-bottom: 2.49vw;
}

.tab-with-editbtn .editbar {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  margin-block-start: 2.49vw;

  /* --------------------new responsive css (3brain)----------------- */
  /* gap: 10px; */
  gap: 0.73vw;
}

.tab-with-editbtn .editbar button.btn-fill-border {
  margin: 0 !important;
}

/* --------------------new responsive css (3brain)----------------- */
/* .btn-fill-border {
  width: 200px;
  height: 52px;
  background: transparent;
  border-radius: 12px;
  color: #253837;
  float: right;
  margin-top: 30px;
  margin-right: 0px;
  border: 1px solid #253837;
  font-family: "Google Sans";
  margin-bottom: 20px;
}

button.btn-fill-border:hover {
  background: #253837;
  border-radius: 12px;
  color: #ffffff;
  border: 0px;
}

.btn-fill-border-orange {
  color: #f27833 !important;
  border: 1px solid #f27833 !important;
} */

button.btn-fill-border-orange:hover {
  color: #ffffff !important;
  background: #f27833 !important;
}

.Auth-wrapper-2 {
  min-height: 100vh;
  background-color: var(--base-primary);
  background-image: url("../../../public/images/auth-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 0;
}

.Auth-wrapper-2 .terms-bar {
  margin-top: 20px;
}

.metermodal .modal-body {
  fill: #fff;
  stroke-width: 1px;
  stroke: #eaeaea;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.04));
  padding: 44px;
  text-align: center;
}

.metermodal .modal-body form.loginform {
}

.metermodal .modal-body form.loginform h4 {
  color: #223635;
  text-align: center;
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 1.667vw;
}

.loginform p {
  font-size: 1.25vw;
}

.Property-wrapper {
  min-height: 100dvh;
  padding: 30px;
  background: #fff;
  filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.05));
  margin-bottom: 40px;
}

.btn-fill2 {
  width: 13.889vw;
  height: 3.681vw;
  background: #253837;
  border-radius: 0.833vw;
  color: #ffffff;
  float: right;
  margin-top: 2.778vw;
  border: 0;
  font-size: 1.111vw;
  font-family: "Google Sans";
}

.btn-fill:hover {
  background: transparent;
  color: #253837;
  border: 1px solid #253837;
}

.authbar.hide-bg {
  background: #fff;
}

.right-content-info h2 {
  font-size: 24px;
  line-height: 30px;
  color: #212121;
  font-family: "Google Sans";
  margin-bottom: 26px;
}

.right-content-info p {
  font-size: 16px;
  color: #797979;
  font-family: "Google Sans";
  margin-bottom: 10px;
  margin-top: 74px;
}

.info-wapper .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #253837 !important;
  outline: 0;
  box-shadow: inherit !important;
}

.list-info-wapper {
  width: 30%;
  display: flex;
  align-items: end;
  padding-right: 40px;
}

.right-content-width {
  width: 70%;
  border-left: 1px solid #dddddd;
  padding-left: 40px;
}

.list-info-wapper button:hover {
  color: #212121;
}

.list-info-wapper button {
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #212121;
  font-family: "Google Sans";
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.nav-pills .nav-link {
  background-color: transparent !important;
}

.list-info-wapper .nav-link.active,
.color-green {
  color: #253837 !important;
}

.circle-active {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #939090;
  text-align: center;
  line-height: 34px;
  margin-left: 15px;
}

.circle-active.active {
  background-color: #253837;
}

.list-info-wapper .nav-link.active .circle-active {
  background-color: #253837;
}

.tabs-item .edittitle h4 {
  margin: 0;
}

.info-wapper select {
  /* color: #cacaca !important; */
  padding-bottom: 0 !important;
  text-align: left;
  width: 100%;
  background-color: transparent;
  /* --------------------new responsive css (heena)----------------- */
  /* border: 1px solid #c4c4c4 !important;
  border-radius: 12px;
  height: 62px;
  padding-left: 15px;
  margin-bottom: 16px;
  font-size: 14px;
  padding-right: 15px; */
  border: 0.066vw solid #c4c4c4 !important;
  border-radius: 0.8vw;
  height: 4.133vw;
  padding-left: 1vw;
  margin-bottom: 1.066vw;
  font-size: 0.933vw;
  padding-right: 1vw;
}

/* property */

.Property {
  color: #ef7833;
  text-align: left;
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 22px; */
  font-size: 1.466vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* --------------------new responsive css (heena)----------------- */
  /* margin-bottom: 30px; */
  margin-bottom: 2vw;
}

.height-auto {
  height: auto;
}

.search-prop-landlord {
  height: 3.75vw !important;
  width: 25.278vw !important;
}

.number-properties {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* margin-bottom: 30px; */
  position: sticky !important;
  top: 75px !important;
  background: #ffffff !important;
  padding: 0.694vw !important;
}

.number-properties h3 span {
  color: #ef7833 !important;
}

.number-properties h3 {
  font-family: "Google Sans";
  font-size: 0.833vw;
  line-height: 14px;
  text-transform: capitalize;
  color: #121212;
  margin-bottom: 0;
}

.number-properties span {
  font-size: 1.111vw;
}

.number-properties a {
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-transform: capitalize;
  color: #253837;
  font-family: "Google Sans";
}

.properties-box {
  background: #ffffff;
  width: 100%;
  padding: 1.389vw;
  margin-bottom: 1.389vw;
  border: 0.6px solid #dddddd;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
  border-radius: 0.556vw;
}

.linked_tenant_div svg {
  width: 1.25vw;
  height: 1.389vw;
}

.properties-box h3 {
  font-size: 16px;
  line-height: 19px;
  color: #121212;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.properties-box h4 {
  font-size: 1.111vw;
  line-height: 19px;
  color: #121212;
  font-family: "Google Sans";
}

.properties-box img:last-child {
  margin-left: 15px;
}

.flex-content {
}

.flex-content ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 10px;
}

.flex-content ul li {
  display: flex;
}

.flex-content ul span {
  font-family: "Google Sans";
  font-size: 0.972vw;
  line-height: 1.181vw;
  color: #9a9a9a;
  margin-bottom: 0.694vw;
  max-width: 50%;
}

.flex-content ul li span:first-child {
  width: 50%;
}

.cl-text {
  color: #121212 !important;
  font-family: "Google Sans";
  word-wrap: break-word;
}

.flex-content ul:last-child {
  margin-bottom: 0;
}

.info-wapper label {
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 14px; */
  font-size: 0.933vw;
  /* line-height: 18px; */
  line-height: 1.2vw;
  color: #121212;
  width: 100%;
  /* margin-top: 15px; */
  margin-top: 1vw;
  /* margin-bottom: 9px; */
  margin-bottom: 0.6vw;
  vertical-align: middle;
  display: block;
}

.Notifications-wapper p.infotext span {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
  font-weight: bold;
  font-family: "Google Sans";
  margin-left: 3px;
}

.admin-name2 {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  margin: 0 auto;
  /* background: #fff3f0; */
  /* --------------------new responsive css (3brain)----------------- */
  /* width: 120px;
  height: 120px;
  line-height: 127px;
  font-size: 34px; */
  width: 8.78vw;
  height: 6.78vw;
  line-height: 9.3vw;
  font-size: 2.49vw;
}

.admin-name2 a {
  color: #253837;
  font-family: "Google Sans";
  /* --------------------new responsive css (3brain)----------------- */
  /* font-size: 34px; */
  font-size: 2.49vw;
}

.primaryaccordion .setaccordionhead .accordion-button {
  margin: 0 !important;
  justify-content: space-between !important;
  width: 100% !important;
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 20px !important;
  min-height: 85px; */
  padding: 1.46vw !important;
  min-height: 6.22vw;
}

.header-edit h3 {
  text-transform: capitalize;
  color: #253837;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  font-family: "Google Sans";
  /* --------------------new responsive css (3brain)----------------- */
  /* font-size: 22px;
  line-height: 26px; */
  font-size: 1.61vw;
  line-height: 1.9vw;
}

.header-edit h4 {
  font-family: "Google Sans";
  color: #212121;
  /* --------------------new responsive css (3brain)----------------- */
  /* margin-bottom: 20px;
  font-size: 22px;
  line-height: 26px; */
  font-size: 1.61vw;
  line-height: 1.9vw;
  margin-bottom: 1.46vw;
}

/* --------------------new responsive css (3brain)----------------- */
.user-name {
  font-size: 1.61vw;
}

.header-edit {
  position: relative;
}

.header-edit span {
  position: absolute;
  left: 0;
}

.edit-line {
  /* --------------------new responsive css (3brain)----------------- */
  /* border-bottom: 0.5px solid #dddddd; */
  border-bottom: 0.0366vw solid #dddddd;
  /* padding-bottom: 20px; */
  padding-bottom: 1.46vw;
  /* margin-bottom: 20px; */
  margin-bottom: 1.46vw;
}

/* --------------------new responsive css (3brain)----------------- */
.edit-line span img {
  width: 1.02vw;
  height: 1.02vw;
}

/* --------------------end new responsive css (3brain)----------------- */

.Property-wrapper .backbtn_bar {
  margin-bottom: 40px;
}

.backbtn_bar button svg {
  width: 0.694vw;
  height: 1.25vw;
}

.Property-wrapper .backbtn_bar .btnback {
  border: none;
  background: transparent;
  outline: none;
}

.asguarantor .modal-title.h4 {
  color: #253837;
  text-align: center;
  font-family: "Google Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.modal-title.h4 {
  color: #253837;
  text-align: center;
  font-family: "Google Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}

.asguarantor .auth-form {
  margin: 24px 0 18px 0 !important;
  padding: 18px !important;
  border-radius: 8px;
  border: 0.6px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
}

.asguarantor .auth-form .formtile {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.asguarantor .auth-form .formtile h4 {
  color: var(--Orange, #ef7833);
  text-align: center;
  font-family: "Google Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.asguarantor .auth-form label.forminput {
  color: #9a9a9a;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px !important;
}

.asguarantor .auth-form p {
  color: #121212;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 3px 0;
  word-wrap: break-word;
  white-space: normal;
}

.asguarantor .modal-body .btngroup {
  gap: 10px !important;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
}

.asguarantor .modal-header {
  padding-bottom: 0;
}

.Property-wrapper .authbar-inner .resend-text {
  width: 35.764vw;
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.newmodalui select.form-control {
  padding: 15px;
  font-size: 16px;
  line-height: 1;
}

.newmodalui .modal-body .auth-form .forminput label.forminput {
  font-size: 1.111vw;
}

.edit-line h4 {
  font-weight: 400;
  font-family: "Google Sans";
  color: #9a9a9a;

  /* --------------------new responsive css (3brain)----------------- */
  /* font-size: 14px; */
  font-size: 1.02vw;
  /* line-height: 17px; */
  line-height: 1.24vw;
  /* margin-bottom: 15px; */
  margin-bottom: 1.1vw;
}

.edit-line h3 {
  /* text-transform: capitalize; */
  font-weight: 400;
  font-family: "Google Sans";
  color: #121212;
  margin-bottom: 0;
  overflow-wrap: break-word;

  /* --------------------new responsive css (3brain)----------------- */
  /* font-size: 16px; */
  font-size: 1.17vw;
  /* line-height: 19px; */
  line-height: 1.39vw;
}

.edit-tabs {
  /* height: 56px; */
  background: #fafafa;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* --------------------new responsive css (3brain)----------------- */
  /* box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.02); */
  /* border-radius: 8px; */
  /* padding: 5px 10px; */
  /* margin-bottom: 20px; */
  box-shadow: inset 0px 0px 0.73vw rgba(0, 0, 0, 0.02);
  border-radius: 0.59vw;
  padding: 0.37vw 0.73vw;
  margin-bottom: 1.47vw;
}

.edit-tabs li.nav-item {
  flex: 0 0 50%;
  max-width: 50%;
}

.tab-with-editbtn h3.Property {
}

.basicbox {
  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 12px;
  box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.12) inset;
  padding: 24px;
  margin-bottom: 30px; */
  border-radius: 0.8vw;
  box-shadow: 0px 0px 2.27vw 0px rgba(0, 0, 0, 0.12) inset;
  padding: 1.6vw;
  margin-bottom: 2vw;
}

.basicbox label {
}

.info-wapper label {
  margin-top: 0;
}

.info-wapper label h3.Property {
}

.basicbox h3.Property {
  /* --------------------new responsive css (heena)----------------- */
  /* margin-bottom: 15px; */
  margin-bottom: 1vw;
}

.graybox {
  background: rgb(39 59 58 / 0.4%);
}

.verify-wrap label {
  color: #121212;
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 12px; */
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.verify-wrap label span {
  color: #9b9b9b;
  /* --------------------new responsive css (heena)----------------- */
  /* margin-left: 2px; */
  margin-left: 0.133vw;
}

.verify-wrap p {
  color: #5cba5f;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 14px; */
  font-size: 0.933vw;
  /* margin: 10px 0 14px 0; */
  margin: 0.666vw 0 0.933vw 0;
  /* gap: 7px; */
  gap: 0.466vw;
}

.verify-wrap p img {
  /* --------------------new responsive css (heena)----------------- */
  width: 1.02vw;
  height: 1.02vw;
}

.btngroup {
}

.btngroupbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  /* --------------------new responsive css (heena)----------------- */
  /* gap: 20px; */
  gap: 1.46vw;
}

.btngroupbar button {
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 16px 40px; */
  padding: 1.066vw 2.667vw;
  border: none;
  background: transparent;
  color: #253837;
  text-align: center;
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 1.066vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.btngroupbar button.btncancle {
  border-radius: 12px;
  border: 1px solid #223635;
  background: #fff;
  color: #223635;
}

.btn-reject {
  border-radius: 12px;
  border: 1px solid #e02727;
  background: #fff;
  color: #e02727;
  text-align: center;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 12px 15px;
  width: 100% !important;
}

button.btncancle {
  border-radius: 12px;
  border: 1px solid #223635;
  background: #fff;
  color: #223635;
}

.btngroupbar button.btnsubmit {
  /* --------------------new responsive css (heena)----------------- */
  /* border-radius: 12px; */
  border-radius: 0.8vw;
  background: #a3eb64;
}

.verificationbtn .connect-bank {
  width: auto;
  color: #253837 !important;
  font-family: "Google Sans" !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 14px; */
  font-size: 0.933vw;
  /* padding: 20px 100px; */
  padding: 1.333vw 6.666vw;
  background: #a3eb64 !important;
  /* border-radius: 12px; */
  border-radius: 0.8vw;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
  gap: 0.666vw;
}

.verificationbtn .connect-bank svg {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 18px; */
  font-size: 1.2vw;
}

.edittitle {
  /* --------------------new responsive css (heena)----------------- */
  margin-bottom: 1.46vw;
  /* margin-bottom: 20px; */
}

.graybox {
  background: rgb(39 59 58 / 0.4%);
}

.edittitle .backbtn {
  /* --------------------new responsive css (heena)----------------- */
  font-size: 1.46vw;
  /* font-size: 20px; */
  padding: 0;
  border: none;
  background: transparent;
}

.edittitle h4 {
  color: #121212;
  text-align: center;
  font-family: "Google Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;

  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 22px; */
  font-size: 1.61vw;
}

.orangebox {
  background-color: hsl(18.93deg 100% 59.61% / 5%);
}

.edit-tabs button {
  padding: 0;
}

/* --------------------new responsive css (heena)----------------- */
/* .basicbox {
  border-radius: 12px;
  box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.12) inset;
  padding: 24px;
  margin-bottom: 24px;
}

.basicbox h3.Property {
  margin-bottom: 15px;
} */

.edit-tabs .nav-item .nav-link {
  color: #253837;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;

  /* --------------------new responsive css (3brain)----------------- */
  /* border-radius: 8px;
  height: 44px;
  max-width: 180.5px;
  font-size: 14px; */
  border-radius: 0.59vw;
  height: 3.22vw;
  /* max-width: 13.19vw; */
  font-size: 1.02vw;
}

.edit-tabs .nav-item .nav-link.active {
  background: #a3eb64 !important;
  color: #253837;
  font-family: "Google Sans";
}

.tab-with-editbtn .edit-tabs {
  /* --------------------new responsive css (3brain)----------------- */
  /* max-width: 362px; */
  max-width: 26.52vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel {
  margin-bottom: 1.39vw;
  position: relative;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  .form-check-label {
  width: 100%;
  border-radius: 0.69vw;
  border: 1px solid #d7d8de;
  background: transparent;
  padding: 0.83vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.04vw;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .imgbar {
  background: rgb(243 243 243 / 21%);
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .imgbar
  img {
  filter: brightness(0);
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label {
  background: #a3eb64;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .contentbar
  h5,
.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar
  .uilabel
  input[type="checkbox"]:checked
  ~ label.form-check-label
  .contentbar
  p {
}

.Notifications-wapper h4 {
  text-transform: capitalize;
  color: #121212;
  font-weight: bold;
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 1.17vw;
  line-height: 1.39vw;
}

.Notifications-wapper span {
  font-family: "Google Sans";
  font-size: 1.111vw;
  color: #121212;
}

.Notifications-wapper h6 {
  font-family: "Google Sans";
  /* --------------------new responsive css (heena)----------------- */
  font-size: 1.02vw;
  line-height: 1.24vw;
  text-transform: capitalize;
  color: #cacaca;
}

.Notifications-wapper p {
  font-family: "Google Sans";

  color: #121212;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 1.17vw;

  /* line-height: 19px; */
  line-height: 1.39vw;
}

.Notifications-wapper {
  background: #fff;
  border-bottom: 1px solid #ddd6;
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
  padding: 25px;
  align-items: center;
}

.Notifications-wapper.bg-wapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  background: #fafafa;
  border-bottom: 1px solid #dddddd;
  opacity: 1;
}

.Notifications-wapper img {
  transform: rotate(180deg);
  opacity: 0.5;
  float: right;
  position: relative;
  /* --------------------new responsive css (heena)----------------- */
  /* width: 7px; */
  width: 0.89vw !important;

  /* top: 10px; */
  top: 0.73vw !important;
}

.space-custom {
  padding: 25px;
}

.space-custom img {
  padding-left: 0px;
}

.bg-main-white {
  background-color: #ffffff;
}

.edit-tabs.profile-edit .nav-item .nav-link {
  height: 44px;
  width: 100%;
  font-size: 14px;
  max-width: 100%;
}

.Notifications-wapper.opacity-wapper {
  opacity: inherit;
  /* padding-left: 0;
	padding-right: 0; */

  /* --------------------new responsive css (heena)----------------- */
  /* padding: 25px !important; */
  padding: 1.83vw !important;
}

.Notifications-wapper.opacity-wapper img {
  opacity: inherit;
  float: inherit;
  width: inherit;
  position: inherit;
}

.back-part-wapper {
  /* position: relative; */
  /* right: -60px; */
  width: 8px;
}

.Notifications-wapper.opacity-wapper h6 {
  font-family: "Google Sans";
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #cacaca;
  margin-top: 10px;
}

.profile-page input {
  padding-left: 40px;
  padding-top: 0;
  height: 56px;
  border-bottom: 1px solid #c4c4c4 !important;
}

.Search-bar {
  position: relative;
}

.Search-bar a {
  position: absolute;
  /* --------------------new responsive css (heena)----------------- */
  /* left: 15px;
  top: 15px; */
  /* left: 1.1vw !important; */
  right: 1.1vw !important;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
}

/* --------------------new responsive css (heena)----------------- */
.Search-bar img {
  width: 1.46vw !important;
  height: 1.46vw !important;
}

.Shortlisted-Properties {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.Shortlisted-Properties h3 {
  font-size: 15px;
  font-family: "Google Sans";
}

.Score-left .process-bar-wapper.process2 {
  width: 100% !important;
  margin: 0 !important;
}

.userscrollprofile-topbar .Shortlisted-Properties a {
  font-size: 0;
}

.Score-left .process-bar-wapper.process2 svg.CircularProgressbar {
  width: 70% !important;
}

.Shortlisted-Properties a {
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-transform: capitalize;
  color: #253837;
  font-family: "Google Sans";
}

.group-btn-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.share-profile .share-it {
  display: flex ;
  justify-content: space-between;
  margin: 1.389vw 0;
}
.share-profile .modal-content .modal-title {
  padding-right: 50px;
  padding-left: 50px;
  word-break: break-all;
}
.share-it a, .share-it button {
  background: #253837 !important;
  color: #ffffff;
  /* width: 80px; */
  display: block;
  text-align: center;

  /* --------------------new responsive css (heena)----------------- */
  /* height: 34px; */
  height: 2.49vw;

  /* border-radius: 8px; */
  border-radius: 0.59vw;

  /* line-height: 32px; */
  line-height: 2.34vw;

  /* font-size: 12px; */
  font-size: 0.88vw;

  /* margin-left: 10px; */
  margin-left: 0.73vw;

  /* padding: 0 8px; */
  padding: 0 0.59vw;
}

.share-it .share-btn {
  background-color: transparent !important;
  color: #253837;
  /* --------------------new responsive css (heena)----------------- */
  /* border: 2px solid #253837; */
  border: 0.15vw solid #253837;
}

.share-btn img {
  width: inherit;
  transform: inherit;
  width: 10px !important;
  height: 8px !important;
}

.share-it-profile {
  color: red;
  text-decoration: underline;
}

.profile-page {
  display: grid;
  justify-content: space-between;

  /* margin-bottom: 30px; */
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.bankimagediv {
  border-right: 0.069vw solid #ececec;
  background-color: #253837;
  padding: 1.389vw !important;
  text-align: center;
}

.bankimagediv img {
  margin: 1.736vw 0px;
  width: 25vw;
  height: 15.208vw;
}

.bankimagediv p {
  color: var(--white, #fff);
  text-align: center;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.bankimagediv p {
  color: white;
}

.connectbankdiv h3 {
  color: var(--Dark-green, #253837);
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.528vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.greenbox-terms {
  background: #a3eb64;
  padding: 50px 20px;
}

.footer-img {
  display: flex;
  gap: 120px;
}

.bank-body h3 {
  margin: 40px 0px 15px 0px;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bank-body p {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.bank-body {
  padding: 80px;
}

.bank-btm {
  color: var(--Dark-green, #253837);
  text-align: center;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bank-btm a {
  color: var(--Orange, #ef7833);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.connectbankdiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.389vw !important;
}

.shareQr {
  text-align: center;
}

.profile-page input {
  padding-left: 40px;
  padding-top: 0;
  height: 56px;
}

.Search-bar {
  position: relative;
}

/* .Search-bar a {
  position: absolute;
  left: 15px;
  top: 15px;
} */

.Landlord-details {
  border-bottom: 0.5px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.Landlord-details .edit-line {
  border-bottom: inherit;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.customradio_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 22px;
}

.height-equal {
  height: 62px;
}

.customradio_box label.forminput {
  width: auto;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.customradio_box label.forminput input[type="radio"] {
  accent-color: #ec5915;
}

.Landlord-details:last-child {
  margin-bottom: 0;
}

.Property-wrapper .authbar-inner .resend-text .auth-form .forminput {
  position: relative;
}

.auth-form .btn {
  padding: 0.972vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.556vw;
  flex: 1;
}

.auth-form .btn svg {
  width: 0.972vw;
  height: 0.833vw;
}

.setNoInfoColor {
  color: var(--Dark-green, #253837);
  /* text-align: center; */
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.setNoInfoColor p {
  font-size: 1.25vw;
}

.custom-input {
  line-height: 2.083vw !important;
}

.setNoInfoText {
  color: var(--Dark-green, #253837);
  text-align: left;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.setColorToRed {
  color: rgb(222, 71, 36) !important;
}

.Score-content-list h4 {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 14px; */
  font-size: 1.02vw;
  /* line-height: 17px; */
  line-height: 1.25vw;
  color: #9a9a9a;
  font-family: "Google Sans";
}

.border-pd {
  height: 100%;
  background: #fff;
  /* --------------------new responsive css (heena)----------------- */
  /* padding: 18px;
  border-radius: 8px;
  border: 0.6px solid #ddd;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03); */
  padding: 1.32vw;
  border-radius: 0.59vw;
  border: 0.044vw solid #ddd;
  box-shadow: 0vw 0.29vw 2.49vw 0.073vw rgba(0, 0, 0, 0.03);
}

.score-kyc-images img {
  max-width: 100% !important;
}

.Score-content-list h3 {
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 1.18vw;
  /* line-height: 19px; */
  line-height: 1.39vw;
  color: #121212;
  font-family: "Google Sans";
  overflow-wrap: break-word;
  margin: 0 auto;
}

.Score-content-list {
  /* --------------------new responsive css (heena)----------------- */
  /* margin-bottom: 20px; */
  margin-bottom: 1.46vw;
}

.Score-content-list-2 {
  flex: 1 1 48%;
  max-width: 48%;
}

.Score-content-list-2 h3 {
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  font-family: "Google Sans";
  overflow-wrap: break-word;
  margin: 0 auto;
}

.Score-content-list-2 h4 {
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
  font-family: "Google Sans";
}

.tabs-item.tabs-wapper2 {
  height: 100%;
}

.main-Score span {
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-transform: capitalize;
  color: #253837;
  font-family: "Google Sans";
}

.infodorpdown:hover .tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
}

.Score-content-list h4 {
  text-align: left !important;
  color: #9a9a9a !important;
  font-family: "Google Sans";
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 14px !important; */
  font-size: 1.02vw !important;
  /* margin-bottom: 10px !important; */
  margin-bottom: 0.73vw !important;
}

.Score-content-list h3 {
  color: #121212;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-word;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 16px; */
  font-size: 1.18vw;
  /* margin: 0; */
  margin: 0;
}

.process-bar-wapper.process2 {
  margin: 0 auto;
}

.userscrollprofile-topbar .border-pd h4 {
  color: #121212;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* --------------------new responsive css (heena)----------------- */
  /* margin-bottom: 20px;
  font-size: 18px; */
  margin-bottom: 1.464vw;
  font-size: 1.32vw;
}

.summary-checkes {
  display: flex;
  flex-direction: column;
  /* --------------------new responsive css (heena)----------------- */
  gap: 3px;
  gap: 0.22vw;
}

.location-img {
  transform: inherit !important;
}

.mandatory_span {
  color: var(--Orange, #ef7833);
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cl-date {
  width: 56px;
  padding-left: 10px;
  padding-top: 0;
  color: #253837;
  border: 1px solid #253837;
}

.info-wapper .Intended-lease input:first-child {
  width: 90% !important;
  margin-right: 20px;
}

.Intended-lease {
  justify-content: space-between;
}

.col100-left {
  width: 100%;
}

.left-upload-wapper.left-upload-wapper2 {
  width: 50%;
  padding-left: 15px;
}

.flex-wapper.flex-wapper2 {
  display: flex;
  justify-content: space-between;
}

.Score-right.home-section {
  background: #ffffff;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 30px;
}

.pdf-share a {
  padding: 5px;
}

.min-max {
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin-top: 20px;
}

.min-max h5 {
  color: #121212;
  font-size: 12px;
  font-family: "Google Sans";
}

.min-max h6 {
  color: #121212;
  font-size: 10px;
  font-family: "Google Sans";
  text-align: left;
}

.max {
  text-align: right !important;
}

.Deposit-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Deposit-flex h5 {
  font-family: "Google Sans";
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #cacaca;
}

.Deposit-flex input {
  width: 128px;
  height: 50px;
}

.Amount-50 {
  width: 50%;
}

.custom-input:focus {
  /* --------------------new responsive css (heena)----------------- */
  /* border: 1px solid #a3eb64 !important; */
  border: 0.073vw solid #a3eb64 !important;
}

.icon-part {
  width: 74px;
  height: 74px;
  text-align: center;
  background: #fcede9;
  border-radius: 50%;
  line-height: 70px;
}

.monthly-left h4 {
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #212121;
  font-family: "Google Sans";
}

.monthly-left h3 {
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #253837;
  font-family: "Google Sans";
}

.monthly-left h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  font-family: "Google Sans";
  color: #cacaca;
}

.rent-money {
  display: flex;
  margin-bottom: 30px;
}

.monthly-left {
  padding-left: 15px;
}

.info-wapper.width-content {
  width: 500px;
  margin-bottom: 30px;
}

.bg-orange {
  background-color: #a2c55f21 !important;
  border-left: 4px solid #253837;
  border-right: 4px solid #253837;
}

.orange-wappper {
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
  padding: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.or-wapper h5 {
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #cacaca;
  font-family: "Google Sans";
  margin-bottom: 10px;
}

.or-wapper h5 span {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
  font-family: "Google Sans";
}

.end-lease a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #253837;
  font-family: "Google Sans";
}

.location-w {
  text-align: center;
  margin-bottom: 20px;
}

.location-w p {
  font-size: 16px;
}

.wappper-location {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.Summary-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
}

.Summary-content h6 {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
  font-family: "Google Sans";
}

.Summary-content h6 {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
  font-family: "Google Sans";
}

.main-Summary h3 {
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  font-family: "Google Sans";
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 15px;
}

.history4 {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #cacaca;
  font-family: "Google Sans";
}

.bodrr-section {
  border-bottom: 0.5px solid #dddddd85;
}

.text-red {
  color: #c10e0e !important;
}

.text-green {
  color: #5cba5f !important;
}

.Statement-row h4 {
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #263238;
  font-family: "Google Sans";
}

.Statement-row h2 {
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #253837;
  font-family: "Google Sans";
}

.pl-rental {
  padding-left: 15px;
}

.btn-fill2.award-btn {
  width: 230px;
}

.Notifications-wapper2 {
  align-items: flex-end;
}

.bodrr-section {
  border-bottom: 0.5px solid #dddddd;
}

.text-red {
  color: #c10e0e !important;
}

.text-green {
  color: #5cba5f !important;
}

.Feedback-back h5 {
  font-family: "Google Sans";
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
  margin-bottom: 20px;
}

.Feedback-back {
  width: 100%;
}

.Feedback-back textarea {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 12px;
  width: 100%;
  height: 77px;
  padding: 10px;
  color: CACACA;
  font-size: 14px;
}

.Feedback-back ul {
  padding-left: 0;
  display: inline-flex;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 17px;
}

.Feedback-back i {
  font-size: 37px;
  color: #f59f18;
}

.Feedback-header {
  max-width: 362px;
  margin: 0 auto;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 20px;
}

.feedback-star-div {
  margin-bottom: 20px;
}

.head-set h3 {
  font-family: "Google Sans";
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  margin-bottom: 24px;
}

.info-wapper select:disabled {
  background: #fafafa;
  /* --------------------new responsive css (heena)----------------- */
  /* border: 1px solid #f0f0f0; */
  border: 0.066vw solid #f0f0f0;
  /* border-radius: 12px; */
  border-radius: 0.8vw;
  color: #aaaaaa;
}

.custom-input:disabled {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  color: black;
}

/* --------------------new responsive css (heena)----------------- */
/* .info-wapper select:disabled {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  color: #aaaaaa;
} */

button.btn-calender {
  width: 56px;
  border: 2px solid #253837;
  background: transparent;
  height: 61px;
  border-radius: 12px;
}

.services-name {
  position: absolute !important;
  bottom: 19%;
  border: 0;
  font-size: 28px;
  letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 0.2;
  padding: 0 !important;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.input-height {
  height: 62px;
  position: relative;
  border-radius: 14px;
  color: #ffffff;
  border: 1px solid #253837 !important;
}

img.btn-calender {
  position: absolute;
  right: 20px;
  top: 20px;
}

/* .Intended-lease input[type="date"]::-webkit-calendar-picker-indicator{display: none;} */
.btn-relate input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../img/calendar.png") !important;
  background-position: center;
  background-repeat: no-repeat !important;
  position: absolute;
  left: 20px;
  background-size: 14px !important;
  z-index: 20;
  display: block !important;
  padding: 0;
}

input.calender-input {
  width: 62px;
  height: 62px;
  /* color: #ffffff !important; */
  border: 1px solid #253837;
  border-radius: 12px;
  font-family: "Google Sans";
  padding-left: 60px;
}

.btn-relate {
  /* width: 62px; */
  position: relative;
}

.mr-rm {
  margin-right: 24px;
}

.profile-mobile {
  display: none;
}

.Notifications-wapper-2 {
  background: #fff;
  border-bottom: 1px solid #ddd6;
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
  padding: 25px;
  align-items: baseline;
}

.incomplete-btn[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.Notifications-wapper-2 h4 {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
  font-weight: bold;
  font-family: "Google Sans";
}

.Notifications-wapper-2.opacity-wapper {
  opacity: inherit;
  padding: 25px;
}

.Notifications-wapper-2.opacity-wapper h6 {
  font-family: "Google Sans";
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #cacaca;
  word-wrap: break-word;
}

.left-upload-wapper-2 {
  width: 100%;
}

/* Responsive css start from here  */
.lined-colerd {
  width: 100%;
  height: 2px;
  background-color: var(--base-primary);
}

.withou-colured {
  width: 100%;
  height: 4px;
  background-color: var(--base-primary);
}

.rightbar {
  padding-left: 20px !important;
}

.next-icon {
  vertical-align: middle;
}

/* Customize the label (the container) */
.rounded-check {
  display: block;
  position: relative;
  /* cursor: pointer; */
  font-family: "Google Sans";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* --------------------new responsive css (heena)----------------- */
  /* padding-left: 24px;
  font-size: 16px; */
  padding-left: 1.76vw;
  font-size: 1.17vw;
}

a.rounded-check {
  text-decoration-line: underline;
}

/* Hide the browser's default checkbox */
.rounded-check input {
  position: absolute;
  opacity: 0;
  /* cursor: pointer; */
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 9px;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid #253837;
  background-color: white;
}

/* On mouse-over, add a grey background color */
.rounded-check:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.rounded-check input:checked ~ .checkmark {
  background-color: #abcd60;
  display: flex;
  align-items: center;
  justify-content: center;
  /* --------------------new responsive css (heena)----------------- */
  /* border: 1px solid #abcd60; */
  border: 0.073vw solid #abcd60;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.rounded-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rounded-check .checkmark:after {
  border: solid white;
  transform: rotate(45deg);
  /* --------------------new responsive css (heena)----------------- */
  /* width: 4px;
  height: 8px;
  border-width: 0 2px 2px 0; */
  width: 0.292vw;
  height: 0.585vw;
  border-width: 0 0.146vw 0.146vw 0;
}

.left-upload-wapper {
  width: 50%;
}

.file-upload {
  background: rgba(231, 87, 53, 0.04);
  margin-bottom: 25px;
  max-width: 362px;
  height: 56px;
  border-radius: 12px;
}

.file-upload-btn:hover {
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  border: 2px dashed #e75735;
  position: relative;
  border-radius: 12px;
  height: 56px;
  margin-top: 40px;
}

/* 

.logout {
  position: absolute;
  right: 0;
  top: 0;
} */

ul.dropdown-menu.show {
  border: 0;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 700;
  text-transform: capitalize;

  font-family: "Google Sans";
  color: #e75735;
  padding: 15px 10px;
  font-size: 16px;
}

.upload-icon {
  vertical-align: middle;
  /* padding-right: 5px; */
  margin-right: 14px;
  font-weight: 500;
}

.admin-btn .dropdown-menu {
  padding: 0;
  margin-top: 0;
}

.hover-dropdown {
  padding: 1.042vw 0;
}

.admin-btn button {
  background: #377def;
  color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.dropdown-toggle::after {
  content: inherit;
}

.score-name {
  font-family: "Google Sans";
  margin-bottom: 0;
  color: #121212;
  text-align: center;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
}

.metrics-box {
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
}

.metrics-boxes {
  display: flex;
  align-items: center;
  row-gap: 12px;
  flex-direction: column;
}

div#main-app-loader {
  position: fixed;
  z-index: 9999;
  background: #fff;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.shared-div {
  width: 50%;
  text-align: center;
}

.bank-page .orange-line {
  position: absolute;
  top: 0;
  right: 0;
}

.bank-page .white-line {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bank-page.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: 100vh;
}

/* #253837 */
/* #A3EB64 light */
.bank-page .greenbox-terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #fff;
  background: #253837;
  flex: 0 0 44%;
  padding: 64px 80px;
  position: relative;
  height: 100vh;
}

.bank-page .bank-body {
  flex: 0 0 56%;
  padding: 64px 80px 64px 45px;
  height: 100vh;
  overflow-y: auto;
}

.bank-body h3 {
  margin: 34px 0 12px 0;
  font-size: 24px;
  color: #253837;
  font-weight: 700;
}

.bank-body p {
  font-size: 16px;
  color: #253837;
  font-weight: 400;
}

.bank-body p:last-child {
  margin: 20px 0 0px 0;
}

.bank-page .what-we-heading {
  color: #a3eb64;
  font-size: 4vw;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 25px;
}

.bank-page .go-back-home span {
  color: #fff !important;
  font-size: 1vw;
}

.terms-heading img {
  width: 10vw;
}

.bank-page .skore-white-logo {
  margin-left: auto;
  width: 17vw;
}

.bank-page .go-back-home {
  position: relative;
  margin-left: unset !important;
}

.go-back-home svg {
  height: 4vw;
  width: 4vw;
}

/* new-added-css */
.delete-link-btn {
  background-color: transparent;
  border-radius: 0.833vw;
  border: 1px solid #a3eb64;
  padding: 0.556vw;
  margin-right: 1.389vw;
  width: 3.472vw;
  height: 3.681vw;
}

.delete-link-btn svg {
  width: 1.806vw;
  height: 1.736vw;
}

.css-1gpjby2 {
  width: 1.667vw;
  height: 1.667vw;
}

.paymentdeailbarryt {
  display: flex;
  align-items: center;
}

.show-details-card {
  background-color: transparent !important;
  border: 0 !important;
}

.show-details-card .card-header {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-weight: 500;
  line-height: 17.81px;
  padding-top: 0.833vw;
  padding-left: 0;
}

.paymentdeailbarleft h3 {
  color: #253837;
  font-family: "Google Sans";
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.paymentdeailbarleft p {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paymentdeailbarryt p {
  font-size: 1.25vw;
  line-height: 2.083vw;
  margin-bottom: 0.694vw;
}

.paymentdeailbarryt a {
  font-size: 1vw;
}

.card-body h5 {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-body p {
  color: #121212;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.get-setup {
  width: 100%;
  border-radius: 0.556vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 2.778vw 0;
  margin-bottom: 0px;
  min-height: 80vh;
}

.getsetup-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 29px;
}

.getsetup-body h1 {
  padding-bottom: 80px;
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.step-one {
  text-align: center;
}

.step-one img {
  width: 6.597vw;
  height: 6.528vw;
}

.step-three img {
  width: 6.597vw;
  height: 6.528vw;
  margin-top: 1.25vw;
}

.step-num {
  background-color: #f3f3f3;
  max-width: 2.222vw;
  border-radius: 50%;
  margin: 0 auto;
  height: 2.222vw;
  margin-bottom: 1.25vw;
  color: #7f7f7f;
  font-size: 1.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.getsetup-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.getsetup-footer img {
  width: 100%;
}

.add-detail {
  padding-top: 1.25vw;
  font-size: 1.25vw;
}

.five-steps {
  align-items: center;
  margin-bottom: 80px;
  width: 100%;
}

.btn-up {
  position: relative;
  z-index: 555;
  top: -75px;
}

.middle-step {
  position: relative;
}

.middle-step::before {
  content: "";
  position: absolute;
  top: 20%;
  left: -84px;
  background-image: url("../../../public/images/waveone-img.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 170px;
  height: 20px;
}

.middle-step::after {
  content: "";
  position: absolute;
  bottom: 20%;
  right: -84px;
  background-image: url("../../../public/images/wavetwo-img.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 170px;
  height: 29px;
}

.step-three {
  text-align: center;
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

/* .step-three img{
width: 9.792vw;
height:9.792vw;
} */
.rental-datails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.556vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 2.778vw 1.389vw;
  margin-bottom: 6.25vw;
}

.rental-datails .details-form {
  max-width: 30vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rental-datails h2 {
  color: #223635;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.rental-datails h6 {
  color: var(--Grey, #7f7f7f);
  text-align: center;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}

.details-form label {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin-bottom: 4px;
}

.details-form label span {
  color: var(--Orange, #ef7833);
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.details-form input {
  border-radius: 0.833vw;
  border: 1px solid var(--Grey, #7f7f7f);
  background: #fff;
  padding: 1.111vw;
  margin-bottom: 1.111vw;
  width: 100%;
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw;
  /* 171.429% */
}

.details-form .radio-check input[type="radio"] {
  width: 0.8vw;
  height: 0.8vw;
}

.form-check.radio-check {
  padding: 0;
}

.radio-check label {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radio-check input {
  margin: 0;
}

.check-radio {
  display: flex;
  gap: 183px;
  margin-top: 18px;
}

.details-form input::placeholder {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.alert-text {
  font-size: 1.111vw;
  margin: 1.389vw 0;
}

.review-form {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 177px;
}

.review-form-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 220px 170px;
}

.review-form h2 {
  color: #223637;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.review-form {
  padding-top: 40px;
}

.body-inner-sec {
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 32px;
}

.body-inner-sec h6 {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.body-inner-sec p {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.body-inner-sec p:last-child {
  margin-bottom: 1.667vw;
}

.payment-detail-content p {
  font-size: 1.25vw;
}

.payment-detail-content {
  width: 100%;
  border-radius: 8px;
  background: rgba(7, 86, 158, 0.1);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.03);
  padding: 1.111vw 1.667vw;
  margin: 1.111vw 0 3.75vw 0;
}

.payment-detail-content svg {
  width: 1.597vw;
  height: 1.597vw;
}

.payment-detail-content p span {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 2.222vw;
}

.tbtn-st {
  padding: 1.111vw 2.778vw;
  border-radius: 0.833vw;
  border: 2px solid #a3eb64;
  background: transparent;
  width: 100%;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.tenant-review {
  padding: 0 50px;
}

.tenant-review
  .primaryaccordion
  .primaryaccordion-header
  .accordion-button
  .maintitle {
  color: #223637;
  text-align: right;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  /* --------------------new responsive css (heena)----------------- */
  /* font-size: 26px; */
  font-size: 1.9vw;
}

.tenant-review-content {
  border-radius: 8px;
  border: 1px solid #a3eb64;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 30px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
}

.close-btn {
  position: absolute;
  right: 17px;
  top: 13px;
}

.review-content h4 {
  color: #223637;
  font-family: "Google Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.review-content p {
  color: #223637;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.detail-page-heading {
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.back-header {
  display: flex;
  align-items: center;
  gap: 28.958vw;
}

.detail-body {
  margin-top: 20px;
  margin-bottom: 200px;
}

.payment-text {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.694vw;
}

.upcoming-box {
  border-radius: 0.556vw;
  border: 0.069vw solid #dddddd;
  background: #ffffff;
  padding: 1.111vw 1.667vw 0.694vw;
  margin-bottom: 1.319vw;
}

.upcoming-text {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.556vw;
}

.upcoming-side {
  width: 65%;
}

.upcoming-table {
  width: 100%;
}

.upcoming-table .first-row {
  border-bottom: 1px solid #a3eb64;
  margin-bottom: 4px;
}

.upcoming-table .first-row th {
  padding-bottom: 0.278vw;
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-table .second-row td {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 4px;
}

.upcoming-table .second-row td:nth-child(2) p:first-child {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-table .second-row td:nth-child(2) p:nth-child(2) {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-table tbody tr td {
  padding: 0.556vw;
}

.upcoming-table tbody tr:nth-child(even) {
  background-color: #f4ffea;
}

.upcoming-table thead tr th {
  padding: 8px;
}

.current-side {
  width: 35%;
}

.current-upper {
  border-radius: 0.556vw;
  border: 0.069vw solid #ddd;
  background: #fff;
  padding: 1.111vw 1.667vw 2.222vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.111vw;
  margin-bottom: 0.972vw;
}

.current-upper h5 {
  color: #7f7f7f;
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.current-upper h3 {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.waiting-tenant {
  border-radius: 0.556vw;
  background: rgba(70, 177, 255, 0.1);
  padding: 0.833vw;
  max-width: 12.5vw;
  margin: 0 auto;
}

.waiting-tenant {
  display: flex;
  align-items: center;
  gap: 14px;
}

.waiting-tenant svg {
  width: 1vw;
  height: 1vw;
  margin-right: 0.556vw;
}

.waiting-tenant h4 span {
  color: var(--Dark-green, #253837);
  text-align: right;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 0px;
}

.waiting-tenant h4 {
  margin: 0;
}

.current-down {
  border-radius: 0.556vw;
  border: 0.069vw solid #ddd;
  background: #fff;
  padding: 1.667vw;
  display: flex;
  flex-direction: column;
  gap: 1.111vw;
}

.current-down h4 {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.current-down h6 {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.payment-main-container {
  gap: 1.667vw;
}

.current-down p {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.278vw;
}

.lease-information.mobile,
.payment-information.mobile {
  border-radius: 1.25vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
}

.payment-information.mobile .payment-box {
  border-radius: 1rem;
}

.payment-information.mobile .payment-box .price {
  color: #ef7833;
  font-weight: 600;
}

.payment-information.mobile .payment-box .status {
  color: #5ed603;
}

.custom-gap {
  margin-bottom: 16px;
}

.edit-det-bt {
  border-radius: 0.833vw;
  border: 2px solid #a3eb64;
  padding: 1.389vw 2.778vw;
  background-color: transparent;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.upcoming-table tbody {
  font-size: 1.111vw;
}

.payment-detail-page {
  padding: 0 30px;
  margin-bottom: 63px;
}

/* tenant-flow */
.paymentdetails-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.paymentdetails-body h1 {
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.paymentdetails-body h5 {
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.778vw;
}

.middlestep {
  position: relative;
}

.middlestep::before {
  content: "";
  position: absolute;
  left: -12.5vw;
  top: 50%;
  background-image: url("../../../public/images/bigwave.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 26.25vw;
  height: 1.667vw;
}

.custom-pb {
  padding-bottom: 45px;
}

.reviewandconform {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 40px 240px;
  margin-bottom: 70px;
}

.uiux.reviewandconform {
  padding: 1.667vw 24.306vw !important;
  margin-bottom: 2.361vw !important;
}

.uiux.reviewandconform .payment-body p:last-child {
  margin-bottom: 0.694vw !important;
}

.uiux.reviewandconform .payment-detail-content {
  margin: 0.694vw 0;
}

.uiux.reviewandconform .payment-body {
  margin-top: 0.556vw !important;
  padding: 1.25vw 1.25vw 0px !important;
}

.reviewandconform-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-body {
  padding: 2.222vw;
  border-radius: 0.556vw;
  border: 0.069vw solid #dddddd;
  background: #ffffff;
  margin-top: 2.778vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviewandconform-body h3 {
  color: #223637;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.556vw;
}

.payment-body h6 {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.278vw;
}

.payment-body p {
  color: #223637;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.monthly-paymenttext + p {
  font-size: 1.111vw;
  line-height: 2.083vw;
}

.payment-body p:last-child {
  margin-bottom: 40px;
}

.payment-body h5 {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.change-payment {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
  border: 0;
  background: transparent;
  margin-top: 40px;
}

.payment-body input {
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--Grey, #7f7f7f);
  background: #ffffff;
  padding: 16px;
  height: 46px;
  margin-top: 4px;
}

.payment-body input:focus-visible {
  outline: 0;
}

.edit-payment-method button {
  border-radius: 0.833vw;
  border: 0.139vw solid #a3eb64;
  padding: 1.389vw 2.778vw;
  background-color: transparent;
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.tenant-review-content svg {
  width: 100%;
}

.btn.btngreen.upbtn {
  position: relative;
  z-index: 555;
}

.custompb {
  padding-bottom: 29px;
}

.assign-btn {
  padding: 0.972vw 2.778vw;
}

.date-payment {
  color: var(--Dark-green, #253837) !important;
  font-family: "Google Sans" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.date-payment span {
  color: var(--Orange, #ef7833);
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.choose-date {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

/* new-css-added 29-05-24 */
.document-card.card {
  width: max-content;
}

.upload-document p {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  margin-top: 24px;
}

.pdf-or-else {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0 !important;
}

.check-deposit {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  gap: 3.125vw;
}

.check-deposit svg {
  height: 2.222vw;
  width: 2.222vw;
}

.rental-details .rentail-headsec {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 1.389vw;
  display: flex;
  gap: 6.25vw;
}

.rental-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rentail-headsec .heading-check {
  display: flex;
  height: min-content;
  align-items: center;
  gap: 3.125vw;
  margin-top: 14px;
}

.rentail-headsec .heading-check svg {
  width: 2.222vw;
  height: 2.222vw;
}

.rental_details {
  display: flex;
  align-items: center;
  gap: 1.25vw;
  color: #121212;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex: 0 0 50%;
}

.check-rent {
  display: flex;
  gap: 3.125vw;
  flex: 0 0 50%;
}

.skorprotect-sec svg {
  height: 2.222vw;
  width: 2.222vw;
}

.check-rent svg {
  height: 2.222vw;
  width: 2.222vw;
}

.monthly-paymenttext {
  color: #9a9a9a;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.monthly-deposit {
  display: flex;
  gap: 50px;
}

.rentaldetails-body {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 30px 60px;
}

.main-rentaldetails.rentaldetails-body {
  padding: 1.389vw !important;
}

.rental-step-point {
  position: relative;
  justify-content: center;
}

.rental-step-point > p {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.step-point {
  display: flex;
  align-items: center;
  /* gap: 432px; */
  margin-bottom: 1.042vw;
}

.step-point p {
  background: #a3eb64;
  padding: 0.417vw 0.833vw;
  border-radius: 50%;
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* letter-spacing: 0.6px; */
  height: 2.222vw;
  width: 2.222vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-point h3 {
  color: #121212;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.skor-depositsec {
  border-radius: 12px;
  background: #f7f7f7;
  padding: 1.528vw 1.25vw 1.25vw;
  text-align: center;
  flex: 0 0 50%;
}

.skor-depositsec h6 {
  color: #223635;
  /* text-align: right; */
  font-family: "Google Sans";
  font-size: 1.319vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.skor-depositsec p {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.667vw;
}

.full-depositsec {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.694vw;
}

.full-depositsec .fulldeposit-text {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120.5%;
  /* 16.87px */
  text-decoration-line: strikethrough;
  margin-bottom: 0.833vw;
}

.keyflex-sec p {
  white-space: nowrap;
  font-size: 1.25vw;
  line-height: normal;
}

.keyflex-sec svg {
  height: 3.403vw;
  width: 3.403vw;
}

.full-depositsec .fulldeposit-amount {
  color: #253837;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: strikethrough;
}

.skorfull-deposit {
  display: flex;
  gap: 1.25vw;
}

.fulldeposit-sec {
  flex: 0 0 49%;
  border-radius: 0.833vw;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1.528vw 1.25vw 1.25vw;
}

.divider {
  background-color: #ddd;
  width: 100%;
  height: 1.5px;
  margin-bottom: 31px;
}

.full-depositsec .total-text {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120.5%;
  /* 19.28px */
  text-transform: uppercase;
}

.full-depositsec .total-amount {
  color: #ef7833;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120.5%;
  /* 24.1px */
}

.fulldeposit-sec .fulldeposit-header h6 {
  color: #223635;
  font-family: "Google Sans";
  font-size: 1.319vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.fulldeposit-sec .fulldeposit-header p {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fulldeposit-middle .deposit-text {
  color: var(--Grey, #7f7f7f);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120.5%;
  margin-bottom: 1.25vw;
  /* 16.87px */
}

.border-div {
  border-bottom: 1px solid #ddd;
}

.fulldeposit-middle .deposit-amount {
  color: var(--Dark-green, #253837);
  /* text-align: right; */
  font-family: "Google Sans";
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120.5%;
}

.foursteps-sec {
  display: flex;
  flex-direction: column;
  gap: 0.694vw;
}

.three-rentpayment {
  display: flex;
  align-items: center;
  gap: 46px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 22px 32px;
}

.third-count {
  background-color: #7f7f7f;
  color: #fff;
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  width: 2.222vw;
  height: 2.222vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.depositoptions-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.556vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 1.389vw;
}

.deposit-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 70%;
}

.skorflex-rent .choosedate-text {
  color: var(--Orange, #ed691f);
  /* text-align: right; */
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 2.361vw;
  margin-top: 0.417vw;
}

.skorflex-rent p {
  color: var(--Black, #000);
  text-align: center;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: strikethrough;
  text-transform: capitalize;
  margin-bottom: 0.556vw;
}

.skorflex-rent h6 {
  color: #223635;
  /* text-align: right; */
  font-family: "Google Sans";
  font-size: 1.319vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.5vw;
}

.paymentdate-sec {
  text-align: left;
}

.paymentdate-sec label {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.556vw;
}

.paymentdate-sec label span {
  opacity: 0.4;
  color: var(--Orange, #ef7833);
  font-family: "Google Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paymentdate-sec .choose-date {
  width: 100%;
  height: 3.889vw;
  border-radius: 12px;
  border: 0.069vw solid var(--Grey, #7f7f7f);
  background: #fff;
  padding: 1.111vw;
}

.file-upload-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.file-upload-wrapper span {
  display: inline-block;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 40%;
  white-space: nowrap;
}

.paymentdate-sec p {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 0.972vw 0 1.667vw;
}

.grey-pay {
  border-radius: 0.833vw;
  opacity: 0.1;
  background: var(--Dark-green, rgb(37 56 55 / 4%));
  width: 100%;
  padding: 0.972vw;
  opacity: 0.1;
  pointer-events: none;
  line-height: 1.667vw;
}

.grey-pay span {
  color: var(--Dark-green, #253837);
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.skorflex-rent {
  opacity: 0.4;
}

.regular-rent h6 {
  margin-bottom: 1.667vw;
}

.paymentbetween p {
  margin-bottom: 0.764vw;
}

.paymentbetween .regularrent-amount {
  color: var(--Dark-green, #253837);
  text-align: center;
  font-family: "Google Sans";
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0.4vw;
}

.keyflex-sec {
  display: flex;
  align-items: center;
  gap: 18px;
  flex: 0 0 50%;
}

.rent-left {
  display: flex;
  align-items: center;
  flex: 0 0 70%;
  justify-content: space-between;
}

.skorprotect-sec {
  display: flex;
  align-items: center;
  gap: 3.125vw;
}

.three-rentpayment-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.556vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 1.389vw;
}

.comingsoon-content {
  color: var(--Dark-green, #253837);
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.complete-pay {
  width: max-content;
  margin: 24px auto;
}

.reivew-page .payment-body {
  margin-top: 20px;
  /* max-width: 481px; */
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
}

.rent-headingtext {
  color: #223635;
  /* text-align: right; */
  font-family: "Google Sans";
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.confirm-continue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 33px 44px;
  margin-bottom: 34px;
}

.confirm-left {
  display: flex;
  align-items: center;
  gap: 34px;
}

.confirm-left h3 {
  color: #7f7f7f;
  text-align: right;
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.confirm-left p {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.confirm-left p span {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.date-card.incomplete-date .date-card-header {
  background: #f1f1f1;
  color: #7f7f7f;
}

.date-card.incomplete-date .date-card-footer p {
  color: #7f7f7f !important;
}

.review-pay {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 44px;
}

.review-pay h3 {
  margin: 0;
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.choose-to-pay {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 44px;
  text-align: center;
}

.choose-to-pay h1 {
  color: #223635;
  text-align: center;
  font-family: "Google Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.choose-to-pay h3 {
  color: var(--Orange, #ef7833);
  text-align: center;
  font-family: "Google Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 34px;
}

.paynow-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 0.6px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
  padding: 26px 34px;
}

.paynow-content {
  display: flex;
  align-items: center;
  gap: 24px;
}

.paynow-content p {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.choose-to-pay .paynow-btn {
  margin-bottom: 18px;
  cursor: pointer;
}

.upcoming-table .second-row .paidamount-first {
  color: var(--Dark-green, #253837);
  font-family: "Google Sans";
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* css-added-21-06-24 */
.docoument-content {
  border-radius: 8px;
  background: #f6f7f7;
  padding: 24px;
  margin: 0 10px;
}

.docoument-content p {
  color: #575757;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

.downloadpdf {
  display: flex;
  align-items: center;
  gap: 10px;
}

.skor-qr-pdf {
  display: flex;
  justify-content: space-between;
  padding: 8px 40px;
  align-items: center;
  background: #fff;
}

.pdf-qrcode {
  display: flex;
  align-items: center;
  gap: 18px;
}

.pdf-qrcode .qr-img svg {
  width: 60px;
  height: auto;
}

/* ///////////////////////// new design tenant ///////////////////////////// */

.tenant-info-wrapper {
  padding: 0.694vw 1.389vw;
  border-radius: 1.25vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.694vw;
}

.tenant-info-wrapper .tenant-info {
  gap: 2.361vw;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-name {
  color: #253837;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-bottom: 0.556vw; */
  margin-bottom: 0;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-status {
  border-radius: 0.833vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.833vw 1.667vw;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 0.833vw;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-status svg {
  height: 1.389vw;
  width: 1.389vw;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-status.pending {
  background: #fdf0e9;
  color: #ef7833;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-status.complete {
  background: #a3eb64;
  color: #253837;
}

.reverify-btn img {
  height: 3.194vw;
  width: 3.194vw;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-email,
.tenant-info-wrapper .tenant-info .tenant-details .tenant-number,
.tenant-info-wrapper .tenant-info .tenant-contact .tenant-email,
.tenant-info-wrapper .tenant-info .tenant-contact .tenant-number {
  display: flex;
  gap: 0.833vw;
  align-items: center;
  color: #253837;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-email svg,
.tenant-info-wrapper .tenant-info .tenant-details .tenant-number svg,
.tenant-info-wrapper .tenant-info .tenant-contact .tenant-email svg,
.tenant-info-wrapper .tenant-info .tenant-contact .tenant-number svg {
  height: 1.111vw;
  width: 1.389vw;
}

.tenant-info-wrapper .reward-points-wrapper {
  border-radius: 0.556vw;
  background: #f4f4f4;
}

.tenant-info-wrapper .reward-points-wrapper .reward-head {
  color: #253837;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0.694vw;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 0.833vw;
}

.tenant-info-wrapper .reward-points-wrapper .reward-head svg {
  height: 1.528vw;
  width: 1.667vw;
}

.tenant-info-wrapper .reward-points-wrapper .reward-points {
  color: #fff;
  text-align: center;
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0.347vw;
  background: #a392ef;
  border-radius: 0.556vw;
  display: flex;
  justify-content: center;
}

.tenant-info-mobile {
  border-radius: 18px;
  background: #a392ef;
}

.tenant-info-wrapper.mobile {
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.694vw;
  padding: 1rem;
}

.tenant-badge-name.mobile {
  height: 64px;
  width: 64px;
}

.tenant-status.mobile {
  border-radius: 2rem;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 0.833vw;
}

.tenant-status.mobile.pending {
  background: #fdf0e9;
  color: #ef7833;
}

.tenant-status.mobile.complete {
  background: #a3eb64;
  color: #253837;
}

.reward-points-wrapper.mobile .reward-head.mobile {
  color: white;
}

.reward-points-wrapper.mobile .reward-points.mobile {
  color: white;
}

.pending-steps-wrapper {
  padding: 0.694vw 1.389vw;
  border-radius: 1.25vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.042vw;
}

.go-back-btn svg {
  height: 3.75vw;
  width: 3.75vw;
  margin-right: 0.556vw;
}

.go-back-btn .go-back-text {
  font-size: 1.25vw;
}

.tile-design.pad-mar-set {
  margin-top: 0.694vw;
  padding-top: 0.694vw;
}

.tile-design {
  padding-bottom: 1.389vw;
  margin-bottom: 1.389vw;
  border-bottom: 0.069vw solid #d7dce4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tile-design .tile-content-wrapper {
  flex: 0 0 65%;
  display: flex;
  align-items: center;
  gap: 1.25vw;
}

.tile-design .tile-content-wrapper img {
  height: 3.75vw;
  width: 3.75vw;
}

.tile-design .tile-content-wrapper .tile-heading {
  color: #253837;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pending-steps-wrapper .tiles-main-heading {
  color: #253837;
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pending-steps-wrapper .tile-design .tile-desc {
  color: #7f7f7f;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pending-steps-wrapper .tile-design .tile-desc-completed {
  color: #bbb;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pending-steps-wrapper .tile-design:has(.tile-desc-completed) .tile-heading {
  color: #bbb;
  font-family: "Google Sans";
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.green-box {
  padding: 18px;
  background-color: #a3eb641a;
  border-bottom: 1px solid #dddddd;
}

.resend-text .green-box .step-inner-desc {
  margin-bottom: 0;
}

.resend-text .lease-info {
  padding: 18px;
}

.step-form-header .back-btn {
  color: #253837;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.step-form-header .back-btn img {
  margin-right: 10px;
}

.step-form-name {
  color: #253837;
  font-size: 1.944vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.972vw;
  margin-bottom: 0.556vw;
}

.step-form-name > svg {
  width: 3.75vw;
  height: 3.75vw;
}

.step-form-header .step-count {
  color: #7f7f7f;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.step-form-header .step-count span {
  color: #253837;
  text-align: right;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.step-form-main-wrapper {
  padding: 2.431vw 3.056vw;
  border-radius: 1.25vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
}

.two-items-row {
  display: flex;
  align-items: stretch;
  gap: 18px;
  margin-bottom: 1rem;
}

.two-items-row .forminput .boxes {
  text-align: center;
}

.two-items-row .forminput {
  flex: 1;
}

.upload-new-flow .tooltip {
  right: 0;
  left: unset;
}

.upload-new-flow .step-form-main-wrapper .step-name {
  color: #223635;
  text-align: center;
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 2.361vw;
  gap: 1.25vw;
}

.step-form-main-wrapper .steps-wrapper {
  max-width: 38.194vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.step-form-main-wrapper.modal-wrapper {
  background: unset;
  box-shadow: unset;
}

.step-form-main-wrapper .steps-wrapper .label-text,
.newmodalui .modal-body .auth-form .forminput label.forminput.label-text {
  color: #121212;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.764vw;
}

.steps-wrapper button {
  /* --------------------new responsive css (heena)----------------- */
  margin: 12px 0;
  /* margin: 0; */
}

.button-gap {
  margin: 0.878vw 0 !important;
}

.step-form-main-wrapper .steps-wrapper .orangetext {
  margin-top: 20px;
}

.step-form-main-wrapper .steps-wrapper .step-btns-group button {
  flex: 1;
}

.pending-steps-wrapper .tile-design .tile-btns-wrapper .tile-points {
  color: #a392ef;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.833vw;
  background: #f6f6f6;
  padding: 1.111vw 1.944vw;
  display: flex;
  align-items: center;
  gap: 0.833vw;
}

.pending-steps-wrapper .tile-design .tile-btns-wrapper .tile-points img {
  height: 1.528vw;
  width: 1.667vw;
}

.pending-steps-wrapper .tile-design .tile-btns-wrapper .incomplete-btn,
.pending-steps-wrapper .tile-design .tile-btns-wrapper .completed-btn {
  background-color: #a3eb64;
  color: #253837;
  text-align: center;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 1.319vw;
  min-width: 13.889vw;
  border: 0;
  border-radius: 0.833vw;
}

.pending-steps-wrapper .tile-design .tile-btns-wrapper .completed-btn {
  /* opacity: 0.4; */
  pointer-events: none;
  border-radius: 0.833vw;
  background: #f6f6f6;
  color: #253837;
  text-align: center;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.pending-steps-wrapper.already-renting .tiles-main-heading {
  padding-bottom: 34px;
  border-bottom: 1px solid #d7dce4;
  margin-bottom: 34px;
}

.steps-progress-bar .circle .mask,
.steps-progress-bar .circle .fill {
  position: absolute;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.steps-progress-bar .circle .mask {
  clip: rect(0px, 5.208vw, 5.208vw, 2.569vw);
}

.steps-progress-bar {
  width: 5.208vw;
  height: 5.208vw;
  background-color: #d9d9d9;
  border-radius: 50%;
}

.add-more-btn.step-btn {
  color: #ef7833;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  background: transparent;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0 !important;
  display: inline-flex;
  align-items: center;
  gap: 2px;
}

.assignToLLBtn {
  padding:0.556vw 1.042vw;
}



.info-modal span {
  color: #6f6f6f;
}

.info-modal .modal-dialog {
  max-width:41.667vw;
}

.info-modal .modal-dialog .modal-header, .info-modal .modal-dialog .modal-body  {
padding: 1.111vw;
}

.info-modal .modal-dialog ul{
  padding-left:2.222vw;
}

.info-modal .modal-content {
  padding:0;
}

.info-modal .modal-header {
  border-bottom: 0.069vw solid #dee2e6 !important;
}



.info-modal .btn-close {
  padding: 0.556vw;
  width:1.111vw;
  height:1.111vw;
  background: transparent var(--bs-btn-close-bg) center/1.111vw auto no-repeat;
}

.info-modal li{
  margin-top:0.347vw;
  font-size: 1.111vw;
  list-style: disc;
  line-height: 1.5;
}

.info-modal a {
  color:#ef7833;
}

.info-modal p {
  font-size: 1.111vw;
  line-height:1.5;
}

.info-modal .modal-title {
  font-size: 1.667vw;
}



.Notifications-wapper {
  align-items: stretch !important;
}

.steps-progress-bar .inside-circle {
  border-radius: 50%;
  background: #fff;
  color: #253837;
  position: absolute;
  z-index: 2;
  font-weight: 700;
  font-size: 1.111vw;
  top: 0.694vw;
  bottom: 0.694vw;
  right: 0.694vw;
  left: 0.694vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-form-main-wrapper:has(.step-complete-reward) .step-form-header > div {
  /* justify-content: center !important; */
  margin-right: 40%;
}

.step-form-main-wrapper:has(.step-complete-reward)
  .step-form-header
  > div
  .back-btn-mobile {
  margin-bottom: 5%;
}

.step-form-main-wrapper:has(.step-complete-reward)
  .step-form-header
  .step-name {
  margin-bottom: 60px;
}

.step-complete-reward .greeting-text {
  color: #121212;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
}

.step-complete-reward .points-info {
  color: #586363;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-top: 8px;
  margin-bottom: 34px;
}

.step-go-home-btn {
  border-radius: 10px;
  border: 2px solid #253837;
  background: rgba(37, 56, 55, 0.06);
}

.step-go-home-btn:hover {
  border-radius: 10px;
  border: 2px solid #9fa7a7;
  background: var(--Dark-green, #253837);
  color: #a3eb64;
}

.btngrey {
  border-radius: 10px;
  border: 2px solid #253837;
  background: rgba(37, 56, 55, 0.06);
}

.btngrey:hover {
  border-radius: 10px;
  border: 2px solid #9fa7a7;
  background: var(--Dark-green, #253837);
  color: #a3eb64;
}

.btngrey:hover svg path {
  fill: #a3eb64;
}

.reverify-btn {
  border: none;
  border-radius: 50%;
}

.step-complete-reward .step-go-home-btn {
  border-radius: 12px;
  border: 1px solid #223635;
  background: #fff;
  text-align: center;
  padding: 15px;
  color: #223635;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.tenant-contact {
  margin-top: 0.556vw;
}

.step-complete-reward .rewards-points {
  border-radius: 12px;
  background: #f6f4fd;
  padding: 24px;
  color: var(--Purple, #a392ef);
  text-align: center;
  font-family: "Google Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.step-complete-reward .rewards-points p {
  color: #a392ef;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

body:has(#sub-header-links) .margin-div {
  margin-top: 0 !important;
  padding: 0.694vw !important;
  margin: 0 2.083vw;
  border-radius: 0px 1.25vw 1.25vw 1.25vw;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  position: relative;
}

body:has(.uiux.reviewandconform) .back-btn {
  position: absolute;
  left: 1.042vw;
  top: 1.042vw;
  padding: 0.556vw;
}

.back-btn span {
  font-size: 1.25vw;
}

.back-btn {
  width: fit-content;
}

.back-btn img {
  height: 2.778vw;
  width: 2.778vw;
}

#sub-header-links {
  margin: 0 2.083vw;
}

#sub-header-links .sub-head-links-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  border-radius: 1.25vw 1.25vw 0px 0px;
  background: #f0f0f0;
}

#sub-header-links {
  margin-top: 6.667vw !important;
  padding: 0;
}

#sub-header-links .sub-link a {
  padding: 1.042vw 1.389vw;
  color: #7f7f7f;
  text-align: center;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

#sub-header-links .sub-link:not(:last-child) a:after {
  content: "";
  width: 0.139vw;
  position: absolute;
  height: 1.389vw;
  background-color: #bbbbbb;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#sub-header-links .sub-link.active a:after {
  display: none;
}

#sub-header-links .sub-link.active a {
  border-radius: 1.25vw 1.25vw 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  color: #253837;
  text-align: center;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 0.139vw solid #a3eb64;
}

#sub-header-links .link.active {
  background: #fff;
}

.progress-step {
  border-radius: 0.694vw;
  background: #efefef;
  height: 1.111vw;
  flex: 1;
  margin-top: 1.389vw;
  overflow: hidden;
}

.skore-btns-wrapper {
  gap: 1.25vw;
  padding-right: 0.833vw;
}

.skore-btns-wrapper button {
  gap: 0.833vw;
}

.regenrate-score-btn {
  border-radius: 0.833vw;
  border: 0.139vw solid #a3eb64;
  padding: 0.972vw;
  background-color: unset;
  background: unset;
  font-size: 1.042vw;
}

.regenrate-score-btn svg {
  height: 1.25vw;
  width: 1.25vw;
}

.add-more-doc-btn {
  border-radius: 0.556vw;
  background: #f4f4f4;
  border: none;
  padding: 0.972vw;
  font-size: 1.042vw;
}

.add-more-doc-btn svg {
  height: 1.25vw;
  width: 1.042vw;
}

.progress-step .progress-fill {
  height: 100%;
  width: 0%;
  display: block;
  border-radius: 0.694vw;
}

.progress-step .progress-fill.progress-filler-red {
  background: #f3483e;
}

.progress-step .progress-fill.progress-filler-orange {
  background: #f77841;
}

.progress-step .progress-fill.progress-filler-yellow {
  background: linear-gradient(270deg, #99de04 28.33%, #f5dd01 70.82%);
}

.progress-step .progress-fill.progress-filler-light-green {
  background: #99de04;
}

.progress-step .progress-fill.progress-filler-dark-green {
  background: #05b801;
}

.margin-bottom-2 {
  margin-bottom: 0.556vw;
}

.steps-wrapper.cust-width-setting {
  max-width: 83.333vw;
}

.cust-width-setting .cust-inner-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 38.194vw;
  margin: 0 auto;
}

.step-form-main-wrapper .steps-wrapper:has(.cust-width-setting) {
  max-width: unset;
}

.skore-details-heading {
  color: #253837;
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.556vw;
}

.skore-details .skore-image-wrapper {
  flex: 0 0 40%;
  border-right: 1px solid #d7dce4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skore-details .skore-details-content {
  flex: 1 1;
  text-align: center;
  /* padding-left: 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skore-details .skore-details-content .skore-detail-heading {
  color: #253837;
  text-align: center;
  font-size: 1.806vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-top: 1.806vw; */
}

.landlordRentalDetails .pending-steps-wrapper {
  padding: 0 !important;
  box-shadow: none !important;
}

.landlordRentalDetails .pending-steps-wrapper .col-lg-6 {
  width: 100% !important;
}

.landlordRentalDetails .pending-steps-wrapper .row {
  gap: 20px;
}

.skore-details .skore-details-content .skore-details-points {
  color: #a392ef;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.833vw;
  background: #f6f6f6;
  padding: 1.111vw 1.944vw;
  display: inline-flex;
  align-items: center;
  gap: 0.833vw;
  margin-top: 1.25vw;
}

.skore-details .skore-details-content .complete-btn {
  margin-top: 1.667vw;
  max-width: 27.778vw;
  width: 100%;
}

.skore-details .skore-details-content .skor-details-desc {
  color: #7f7f7f;
  text-align: center;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.skore-details .skore-details-content .skore-details-points img {
  height: 1.528vw;
  width: 1.667vw;
}

.tenant-info-wrapper .tenant-info .tenant-details .tenant-status.failed {
  background: #ef7833;
  color: #fff;
}

/* color animation */

/* 3rd progress bar */
.steps-progress-bar .mask .fill {
  clip: rect(0px, 2.569vw, 5.208vw, 0px);
  background-color: Orange;
}

.steps-progress-bar .mask.full,
.steps-progress-bar .circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(135deg);
}

.modal-main-heading {
  color: #253837;
  text-align: center;
  font-size: 1.528vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: 1;
  margin-bottom: 0.556vw !important;
}

.modal-content .modal-body .pdf-btn {
  border-radius: 12px;
  border: 1px solid #223635;
  background: #fff;
  color: #223635;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  padding: 18px 15px;
  margin-bottom: 18px;
}

.modal-sub-heading {
  color: #7f7f7f;
  text-align: center;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 2.361vw;
}

.modal-content .modal-header,
.modal-content .modal-body {
  border-bottom: none;
  padding: 0;
}

/* //////////////////////////////////////////////////////// */

.guage-box {
  position: relative;
  width: 27.778vw;
  height: 20.833vw;
  margin: 0 auto;
}

.guage-main-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.guage-main-lower {
  z-index: 1;
}

.guage-main-upper {
  z-index: 2;
}

.guage-main-inner-item {
  position: absolute;
  left: 8%;
  top: 9%;
  width: 84%;
}

.guage-main-inner-lower {
  z-index: 1;
}

.guage-main-inner-upper {
  z-index: 2;
}

.gague-val-box {
  position: absolute;
  left: 0;
  top: 0.764vw;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  z-index: 2;
}

.gague-val {
  width: 39%;
  height: 52%;
  margin-top: 2.778vw;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
}

.tenant-revisit-pdf-box .upload_doc_tooltip p {
  text-align: left;
}



.cursor-pointer {
  cursor: pointer;
}
.gague-val h1 {
  margin: 0;
  color: #121212;
  text-align: center;
  font-size: 2.639vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gague-val p {
  margin: 0;
  color: #b4cc23;
  text-align: right;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: 0 0 40%;
  text-align: center;
}

/* //////////////////////////////////////////////////////// */
div.modal-dialog {
  max-width: 34.722vw;
}

.modal-uploadpdf .modal-dialog .modal-content {
  padding: 2.361vw;
  border-radius: 0.556vw;
}

.modal-uploadpdf.radio-wrapper .customradiobar .boxes {
  margin-bottom: 18px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #5b5b5b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.view-progress {
  border-radius: 0.833vw !important;
  background: #f6f6f6;
  color: #ef7833;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 1.319vw;
  outline: 0;
  border: 0;
  min-width: 13.889vw;
}

.modal-content {
  padding: 2.361vw;
}

.newmodalui.connectbankmodal .modal-content {
  padding: 0;
}

.congratulation-screen {
  text-align: center;
}

.congratulation-screen .congrats-image {
  max-width: 180px;
  margin: 0 auto 24px;
}

.add-landlord-modal .modal-footer:has(.add-landlord-submit) {
  border: 0;
  padding: 0;
}

.add-landlord-modal .add-landlord-submit {
  width: 100%;
  margin: 0;
}

.add-landlord-modal .modal-dialog {
  max-width: 50vw;
}

/* .landlord-table tbody tr:nth-child(odd) {
  background-color: #eee;
} */

.landlord-table thead th {
  padding-bottom: 14px !important;
}
.landlord-table tbody td {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.add-landlord-modal .phone_input .form-control {
  border-radius: 0.88vw;
}

.add-landlord-modal .react-tel-input .flag-dropdown,
.add-landlord-modal .react-tel-input .selected-flag,
.add-landlord-modal .react-tel-input .flag-dropdown.open,
.add-landlord-modal .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 0.88vw 0 0 0.88vw;
}

.congratulation-screen .congrats-text {
  color: #121212;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.congratulation-screen .congrats-desc {
  color: #586363;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 34px;
}

.step-form-main-wrapper .steps-wrapper button.bg-none {
  background-color: unset !important;
  color: #223635 !important;
}

.congratulation-screen.steps-wrapper .step-form-header .step-form-name {
  justify-content: center;
  margin-bottom: 34px;
}

.CircularProgressbar-path.rent-score {
  stroke: #a392ef !important;
}

.payment-body .payment-header {
  background: #fdf6ef;
  text-align: center;
  padding: 1.111vw 0px;
}

.payment-address .payment-title {
  color: #9a9a9a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 18px;
}

.payment-address .payment-address-des {
  color: #253837;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 24px;
  margin-bottom: 18px;
  text-align: center;
  border-bottom: 1px solid #dddddd;
}

.main-cust-wrapper .cust-head {
  color: #223635;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main-cust-wrapper .cust-val {
  color: #121212;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
  /* margin-bottom: 18px; */
}

.warning-text {
  /* font-size: ; */
}

.payment-complete-btn {
  display: flex;
  gap: 12px;
  padding: 10px;
  color: #253837;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* width:100%; */
  border-radius: 8px;
  background: #f1f9f1;
  outline: none;
  border: none;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 24px; */
  width: calc(100% - 36px);
  margin: 8px auto 24px;
}

.tennat-left-box-top ul {
  padding-left: 15px;
}

.white-disc li {
  list-style: disc;
}

.tennat-left-box-top ul::marker,
.sidebar-inner-heading + ul li::marker,
.white-disc li::marker {
  color: #fff;
}

.tennat-left-box-top ul ul li,
.sidebar-inner-heading + ul {
  list-style: disc;
}

.uiux.reviewandconform .payment-body:has(.payment-header) {
  padding: 0 !important;
  overflow: hidden;
}

.uiux.reviewandconform .payment-body:has(.payment-header) > .row {
  margin: 0;
}

.payment-body .payment-address,
.payment-body .row .row,
.payment-body {
  padding: 0 18px;
}

.payment-body .payment-title {
  color: #9a9a9a;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.payment-body .payment-header .payment-header-price {
  margin-top: 0.2rem;
  color: var(--Orange, #ef7833);
  text-align: center;
  font-family: "Google Sans";
  font-size: 2.083vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payment-address .payment-address-title {
}

/* @keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
} */

.tabs-home.mobile {
  border-radius: 11.995px;
}

.tab-content-home.mobile .border-pd {
  box-shadow: none;
  border-radius: 0.8rem;
}

.tenant-home-accordion.accordion .accordion-item {
  border-radius: 12px;
}

.tenant-home-accordion.accordion .accordion-button {
  background-color: transparent;
}

.tenant-home-accordion.accordion .accordion-button:not(.collapsed) {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.tenant-home-accordion.accordion .accordion-button:focus {
  box-shadow: none;
}

.tenant-home-accordion.accordion .accordion-body {
  padding-top: 0;
}

.btn-gray {
  background-color: #f6f6f6 !important;
  font-size: 14px;
  padding: 6px 24px;
  margin-block-start: 6px;
}

.btn-gray img {
  margin-inline-end: 10px;
  margin-inline-start: 0 !important;
}

.share-filter {
  padding: 0.347vw 0.694vw 0.347vw 0.972vw;
  width: 8.889vw;
  height: 3.333vw !important;
}

.common-gap {
  margin-bottom: 1.2rem !important;
}

.mobile-max-rent-heading h4 {
  font-size: 1.667vw;
}

.special-gap {
  margin-bottom: 1.5rem !important;
}

.MuiSlider-markLabel {
  font-size: 0.972vw !important;
}

.user-auth {
  /* margin-bottom: 1.2rem !important; */
}

.select-margin {
  margin-bottom: 0 !important;
}

.editbar .change-password-btn svg {
  margin-right: 0.1rem;
  width: 1.389vw;
  height: 1.389vw;
}

.Score-content-list h3 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.assigned_details_modal svg {
  width: 1.389vw;
  height: 1.389vw;
}

.mobile-card-content {
  height: 2.778vw;
  width: 2.778vw;
}

#assignToLLBtn {
  z-index: 3;
}

/* ///////////////////////// new design ///////////////////////////// */

/* Responsive css start from here  */
@media (min-width: 1700px) {
  /* .Auth-bar {
    height: 100vh;
} */
  .card-listing .card-body {
    min-height: 15vh;
  }
}

@media only screen and (max-width: 1440px) {
  .main-wapper {
    height: auto;
  }

  .edit-tabs.profile-edit .nav-item .nav-link {
    height: 44px;
    max-width: 177px !important;
    font-size: 14px;
  }

  .edit-tabs {
    /* height: 56px; */
    background: #fafafa;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .finacial {
    width: 100% !important;
  }
}

@media (max-width: 1090px) {
  .btngroupbar button.btnsubmit {
    padding: 12px 18px;
  }

  .custom-input {
    /* padding-left: 10px !important;
    margin-bottom: 20px; */
  }

  #page-container {
    padding-right: 0px !important;
    transition: all 0.3s ease-in-out !important;
  }

  .link-property.prime-selectmulti {
    grid-template-columns: 1fr;
  }

  .link-property.prime-selectmulti .btn {
    z-index: unset;
  }

  .process-bar-wapper {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .Score-right.home-section {
    width: 100% !important;
  }
}

.react-tel-input .flag {
  width: 1.111vw !important;
  height: 0.764vw !important;
}

@media only screen and (max-width: 1070px) {
  .middle-step::before {
    content: "";
    position: absolute;
    top: 34%;
    left: -53px;
    background-image: url(http://localhost:3000/static/media/waveone-img.6e0cbd511a64324ee11c.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    height: 20px;
  }

  .middle-step::after {
    content: "";
    position: absolute;
    bottom: 20%;
    right: -50px;
    background-image: url(http://localhost:3000/static/media/wavetwo-img.a9899dd2bf1064be5b47.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    height: 29px;
  }

  .five-steps img {
    width: 70px;
  }

  .five-steps {
    margin-bottom: 25px;
  }

  .add-detail {
    font-size: 14px;
    line-height: 18px;
  }

  .getsetup-body h1 {
    padding-bottom: 20px;
  }

  .step-three {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }

  .middlestep::before {
    width: 200px;
    left: -102px;
  }

  .upcoming-side {
    width: 100%;
  }

  .current-side {
    width: 100%;
  }

  .mobile-custom {
    flex-wrap: wrap;
  }

  .edit-tabs {
    max-width: 50% !important;
    /* height: 56px; */
    background: #fafafa;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bank-page .go-back-home span {
    font-size: 2vw !important;
  }

  .bank-page .greenbox-terms {
    padding: 64px 30px;
  }

  .bank-page .bank-body {
    padding: 64px 30px;
  }

  .review-form-body {
    padding: 20px;
  }

  .tbtn-st {
    margin-bottom: 15px;
  }

  .secondrysearchbar .custom-input {
    /* padding-left: 50px !important; */
  }

  .btn-up {
    top: 0;
  }

  .reviewandconform {
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .PhoneInputInput {
    font-size: 16px;
    font-family: "Google Sans";
  }

  .search-prop-landlord {
    height: 54px !important;
    width: auto !important;
  }

  .loginform .gridbar {
    grid-template-columns: 100%;
  }

  .number-properties .btn.btngreen {
    padding: 14px 20px;
  }

  .Score-content .Score-content-list {
    margin-bottom: 20px;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .Score-left {
    width: 40%;
    text-align: center;
  }

  .Score-right {
    width: 60%;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .rental-details {
    /* flex-direction: column; */
  }

  .rentail-headsec {
    flex-wrap: wrap;
    gap: 20px !important;
    display: flex;
  }

  .step-point {
    gap: 120px;
  }

  .step-point h3 {
    margin: 0;
  }

  .skor-depositsec {
    flex: 0 0 100%;
  }

  .skorfull-deposit {
    flex-wrap: wrap;
  }

  .fulldeposit-sec {
    flex: 0 0 100%;
    gap: 30px;
  }

  .deposit-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .keyflex-sec svg {
    width: 32px;
    height: auto;
  }

  .depositoptions-sec {
    align-items: flex-start;
  }

  .rent-left {
    flex-wrap: wrap;
    gap: 20px;
  }

  .confirm-continue {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .confirm-left {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
  }

  .review-form-body {
    padding: 20px;
  }

  .tbtn-st {
    margin-bottom: 15px;
  }

  .paymentdeailbar {
    flex-wrap: wrap;
  }

  .paymentdeailbarleft {
    width: 100%;
  }

  .paymentdeailbarryt {
    width: 100%;
  }

  .middle-step::before {
    display: none;
  }

  .middle-step::after {
    display: none;
  }

  .getsetup-body h1 {
    padding-bottom: 20px;
  }

  .custompb {
    margin-bottom: 10px;
  }

  .delete-link-btn {
    margin-bottom: 10px;
  }

  .bank-page .greenbox-terms {
    padding: 20px !important;
  }

  .go-back-home svg {
    height: 6vw;
    width: 6vw;
  }

  .bank-page .go-back-home span {
    color: #fff !important;
    font-size: 3vw;
  }

  .bank-page .greenbox-terms {
    flex: 0 0 50%;
  }

  .bank-page .bank-body {
    flex: 0 0 50%;
    padding: 20px;
  }

  .reviewandconform {
    padding: 20px;
    margin-bottom: 15px;
  }

  .review-pay {
    padding: 20px;
  }

  .choose-to-pay {
    padding: 20px;
  }

  .choose-to-pay .paynow-btn {
    padding: 20px;
  }

  .paynow-content p {
    font-size: 18px;
  }

  .payment-detail-content p span {
    padding-left: 5px;
    font-size: 12px;
  }

  .payment-detail-content {
    padding: 10px;
    margin-bottom: 15px;
  }

  .middlestep::before {
    display: none;
  }

  .tab-with-editbtn {
    padding: 0;
  }

  .heading_lng_div {
    align-items: center;
    margin-bottom: 30px;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .btngroup
    .btn {
    padding: 15px 5px;
    font-size: 16px;
  }

  .hero-foot .terms-bar {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

  .hero-foot .terms-bar .link-bar {
    font-size: 16px;
  }

  .edit-tabs .nav-item .nav-link {
    width: 100%;
  }

  .tab-with-editbtn .editbar button.btn-fill-border {
    width: 100%;
    font-size: 14px;
  }

  .tab-with-editbtn .editbar {
    width: 100%;
    top: 68px !important;
  }

  .edit-tabs .nav-item .nav-link {
    width: 100%;
  }

  .tab-with-editbtn .edit-tabs {
    margin-bottom: 90px;
  }

  .edit-tabs {
    /* --------------------new responsive css (3brain)----------------- */
    /* max-width: 80% !important; */
    max-width: 100% !important;
    /* height: 56px; */
    background: #fafafa;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #header .header-left {
    padding-left: 200px;
  }

  #left-menu ul li a {
    width: 160px;
  }

  #page-container {
    padding-left: 0px;
    padding-top: 0px;
  }

  #left-menu,
  #logo {
    width: 180px;
  }

  /* #toggle-left-menu,.big-logo{
      display: none;
  } */

  #left-menu > ul > li {
    padding: 15px 0;
    padding-left: 15px;
  }

  #logo {
    height: 130px;
    padding-left: 15px;
    width: 179px;
  }

  #header {
    height: 54px;
  }

  #left-menu {
    margin-top: 130px;
  }

  .content-disable button {
    width: 280px;
  }

  .tabs-item {
    padding: 15px;
  }

  .buy-section h5 {
    font-size: 20px;
  }

  .buy-section p {
    font-size: 15px;
  }

  .buy-section a img {
    width: 70px;
  }

  .margin-div {
    padding-left: calc(300px - 90px) !important;
  }

  body:has(.hassidebar) .margin-div {
    padding-left: 180px !important;
  }

  #left-menu ul li a span {
    font-size: 12px;
  }

  .btn-up {
    top: 0;
  }

  .middlestep {
    margin-top: 20px;
  }
}

.tabs-box-pattern .tabs-item h4.text-center.mt-2 {
  font-size: 1.667vw;
}

.qr-code-download {
  font-size: 1vw;
}

.modal-dialog-centered h4 {
  font-size: 1.667vw;
}

.tenant-right-box .col-md-4 {
  padding: 0.45vw 0.972vw !important;
}

.mobile-tenant-box .card-body {
  padding: 1.111vw;
}

@media (max-width: 991px) {
  .tenant-card-footer label img {
    width: 16px !important;
    height: 16px !important;
  }

  .mobile-tenant-box .card-body {
    padding: 16px !important;
  }

  .calvepin-option-div p {
    line-height: 30px !important;
  }

  .tenant-card-footer .tenant-card-p {
    width: 150px !important;
  }

  .mobile-tenant-box .videoframe {
    height: auto !important;
  }

  .tenant-right-box iframe {
    width: 100%;
  }

  .tenant-revisit-pdf-box {
    padding: 18px 12px !important;
    height: auto !important;
    margin-bottom: 20px;
  }

  .tenant-revisit-pdf-box h4 {
    font-size: 14px !important;

    padding-top: 8px !important;
    margin-bottom: 0 !important;
  }

  .tenant-revisit-pdf-box p {
    font-size: 14px !important;
  }

  .tenant-revisit-pdf-box label {
    margin-top: 10px !important;
  }

  .tenant-revisit-pdf-box .btngreen {
    padding-block: 8px !important;

    gap: 10px !important;
  }

  .tenant-revisit-pdf-box .btngreen img {
    width: 20px !important;
    height: 20px !important;
  }

  .tennat-eft-content-btn {
    font-size: 16px !important;
    padding-block-start: 14px !important;
  }

  .tenant-left-box {
    border-radius: 10px !important;
    padding: 20px !important;
  }

  .tenant-left-box h4 {
    font-size: 16px !important;
  }

  .tenant-left-box h5 {
    font-size: 16px !important;
  }

  .tenant-left-box-bottom .btngreen {
    justify-content: center;
    margin-top: 10px;
  }

  .tenant-left-box p {
    font-size: 14px !important;
  }

  .tenant-left-box img {
    width: 54px !important;
    height: 54px !important;
    margin-block-end: 10px !important;
  }

  .tenant-left-box-bottom .btngreen {
    padding: 0.833vw 1.25vw;
    display: flex;
    align-items: center;
    gap: 11px;
    text-transform: none;
  }

  .tenant-left-box-bottom .btngreen img {
    width: 20px !important;
    height: 20px !important;
    margin-bottom: 0px !important;
  }

  .tenant-right-box {
    margin-top: 40px !important;
  }

  .tenant-right-box h5 {
    font-size: 16px !important;
  }

  .tenant-right-box p {
    font-size: 14px !important;
  }

  .tenant-right-box svg {
    flex: 1 !important;
    font-size: 20px !important;
  }

  .mobile-tenant-box h5 {
    font-size: 16px !important;
  }

  .mobile-tenant-box p {
    font-size: 14px !important;
  }

  .mobile-tenant-box svg {
    flex: 1 !important;
    font-size: 20px !important;
  }

  .clave-box-bottom {
    padding-top: 10px !important;
  }

  .clave-box-bottom h3 {
    font-size: 18px !important;
    font-weight: 600;
    margin-block-end: 10px !important;
  }

  .tenant-right-box {
    margin: auto;
  }

  .tenant-light-box {
    /* opacity: 0.4; */
    background: #bbc1c1 !important;
  }

  .tenant-light-box .light-box-btn {
    border-radius: 10px !important;
    font-size: 16px !important;
  }

  .mobile-tenant-box {
    margin-bottom: 24px !important;
  }

  .forminput {
    font-size: 20px;
  }

  .waiting-tenant {
    max-width: 100% !important;
  }

  .modal-body .qr-link-copi {
    padding: 30px !important;
    display: flex !important;
  }

  .qr-link-copi input {
    width: 60% !important;
  }

  .qr-link-copi button {
    padding: 10px 20px !important;

    border-radius: 10px !important;
  }

  .max-w-lg .modal-dialog {
    max-width: 100%;
  }

  .react-tel-input .flag {
    width: 16px !important;
    height: 11px !important;
  }

  .btn-fill2 {
    height: 46px !important;
    border-radius: 10px !important;

    font-size: 14px !important;
  }

  .input-with-eye .eye-icons-reset {
    font-size: 20px;
  }

  .newmodalui .modal-header button.btn-close {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }

  p.password_condition.font_design {
    font-size: 14px;
    line-height: 2;
  }

  .hover-dropdown a {
    height: 40px;
  }

  .modal-dialog-centered h4 {
    font-size: 20px;
  }

  .paymentdeailbar {
    padding: 16px;
  }

  .details-form .radio-check input[type="radio"] {
    width: 16px !important;
    height: 16px !important;
  }

  .rent-slider .MuiSlider-thumb {
    height: 16px !important;
    width: 16px !important;
    background-color: #fff;
    border: 2px solid #253837;
  }

  .landloard-address-form {
    width: 100%;
  }

  .qr-link-copi input {
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
  }

  .qr-link-copi {
    margin: 20px auto !important;
  }

  .edit-det-bt {
    border-radius: 12px;
    padding: 20px 40px;
  }

  .hover-dropdown {
    padding: 15px 0;
  }

  .menu-togglebar
    label.customradiobtn
    input[type="radio"]:checked
    ~ .checkmark1 {
    border-radius: 6px;
  }

  .upcoming-table tbody {
    font-size: 16px;
  }

  .edit-det-bt {
    border-radius: 12px;
    border: 2px solid #a3eb64;
    padding: 20px 40px;
    background-color: transparent;
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .waiting-tenant svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .waiting-tenant {
    border-radius: 8px;
    background: rgba(70, 177, 255, 0.1);
    padding: 12px;
    max-width: 180px;
    margin: 0 auto;
  }

  .linked_li_rate {
    margin-block-end: 22px;
  }

  .assigned_details_body {
    padding: 24px 0px;
  }

  .linked_li_rate p {
    border-radius: 6px !important;
    padding: 8px 12px !important;
  }

  .assigned_details_modal .assigned_address {
    margin: 0px 12px;
  }

  .assigned_details_modal svg {
    width: 20px !important;
    height: 20px !important;
  }

  .number-properties span {
    font-size: 14px;
  }

  .hover-dropdown {
    width: 157px;
    border-radius: 8px;

    /* position: absolute; */
    width: 165px;
    height: 114px;

    background-size: 164px 114px;
  }

  .hover-dropdown a {
    display: flex;
    font-size: 15px;
    padding: 6px 20px;
  }

  .hover-dropdown img {
    width: 15px;
    height: 14px;
    object-fit: contain;
    margin-right: 10px;
  }

  .tabs-box-pattern .tabs-item h4.text-center.mt-2 {
    font-size: 24px;
  }

  .editbar .change-password-btn svg {
    width: 18px;
    height: 18px;
  }

  button.btn-fill-border {
    font-size: 12px !important;
  }

  .properties-box a img {
    width: 18px;
    height: 18px;
  }

  .filter-div .filterDiv select {
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    border-radius: 10px;
    line-height: 30px !important;
  }

  .property-percentage-box .max_rent_title {
    padding: 10px;
  }

  .secondryaccoridonbody.accordion-body .blockbookpayment {
    border-radius: 10px;
    padding: 10px 20px;
  }

  .setNoInfoColor p {
    font-size: 16px;
  }

  .custom-input {
    line-height: 30px !important;
  }

  .rent-slider .MuiSlider-valueLabelOpen.MuiSlider-valueLabel {
    font-size: 12px !important;
  }

  .css-1o63r9r-MuiSlider-root .MuiSlider-valueLabel {
    width: 38px !important;
    height: 38px !important;
  }

  .secondryaccoridonbody.accordion-body .blockbookpayment .lleftblock h5 {
    font-size: 18px !important;
  }

  .secondryaccoridonbody.accordion-body .blockbookpayment .lleftblock p {
    font-size: 14px !important;
  }

  .secondryaccoridonbody.accordion-body .blockbookpayment .rytblock {
    font-size: 14px !important;
  }

  label .radio-check input {
    margin: 0 !important;
  }

  .mobile-tenant-profile-accordation .delete-link-btn {
    width: 40px !important;
  }

  .primaryaccordion .primaryaccordion-body h4.titlebar_new {
    font-size: 16px !important;
  }

  .Notifications-wapper .sharingcardblock h4 {
    font-size: 20px !important;
  }

  .Notifications-wapper span {
    font-size: 16px !important;
  }

  .Notifications-wapper span {
    font-size: 16px !important;
  }

  select.form-control {
    height: 50px !important;
  }

  .percentage_salary_p {
    font-size: 14px !important;
  }

  .mobile-max-rent-heading h4 {
    font-size: 22px !important;
  }

  .metermodal .modal-body form.loginform h4 {
    font-size: 22px !important;
  }

  .address-popup-mobile .auth-form .gridbar {
    grid-template-columns: 100% !important;
  }

  .loginform p {
    font-size: 16px !important;
  }

  .Property-wrapper .authbar-inner .resend-text {
    width: 100% !important;
  }

  #property_reference {
    border-radius: 10px;
    padding: 10px;
    margin-top: 20;
    font-size: 16px;
  }

  .auth-form .btn svg {
    width: 18px;
    height: 18px;
  }

  li.autocomplete_li {
    padding: 8px 16px;
    text-align: left;
    border-bottom: 1px solid lightgrey;
    font-size: 16px;
    line-height: 1;
  }

  .mobile-card-content {
    width: 24px;
    height: 24px;
  }

  ul.autocomplete_ul {
    height: auto;
  }

  .rental-datails .details-form {
    max-width: 100% !important;
    padding: 20px;
  }

  .details-form input {
    border-radius: 12px !important;
    border: 1px solid var(--Grey, #7f7f7f);
    background: #fff;
    padding: 16px !important;
    margin-bottom: 16px !important;
    width: 100% !important;
    font-size: 14px;
    line-height: 1;
  }

  .payment-detail-content p {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .paymentdeailbarryt p {
    font-size: 16px !important;
  }

  .paymentdeailbarleft h3 {
    font-size: 20px !important;
  }

  .paymentdeailbarleft p {
    font-size: 16px !important;
  }

  .primaryaccordion .accordion-item {
    z-index: 0 !important;
  }

  .card-listing .card-body .listing-foot span.del-icon {
    width: 30px;
    height: 30px;
  }

  .doc-uploaded-from {
    font-size: 16px !important;
    padding: 8px 18px;
  }

  .max_rent_val {
    font-size: 22px !important;
  }

  .max_rent_title {
    font-size: 16px !important;
  }

  .percentage_salary {
    font-size: 14px !important;
    text-align: start;
  }

  .MuiSlider-markLabel {
    font-size: 14px !important;
  }

  .css-1bvr8oc-MuiSlider-markLabel {
    font-size: 14px !important;
  }

  .linked_p_details {
    font-size: 16px !important;
  }

  .linked_li_rate p {
    font-size: 20px !important;
  }

  .linked_li_div .linked_li_score {
    font-size: 16px !important;
  }

  .linked_li_div .linked_li_name {
    font-size: 20px !important;
  }

  .assigned_details_modal .assigned_address {
    font-size: 24px !important;
  }

  .properties-box li {
    margin-bottom: 8px;
  }

  .linked_p {
    font-size: 16px !important;
  }

  .flex-content ul span {
    font-size: 16px !important;
    margin-bottom: 14px !important;
  }

  .properties-box .flex-row h4 {
    font-size: 18px !important;
  }

  .filter-div .filterDiv label {
    font-size: 16px !important;
    margin-bottom: 8px !important;
  }

  .linked_tenant_div svg {
    width: 18px;
    height: 20px;
  }

  /* div.modal-dialog.modal-dialog-centered {
    max-width: 39.722vw;
  } */
  .filter-div.property-filter {
    margin-top: 23px;
    padding: 10px;
  }

  .mobile-max-less {
    flex-direction: row !important;
  }

  .auth-form label.forminput {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .otp-Verification .forminput input[type="tel"] {
    width: 40px !important;
    height: 40px !important;
  }

  .step-form-name {
    font-size: 24px;
  }

  .custom-input {
    /* padding-left: 10px !important;
    margin-bottom: 20px; */
  }

  .flatmate-rybar {
    display: grid;
    grid-template-columns: 11fr 1fr;
    align-items: center;
    gap: 12px;
  }

  .annual_income_tooltip .infodorpdown ul.tooltip {
    /* width: auto; */
  }

  #header .header-left .firstaside {
    flex: inherit;
  }

  #header .header-left {
    flex-wrap: nowrap;
  }

  #header {
    left: 0;
  }

  .second-aisde {
    width: 100%;
    gap: 10px;
    justify-content: flex-end;
  }

  #header .header-left .firstaside {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  #header .header-left .firstaside .tooltipbar svg {
  }

  #header .header-left .firstaside .tooltipbar h6 {
    position: absolute;
    top: 20px;
    width: 210px;
    z-index: 999;
    background: rgb(243 70 62 / 100%);
    left: 20px;
    margin: 0;
    color: #ffffff;
    display: none;
  }

  #header .header-left .firstaside .tooltipbar:hover h6 {
    display: block;
  }

  #header .header-left .firstaside {
    margin-bottom: 10px;
  }

  #header .header-left .firstaside {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  #header .header-left .firstaside .tooltipbar {
    display: block;

    position: relative;
  }

  #header .header-left .firstaside h6.mob-none {
    display: none;
  }

  .number-properties {
    display: grid;
    gap: 10px;
    grid-template-columns: 100%;
    top: 93px !important;
  }

  .number-properties .search-property input {
    width: 100% !important;
  }

  .number-properties .btn.btngreen {
    width: 100%;
  }

  .wappper-location {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .Notifications-wapper.opacity-wapper .share-it-profile {
    padding: 3px !important;
    width: 100%;
    text-align: center !important;
  }

  .wappper-location .or-wapper {
    width: 100%;
  }

  .Notifications-wapper.opacity-wapper .rental {
    width: 100%;
  }

  .profile-page.marless .Search-bar {
    /* margin-top: 20px; */
  }

  .Score-content .Score-content-list {
    margin-bottom: 20px;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .main-Score {
    display: block;
  }

  .Score-left {
    width: 100%;
    text-align: center;
  }

  .Score-right {
    width: 100%;
    padding-left: 30px;
  }

  .container {
    max-width: 95% !important;
  }

  #main-content {
    padding: 0px !important;
  }

  .primaryaccordion .primaryaccordion-body {
    padding: 0;
  }

  .link-property.prime-selectmulti .btn {
    z-index: unset;
  }

  .card-listing {
    padding: 10px;
  }

  .Landlord-details {
    border-bottom: 0.5px solid #dddddd;
    padding-bottom: 9px !important;
    margin-bottom: 66px !important;
  }

  .Score-content {
    padding: 0px !important;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 25px !important;
  }

  .Score-right.home-section {
    width: 100% !important;
    background: #ffffff;
    box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 30px;
  }

  .header-edit span {
    position: absolute;
    left: 7px !important;
    top: -5px !important;
  }

  .profile-page {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap !important;
    /* margin-bottom: 20px; */
  }

  .line-li {
    width: 48%;
    height: 2px;
    background-color: #939090;
    margin-bottom: 20px;
  }

  .line-flex {
    display: flex;
    justify-content: space-between;
  }

  .active-2 {
    background-color: var(--base-primary);
  }

  /* .Auth-bar .authbar .container .authbar-inner .leftbar {
  display: none;
} */

  .Auth-bar .authbar .container .authbar-inner .leftbar,
  .Auth-bar .authbar .container .authbar-inner .rightbar {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0 !important;
  }

  .link-property.prime-selectmulti {
    grid-template-columns: 1fr;
  }

  .link-property.prime-selectmulti {
    grid-template-columns: 1fr;
  }
}

.tenant-skor-wrapper .score-board {
  flex-wrap: nowrap;
}

.hide-for-desktop {
  display: none !important;
}

.qr-code-div {
  padding-top: 12px;
}

.qr-link-copi button {
  font-size: 1.111vw;
}

.qr-link-copi button svg {
  margin-right: 6px;
}

.qr-code-download a svg {
  margin-right: 6px;
}

@media screen and (max-width: 767px) {


  .Auth-wrapper .logobar img.company-logo {
    height: 70px !important ;
    display: block;
  }
  
  .Auth-wrapper .logobar img.power-by-logo {
    height: 30px !important;
    margin-top:8px;
  }

  .Auth-wrapper .logobar a {
    display: block !important;
    width: fit-content;
  }

  .info-modal .modal-header {
    border-bottom: 1px solid #dee2e6 !important;
  }


  .info-modal .modal-dialog {
    max-width:100%;
  }
  
  .info-modal .modal-dialog .modal-header, .info-modal .modal-dialog .modal-body  {
  padding: 16px;
  }
  
  .info-modal .modal-dialog ul{
    padding-left:32px;
  }
  
  .info-modal .btn-close {
    padding: 8.006px;
    width:15.998px;
    height:15.998px;
    background: transparent var(--bs-btn-close-bg) center/16px auto no-repeat;
  }
  
  .info-modal li{
    margin-top:4.997px;
    font-size: 15.998px;
  }
  
  .info-modal p {
    font-size: 15.998px;
  }
  
  .info-modal .modal-title {
    font-size: 24.005px;
  }




  body .clave-box-bottom.clave-box-setting .mobile-tenant-box {
    margin-bottom: 10px !important;
  }

  body .grid-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  body .grid-wrapper .add-more-doc-card {
    height: auto;
  }

  body .more-doc-label {
    font-size: 18px !important;
    line-height: 29.995px;
  }

  body .more-doc-label + .btn.btngreen.edit-status {
    margin-top: 8.006px;
    padding: 8.006px 15.005px !important;
  }

  body .tennat-left-box-top .infodorpdown .tooltip ul,
  body .upload_doc_tooltip .infodorpdown .tooltip ol {
    padding-left: 24.998px;
    margin-top: 8.006px;
  }

  .upload-doc-plac-img,
  .step-form-name.mobile-model-heading img {
    height: 54px;
    width: 54px;
  }

  .tooltip-heading {
    font-size: 13.997px;
  }

  body .upload_doc_tooltip {
    position: absolute;
    right: 20.002px;
    top: 15.005px;
  }

  body .upload-doc-plac-img {
    height: 54px;
    width: 54px;
  }

  .provide-doc-info span {
    font-size: 18px;
    line-height: 29.995px;
    display: inline-block;
  }

  body div .provide-doc-info {
    margin-bottom: 9.994px !important;
    padding: 15.998px !important;
    border-radius: 8.006px !important;
  }

  .back-btn span {
    font-size: 18px;
  }

  .provide-doc-info p {
    flex-wrap: wrap;
    gap: 10px;
  }

  body .provide-doc-info {
    margin: 0px !important;
    margin-bottom: 0.694vw !important;
    background: #fdf1eb !important;
    padding: 1.111vw !important;
    border-radius: 0.556vw !important;
  }

  .provide-doc-info svg {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }

  body .provide-doc-info .btn.btndarkgreen.edit-status {
    flex: 0 0 100%;
    padding: 8.006px 15.005px !important;
    margin-left: 0 !important;
  }

  .qr-link-copi input {
    width: 100%;
  }

  .css-1r4vtzz {
    padding: 3px 6px !important;
  }

  .Milestones-dates .mobile-address .heading {
    flex: 1;
    text-align: start;
  }

  .mobile-tenant-profile-accordation .download_profile_btn svg {
    width: 40px !important;
    height: 40px !important;
    margin-inline-end: 6px;
  }

  .linked_tenant_div {
    margin-top: 8px;
    padding: 18px;
    border-radius: 6px;
  }

  .profile-notification-box .leftyshare {
    display: block;
  }

  .profile-notification-box .leftyshare .max-rent-span {
    width: 100%;
    margin-inline: 0;
    margin-top: 10px;
    display: inline-block;
    padding: 10px 12px;
  }

  .steps-progress-bar {
    margin-top: 8px !important;
  }

  .modal-dialog-centered .modal-content {
    padding: 20px;
  }

  .auth-form .btngroup {
    padding: 0 20px;
  }

  .payment-detail-content p svg {
    width: 14px;
    height: 14px;
  }

  .payment-detail-content span {
    font-size: 14px !important;
  }

  .five-steps img {
    height: auto !important;
  }

  .landloard-payment-box .payment-text {
    font-size: 16px !important;
  }

  .landloard-payment-box .payment-detail-content svg {
    width: 14px;
    height: 14px;
  }

  .landloard-payment-box .payment-detail-content span {
    font-size: 14px !important;
  }

  .landloard-payment-box .upcoming-text {
    font-size: 14px !important;
  }

  .landloard-payment-box .upcoming-table .second-row .paidamount-first {
    font-size: 14px !important;
  }

  .summaryinfobar ul.tooltip p,
  .infodorpdown .tooltip li {
    font-size: 14px;
  }

  .padd-set-for-col .justify-content-center {
    justify-content: start !important;
    padding-inline: 10px !important;
  }

  .profile-notification-box .Notifications-wapper {
    display: flow !important;
  }

  .profile-notification-box .steps-progress-bar {
    margin: auto;
  }

  .mobile-tenant-profile-accordation .primaryaccordion-header {
    margin-bottom: 10px !important;
  }

  .mobile-tenant-profile-accordation.primaryaccordion {
    display: block !important;
  }

  .mobile-tenant-profile-accordation .tabs-item .row {
    display: block !important;
    text-align: center;
  }

  .mobile-tenant-profile-accordation button {
    height: 40px !important;
    padding: 3px 10px;
    margin-bottom: 0;
  }

  .mobile-tenant-profile-accordation button svg {
    width: 16px;
    height: 16px;
  }

  .mobile-tenant-profile-accordation .card-body h5 {
    margin-block-start: 14px;
  }

  .mobile-tenant-profile-accordation span.submain {
    font-size: 14px !important;
  }

  .primaryaccordion .accordion-button::after {
    width: 13px !important;
    height: 13px !important;
    background-size: 12px !important;
  }

  .mobile-tenant-profile-accordation .accordion-button span.accordion_score {
    padding: 4px 10px !important;
    margin-inline-end: 10px;
    font-size: 14px !important;
  }

  .linked_li_div {
    padding: 10px 0px 20px;
  }

  .mobile-single-property li {
    display: block !important;
  }

  .mobile-single-property .linked_li_details .btngreen {
    margin-block: 12px 24px;
    padding: 12px 24px !important;
  }

  .mobile-max-less {
    flex-direction: column !important;
  }

  .qr-code-download {
    padding-top: 10px;
  }

  .qr-link-copi {
    padding: 10px !important;
  }

  .mobile-max-less {
    padding: 0;
  }

  .Notifications-wapper .sharingcardblock h4 {
    flex-wrap: wrap !important;
  }

  .linked_li_rate {
    margin-block-end: 0px;
  }

  .mobile-max-rent-heading {
    padding: 12px !important;
    margin-block: 4px !important;
  }

  .address-popup-mobile .auth-form .gridbar {
    grid-template-columns: 88%;

    justify-content: center;
  }

  .address-popup-mobile .modal-content {
    padding: 18px !important;
  }

  .address-popup-mobile .auth-form .btn {
    gap: 8px;
  }

  .modal-content {
    padding: 10px;
  }

  .Property-wrapper:has(.mobile-property-cadastre) .container {
    margin: 0;
    padding: 0;
  }

  .mobile-property-cadastre .mobile-btn-box {
    margin: 0;
    padding: 0;
  }

  body:has(.mobile-max-rent-heading) .margin-div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body:has(.mobile-property-cadastre) .margin-div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body:has(.mobile-property-cadastre) .Property-wrapper {
    padding: 10px 5px 10px 20px !important;
    min-height: 60dvh;
    margin-top: 0;
  }

  .mobile-property-cadastre .col-md-6 {
    padding: 0;
  }

  .mobile-property-cadastre .col-md-8 {
    padding: 0;
  }

  .mobile-property-cadastre .col-md-4 {
    padding: 0;
  }

  .add-property-form-box .col-md-4 {
    padding: 0;
  }

  .add-property-form-box .col-md-8 {
    padding: 0;
  }

  .add-property-form-box .col-md-12 {
    padding: 0;
  }

  .mobile-property-cadastre {
    padding: 0 !important;
  }

  body div .done-btn {
    padding: 8px 15px;
    gap: 8px;
    top: 0;
    left: unset;
    width: fit-content !important;
  }

  .react-tel-input .country-list .country {
    text-align: start !important;
  }

  .auth-email-div .mail-icon {
    height: 14px !important;
  }

  .otp-Verification .login-otp-box.forminput input[type="tel"] {
    width: 32px !important;
    height: 32px !important;
  }

  .resend-otp-text {
    font-size: 12px !important;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .backbtnbar
    .backbtn {
    font-size: 16px !important;
  }

  .mobile-payoff-heading {
    gap: 10px;
  }

  .hide-for-mob {
    display: none !important;
  }

  body .hide-for-desktop {
    display: block !important;
  }

  body label.rounded-check.summaryinfobar.flexbarinner.no-wrap-on-mobile {
    flex-direction: row !important;
    padding-left: 0;
    gap: 10px;
    margin: 0;
  }

  .mobile-pymnet-heding {
    margin-top: 0 !important;
  }

  .mobile-btn-box {
    display: block !important;
  }

  .mobile-month-payment-box .btngroup {
    padding-inline: 5px;
  }

  .right-contentbar.mobile-month-payment-box {
    margin-top: 0 !important;
    padding-inline: 10px;
  }

  .choose-to-pay .paynow-btn {
    padding: 20px 10px;
  }

  .mobile-paynow {
    flex-direction: row !important;
    gap: 10px;
    align-items: center !important;
  }

  .mobile-paynow p {
    line-break: anywhere;
  }

  .mobile-review-footer .confirm-left h3 {
    font-size: 16px;
  }

  .mobile-review-footer .confirm-left p {
    font-size: 14px;
  }

  .mobile-review-screen .payment-title {
    font-size: 14px;
  }

  .mobile-review-screen .payment-header .payment-header-price {
    font-size: 16px;
  }

  .mobile-review-screen .reviewandconform {
    padding: 10px !important;
  }

  .mobile-user-profile .btnorange {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile-payment-popup .customradiobar {
    margin-bottom: 8px;
  }

  .mobile-payment-popup .modal-body {
    padding-inline: 10px;
  }

  .mobile-payment-popup .modal-header {
    margin-bottom: 8px;
  }

  .mobile-payment-popup .mobile-model-form-heading {
    align-items: center;
    margin-bottom: 8px;
  }

  .mobile-payment-popup .orange_text {
    padding: 6px !important;
  }

  .mobile-payment-popup .boxes img {
    width: 16px;
    height: 16px;
  }

  .mobile-payment-popup .boxes svg {
    width: 16px;
    height: 16px;
  }

  .mobile-payment-popup .boxes p {
    font-size: 14px !important;
  }

  .mobile-payment-popup .modal-dialog {
    margin: auto;
    max-width: 90% !important;
  }

  .mobile-depositoptions .skorprotect-sec svg {
    width: 18px;
    height: 18px;
  }

  .mobile-depositoptions .check-rent svg {
    width: 18px;
    height: 18px;
  }

  .mobile-rental-details .skor-depositsec .paymentdate-sec p {
    font-size: 12px !important;
  }

  .mobile-rental-details .manual-card-desc {
    font-size: 12px !important;
  }

  .mobile-rental-details .regularrent-amount {
    font-size: 16px !important;
  }

  .mobile-rental-details .paymentbetween p {
    font-size: 14px !important;
  }

  .mobile-rental-details .paymentbetween span {
    font-size: 14px !important;
  }

  .mobile-rental-details .skorfull-deposit .regular-rent h6 {
    font-size: 16px !important;
  }

  .mobile-rental-details label {
    font-size: 14px !important;
  }

  .mobile-rental-details input {
    height: 45px !important;
  }

  .mobile-depositoptions .check-deposit svg {
    width: 18px;
    height: 18px;
  }

  .mobile-depositoptions .check-deposit h3 {
    font-size: 16px;
  }

  .mobile-depositoptions p {
    font-size: 14px;
    padding: 10px;
  }

  .mobile-depositoptions h3 {
    font-size: 16px;
  }

  .mobile-rental-details .fulldeposit-sec {
    padding-block: 10px;
  }

  .mobile-rental-details .fulldeposit-sec h6 {
    font-size: 16px;
  }

  .mobile-rental-details .fulldeposit-sec p {
    font-size: 14px;
  }

  .mobile-rental-details .full-depositsec del {
    font-size: 12px;
  }

  .mobile-rental-details .skorflex-rent h6 {
    font-size: 14px;
  }

  .mobile-rental-details .skorflex-rent p {
    font-size: 12px;
  }

  .mobile-rental-details .rental-step-point {
    padding: 12px 0px;
  }

  .mobile-rental-details .rental-step-point p {
    font-size: 14px;
    padding: 10px;
    margin-left: 12px;
  }

  .mobile-rental-details .rental-step-point h3 {
    font-size: 16px;
  }

  .mobile-rental-info .rentail-headsec {
    padding: 14px;
  }

  .mobile-rental-info .mobile-rental-display {
    display: block !important;
  }

  .mobile-rental-info .heading-check svg {
    width: 18px;
    height: 18px;
  }

  .mobile-rental-info .heading-check h3 {
    font-size: 18px;
  }

  .mobile-rental-info .rental_details svg {
    width: 16px;
    height: 16px;
  }

  .mobile-rental-info .rental_details {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile-rental-info .rental_details p {
    font-size: 16px;
    line-height: 1.5;
  }

  .mobile-rental-info .rental_details h6 {
    font-size: 14px;
    line-height: 2;
  }

  .reviewandconform.uiux.mobile-review-box {
    padding-block-start: 20px;
    padding-inline: 20px !important;
  }

  .mobile-review-box .reviewandconform-body {
    padding-block-start: 16px;
  }

  .reviewandconform.uiux.mobile-review-box .payment-body {
    margin-block-start: 25px !important;
    padding: 15px !important;
  }

  .mobile-review-box .payment-body h6 {
    font-size: 14px !important;
  }

  .mobile-review-box .payment-detail-content {
    padding-inline: 10px;
  }

  .mobile-review-box .btngreen {
    font-size: 16px !important;
  }

  .mobile-review-box .payment-detail-content p svg {
    width: 14px;
    height: 14px;
  }

  .mobile-review-box .payment-detail-content span {
    font-size: 14px;
    line-height: 2;
  }

  .mobile-review-box .mobile-review-filed {
    padding-block-end: 10px;
  }

  .mobile-steps-box .step-num {
    font-size: 16px;
    max-width: 30px;
    height: auto;
    text-align: center;
    margin-block-end: 10px;
  }

  .mobile-steps-box .step-one img {
    height: auto;
    width: 70px;
  }

  .mobile-steps-box .step-one .add-detail {
    font-size: 16px;
    padding-block-start: 14px;
  }

  .mobile-rental-info {
    margin-inline-start: 12px;
  }

  .fYLFBX {
    font-size: 14px;
  }

  .mobile-model-heading {
    gap: 10px;
  }

  .profile-page.tab-profile-search-header {
    padding-inline: 10px;
  }

  .mobile-back-icon {
    margin-block-start: 10px;
    margin-inline-start: 10px;
  }

  .mobile-profile-info-box {
    flex: 1;
  }

  .mobile-profile .accordion-tile {
    padding-block: 16px;
  }

  .mobile-profile .view_doc_btn {
    margin-top: 12px;
  }

  .mobile-user-profile .mobile-profile h4 {
    color: #121212;
    line-height: 1.8;
    font-size: 22px;
  }

  .mobile-user-profile .mobile-profile .lleftbarbtn {
    line-height: 2;
    font-size: 16px;
    margin-block-end: 10px;
  }

  .mobile-user-profile .mobile-profile .mobile-identification {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .mobile-user-profile .mobile-profile .score-summary {
    padding-inline: 15px;
  }

  .mobile-user-profile .mobile-profile .iconinfo svg {
    height: 18px;
    width: 18px;
  }

  .mobile-user-profile .mobile-profile .tooltip {
    width: 160px;
    padding: 10px;
  }

  .mobile-user-profile .mobile-profile .gurantor_box {
    margin-block: 6px;
  }

  .mobile-user-profile .mobile-profile .mobile-perdonal-info {
    padding-inline: 15px;
    margin-bottom: 0;
  }

  .mobile-user-profile .score-personal-info.mobile-profile {
    padding-inline: 0;
  }

  .mobile-user-profile .mobile-profile .mobile-perdonal-info .gurantor_box {
    padding-inline: 15px;
    margin-bottom: 10px;
  }

  .mobile-user-profile
    .mobile-profile
    .mobile-perdonal-info
    .gurantor_box
    .Score-content-list {
    padding-inline: 12px;
  }

  .mobile-user-profile .mobile-profile .mobile-professional {
    padding-inline: 15px;
  }

  .mobile-profile label {
    font-size: 16px;
  }

  .mobile-user-profile .mobile-profile .mobile-professional .incomes_main_div {
    width: 100%;
    margin: 0;
    padding-inline: 12px;
  }

  .mobile-user-profile
    .mobile-profile
    .mobile-professional
    .incomes_main_div
    h4 {
    padding: 0;
  }

  .mobile-perdonal-info .Score-content-list h4 {
    line-height: 2 !important;
  }

  .mobile-professional .Score-content-list h4 {
    line-height: 2 !important;
  }

  .mobile-user-profile
    .mobile-profile
    .mobile-professional
    .incomes_main_div
    .Score-content-list
    h3 {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .mobile-document-accordion {
    border-radius: 6px !important;
    /* padding: 10px; */
  }

  .mobile-document-accordion .submain {
    font-size: 18px !important;
  }

  .mobile-document-accordion .accordion-button::after {
    width: 13px !important;
    height: 13px !important;
    background-size: 15px !important;
  }

  .mobile-primary-accordaion .maintitle {
    font-size: 20px !important;
  }

  .mobile-primary-accordaion .setaccordionhead {
    padding: 12px !important;
  }

  .mobile-primary-accordaion .download_profile_btn {
    top: 11px;
    margin-inline-end: 24px;
  }

  .mobile-primary-accordaion .mobile-profile {
    padding: 0 !important;
  }

  .mobile-primary-accordaion {
    display: block;
    width: 100%;
  }

  .mobile-primary-accordaion .accordion_score {
    font-size: 18px !important;
  }

  .mobile-primary-accordaion .setaccordionhead .accordion-button {
    min-height: 40px;
  }

  .mobile-primary-accordaion .accordion-button::after {
    width: 13px !important;
    height: 13px !important;
    background-size: 15px !important;
  }

  .mobile-primary-accordaion .accordion-button {
    padding-inline: 16px;
  }

  .mobile-perdonal-info .gurantor_box .Score-content-list h3 {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .mobile-user-profile .mobile-profile .summaryinfobar ul.tooltip p,
  .infodorpdown .tooltip li {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .mobile-professional .tooltip p {
    font-size: 16px;
  }

  .clave-box-setting .mobile-tenant-box.card {
    margin-top: 20px;
  }

  .mobile-edit-form-box .backbtn svg {
    font-size: 10px;
  }

  .mobile-perdonal-info .Score-content-list h3 {
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
  }

  .mobile-pdf-card-box {
    padding: 10px 14px !important;
  }

  body:has(#sub-header-links) .margin-div {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mobile-home-icon-box {
    flex: none !important;
  }

  .step-form-name > svg {
    width: 40px;
    height: 40px;
  }

  .tenant-skor-wrapper .score-board {
    flex-wrap: wrap;
  }

  /* media query for VW setting in mobile view */
  body #sub-header-links .sub-link.active a {
    border-radius: 18px 18px 0px 0px;
    /* font-size: 11.995px !important; */
    border-bottom: 2px solid #a3eb64;
  }

  #sub-header-links .sub-link a {
    padding: 15.005px 20.002px;
    font-size: 14px;
  }

  body #skor_navbar_logo {
    width: 144px !important;
    height: 36px !important;
  }

  body .menu-togglebar {
    border-radius: 2.5px;
    padding: 5px;
    width: 120px;
  }

  .menu-togglebar .checkmark1 {
    font-size: 14px;
    height: 28px;
  }

  .admin-name {
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 18px;
  }

  body .second-aisde {
    gap: 20.002px;
  }

  .badge + img {
    height: 31.003px;
    width: 27px;
  }

  body .badge {
    height: 20.002px;
    width: 20.002px;
    line-height: 13.003px;
    top: -11.995px;
    left: 11.995px;
    font-size: 13.003px;
  }

  body:has(#sub-header-links) .margin-div {
    margin: 0 29.995px;
    border-radius: 0px;
  }

  body #sub-header-links {
    margin-top: 96.005px !important;
  }

  #sub-header-links .sub-head-links-wrapper {
    border-radius: 18px 18px 0px 0px;
  }

  .tenant-info-wrapper {
    padding: 9.994px 20.002px;
    border-radius: 18px;
    margin-bottom: 9.994px;
  }

  .tenant-badge-name {
    width: 80.006px;
    height: 80.006px;
    font-size: 24.005px;
    line-height: 56.995px;
  }

  .tenant-info-wrapper .tenant-info .tenant-details .tenant-name {
    font-size: 15.998px;
  }

  .tenant-details {
    gap: 20.002px;
  }

  .tenant-info-wrapper .tenant-info .tenant-details .tenant-status {
    border-radius: 11.995px;
    padding: 11.995px 24.005px;
    font-size: 15.998px;
    font-weight: 700;
    gap: 11.995px;
  }

  .tenant-info-wrapper .tenant-info .tenant-details .tenant-status svg {
    height: 20.002px;
    width: 20.002px;
  }

  .tenant-details {
    flex-direction: column;
  }

  .reverify-btn {
    height: fit-content;
  }

  .reverify-btn img {
    height: 45.994px;
    width: 45.994px;
    max-width: unset;
  }

  .tenant-info-wrapper .tenant-info .tenant-details .tenant-email,
  .tenant-info-wrapper .tenant-info .tenant-details .tenant-number,
  .tenant-info-wrapper .tenant-info .tenant-contact .tenant-email,
  .tenant-info-wrapper .tenant-info .tenant-contact .tenant-number {
    gap: 11.995px;
    font-size: 15.998px;
    margin-top: 10px;
  }

  .tenant-info-wrapper .tenant-info .tenant-details .tenant-email svg,
  .tenant-info-wrapper .tenant-info .tenant-details .tenant-number svg,
  .tenant-info-wrapper .tenant-info .tenant-contact .tenant-email svg,
  .tenant-info-wrapper .tenant-info .tenant-contact .tenant-number svg {
    height: 15.998px;
    width: 20.002px;
  }

  .tenant-info-wrapper .reward-points-wrapper {
    border-radius: 8.006px;
  }

  .tenant-info-wrapper .reward-points-wrapper .reward-head {
    font-size: 15.998px;
    padding: 9.994px;
    gap: 11.995px;
  }

  .tenant-info-wrapper .reward-points-wrapper .reward-points {
    font-size: 24.005px;
    padding: 4.997px;
    border-radius: 8.006px;
  }

  .tenant-info-wrapper .reward-points-wrapper .reward-head svg {
    height: 22.003px;
    width: 24.005px;
  }

  .pending-steps-wrapper {
    padding: 9.994px 20.002px;
    border-radius: 18px;
    margin-bottom: 15.005px;
  }

  .tenant-skore .skore-heading {
    font-size: 24.005px;
    /* margin-bottom: 24.005px !important; */
  }

  .guage-box {
    width: 250px;
    height: 200px;
  }

  .gague-val-box {
    top: 20px;
  }

  .gague-val h1 {
    font-size: 30px;
  }

  .gague-val p {
    font-size: 10px;
  }

  .score-name {
    font-size: 20.002px;
  }

  ul.score-textgray span {
    font-size: 11.995px;
  }

  .tenant-skore > div {
    margin-bottom: 4.997px;
  }

  .tenant-skore .border-wrapper {
    border-radius: 11.995px;
    border: 0.994px solid #ddd;
    padding: 15.998px;
    margin-bottom: 10px;
  }

  .tenant-skore .score-card .score-card-head {
    font-size: 18px;
    margin-bottom: 15.005px;
  }

  .progress-bar-wrapper .progress-head {
    font-size: 18px;
  }

  .progress-bar-wrapper .progress-score {
    border-radius: 8.006px;
    padding: 11.995px 20px !important;
    margin-bottom: 11.995px;
    font-size: 16px;
    /* min-width: 137.995px !important; */
  }

  .progress-step {
    border-radius: 9.994px;
    height: 8px;
    margin-top: 8px;
  }

  .progress-bar-inner-wrapper .score-limit {
    font-size: 13.997px;
    margin: 8.006px 0;
  }

  .step-go-home-btn svg {
    height: 1.25vw;
    width: 1.25vw;
    margin-right: 0.556vw;
  }

  body .step-go-home-btn {
    border-radius: 11.995px;
  }

  body .btn {
    padding: 14px 30px;
    border-radius: 11.995px;
    font-size: 15.005px !important;
    line-height: 24.005px;
    width: 100%;
  }

  .step-go-home-btn svg {
    height: 18px;
    width: 18px;
    margin-right: 8.006px;
  }

  .btngrey svg {
    height: 18px;
    width: 15.005px;
    margin-right: 8.006px;
  }

  .btn-margin-set .btn {
    margin-top: 10px;
    justify-content: center;
  }

  .tenant-skore .row > div.col-12 .btn.btngreen {
    padding: 14px 30px;
    font-size: 15.005px;
    border-radius: 11.995px;
    gap: 8.006px;
  }

  .tenant-skore .row > div.col-12 .btn.btngreen svg {
    height: 20.002px;
    width: 18px;
  }

  .skore-details-heading {
    font-size: 24.005px;
    margin-bottom: 8.006px;
  }

  .cust-card-wrapper {
    padding: 24.005px 27.994px;
    border-radius: 18px;
  }

  .cust-card-wrapper .card-heading {
    font-size: 18px;
  }

  .cust-card-wrapper .card-content .card-content-image {
    margin-bottom: 15.998px;
    width: 30px;
    height: 30px;
  }

  .cust-card-wrapper .card-content .card-content-heading {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .Milestones-dates {
    border-radius: 11.995px;
    padding: 15.005px;
  }

  .Milestones-dates .Milestones-head {
    font-size: 18px;
  }

  .stepper-wrapper {
    margin-top: 4.997px;
  }

  .stepper-item .step-counter {
    width: 26.006px;
    height: 26.006px;
    margin-bottom: 6.005px;
  }

  .stepper-item .step-counter img {
    width: 11.995px;
    height: 9.994px;
  }

  .stepper-item::after {
    border-bottom: 2px solid #ccc;
    top: 13.003px;
  }

  .stepper-item::before {
    border-bottom: 2.002px solid #ccc;
    top: 13.003px;
  }

  .stepper-item .step-name {
    font-size: 13.997px;
  }

  .cards-wrapper > div {
    padding: 8px !important;
  }

  .cards-wrapper .date-card {
    border-radius: 11.995px;
  }

  .cards-wrapper .date-card .date-card-header {
    padding: 8px 29.002px;
    font-size: 13.997px;
  }

  .cards-wrapper .date-card .date-card-footer .card-date {
    font-size: 18px;
  }

  .expiry-skor-date span {
    font-size: 10px;
  }

  .tile-design .tile-content-wrapper {
    gap: 18px;
  }

  .tile-design .tile-content-wrapper .tile-heading {
    font-size: 20.002px;
  }

  .pending-steps-wrapper .tile-design .tile-desc {
    font-size: 13.997px;
  }

  .pending-steps-wrapper .tile-design .tile-btns-wrapper .incomplete-btn,
  .pending-steps-wrapper .tile-design .tile-btns-wrapper .completed-btn {
    font-size: 13.997px;
    padding: 18.994px;
    min-width: 200.002px;
    border-radius: 11.995px;
  }

  .pending-steps-wrapper .tiles-main-heading {
    font-size: 20px;
  }

  .steps-progress-bar {
    width: 74.995px;
    height: 74.995px;
  }

  .steps-progress-bar .circle .mask {
    clip: rect(0px, 74.995px, 74.995px, 36.994px);
  }

  .steps-progress-bar .inside-circle {
    font-size: 15.998px;
    top: 9.994px;
    bottom: 9.994px;
    right: 9.994px;
    left: 9.994px;
    z-index: 1;
  }

  .mobileprofile-select .form-group {
    width: 100% !important;
  }

  .mobileprofile-select .form-control.share-filter {
    width: 100%;
  }

  .tabs-box-pattern-2 .tabs-profile {
    padding: 0px !important;
  }

  .tabs-box-pattern-2 .leftyshare.sharingcardblock {
    padding: 0px !important;
  }

  .steps-progress-bar .mask .fill {
    clip: rect(0px, 36.994px, 74.995px, 0px);
  }

  .tile-design .tile-content-wrapper img {
    height: 54px;
    width: 54px;
  }

  .pending-steps-wrapper .tile-design:has(.tile-desc-completed) .tile-heading {
    font-size: 20.002px;
  }

  .pending-steps-wrapper .tile-design .tile-desc-completed {
    font-size: 13.997px;
  }

  .pending-steps-wrapper .tile-design .tile-btns-wrapper .tile-points {
    font-size: 14.998px;
    border-radius: 11.995px;
    padding: 15.998px 27.994px;
    gap: 11.995px;
  }

  .pending-steps-wrapper .tile-design .tile-btns-wrapper .tile-points img {
    height: 22.003px;
    width: 24.005px;
  }

  .tile-design .tile-content-wrapper {
    flex-direction: column;
    margin-top: 15px;
  }

  .gague-val {
    margin-top: 0;
    height: 100px;
    width: 100px;
  }

  .skore-image-wrapper img {
    height: 180px;
    width: 369px;
    width: 100%;
  }

  .skore-details-content img {
    height: 74.002px;
    width: 74.002px;
  }

  .skore-details .skore-details-content .skore-detail-heading {
    font-size: 26.006px;
    margin-top: 26.006px;
  }

  .skore-details .skore-details-content .skor-details-desc {
    font-size: 13.997px;
  }

  .skore-details .skore-details-content .complete-btn {
    margin-top: 15px;
    max-width: unset;
    width: 100%;
  }

  .skore-details .skore-details-content .skore-details-points {
    font-size: 14.998px;
    border-radius: 11.995px;
    padding: 15.998px 27.994px;
    gap: 11.995px;
    margin-top: 18px;
  }

  .skore-details .skore-details-content .skore-details-points img {
    height: 22.003px;
    width: 24.005px;
  }

  .view-progress {
    border-radius: 12px !important;
    font-size: 14px;
    padding: 19px;
    min-width: 200px;
  }

  .margin-div {
    margin: 0 2.083vw !important;
  }

  .back-btn img {
    width: 50px;
    height: 50px;
  }

  .cust-width-setting .cust-inner-wrapper {
    max-width: unset;
  }

  .document_preview_div h5 {
    font-size: 1.2rem;
    line-height: unset;
  }

  .card-listing .slick-slider .slick-prev,
  .card-listing .slick-slider .slick-next {
    width: 1.5rem;
    height: 1.5rem;
  }

  .card-listing .slick-slider .slick-prev {
    left: -16px;
  }

  .card-listing .slick-slider .slick-next {
    right: -16px;
  }

  .card-listing .slick-slider > .slick-dots {
    width: 100%;
  }

  .card-listing .slick-dots {
    bottom: 0;
  }

  select.form-control {
    height: 50px;
    font-size: 1rem;
    line-height: normal;
  }

  select {
    background-size: 10px 10px !important;
  }

  /* --------------------new responsive css (3brain)----------------- */

  .annual_income_tooltip .infodorpdown ul.tooltip {
    width: 45vw;
  }

  .annual_income_tooltip .infodorpdown ul.tooltip li p {
    white-space: unset;
    font-size: 0.8rem;
    line-height: normal;
  }

  body:has(.hassidebar) .margin-div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .skor_limits {
    display: none;
  }

  .custom-input {
    width: 100% !important;
    height: 3rem !important;
    font-size: 0.8rem !important;
    padding-left: 1.5rem !important;
    border-radius: 0.75rem !important;
  }

  .custom-input::placeholder {
    font-size: 0.8rem !important;
  }

  .Search-bar a {
    /* left: 15px;
    top: 15px; */
    left: 0.4rem !important;
    right: 0.2rem !important;
    top: 46%;
    transform: translateY(-50%);
  }

  .Search-bar img {
    width: 1rem !important;
    height: 1rem !important;
  }

  .Notifications-wapper h4 {
    font-size: 0.89rem;
    line-height: 1.06rem;
    text-align: left !important;
  }

  .Notifications-wapper p {
    font-size: 0.89rem;
    line-height: 1.06rem;
  }

  .Notifications-wapper {
    flex-wrap: nowrap !important;
  }

  .share-it .share-btn {
    padding: 1rem;
    margin: 0;
    line-height: normal;
    border: 1px solid #253837;
    border-radius: 8px;
    height: auto;
  }

  .share_landlord_info {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: 0.9rem;
  }

  .share_landlord_info > svg {
    height: 20px;
    width: 20px;
    translate: 0 1px;
  }

  .select-radio .form-check .form-check-input:checked {
    border: 1px solid #ffffff;

    flex: 0 0 10px;

    height: 10px;
  }

  .modal-main-heading {
    font-size: 1.25rem;
  }

  .modal-header .btn-close {
    margin: 0;
  }

  .modal-main-heading + .btn-close {
    height: 12px;
    width: 12px;
  }

  .modal-sub-heading {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .icon-text-box {
    margin-block-end: 1rem;
    gap: 0.8rem;
  }

  .icon-text-box .text-box .box-heading {
    font-size: 1rem;
  }

  .icon-text-box > img {
    width: 36px;
    height: 36px;
  }

  .icon-text-box .text-box .box-desc {
    font-size: 1rem;
  }

  .step-form-main-wrapper .steps-wrapper .label-text,
  .newmodalui .modal-body .auth-form .forminput label.forminput.label-text {
    font-size: 1rem;
    margin-bottom: 0.4rem;
    white-space: nowrap;
  }

  .newmodalui .modal-header .modal-title {
    font-size: 1rem;
    line-height: unset;
  }

  .newmodalui .modal-header button.btn-close {
    filter: none;
    top: 15px;
  }

  .form-control {
    font-size: 1rem;
    line-height: normal;
    border-radius: 8px;
    padding: 0.8rem;
  }

  .step-form-main-wrapper .steps-wrapper .orangetext {
    margin-block: 1rem;
  }

  .form-control.uni_select
    .css-13cymwt-control
    .css-1wy0on6
    .css-1xc3v61-indicatorContainer
    svg {
    height: 1rem;
    width: 1rem;
  }

  .forminput,
  .step-btns-group,
  .css-13cymwt-control {
    /* select.form-control { */
    /* .user-auth { */
    margin-bottom: 1rem;
  }

  .select-margin {
    margin-bottom: 0.2rem !important;
  }

  .phonenumber-input .react-tel-input .form-control {
    font-size: 1rem;
    height: 45px;
  }

  .css-13cymwt-control {
    border-radius: 8px;
  }

  .btn.skip-btn {
    padding: 15px;
  }

  .forminput label.forminput {
    margin-bottom: 0.4rem;
    font-size: 1rem;
  }

  .customradiobar .boxes {
    font-size: 0.9rem;
    line-height: normal;
    padding: 0.8rem;
  }

  .two-items-row {
    gap: 1rem;
  }
}

@media (max-width: 767px) {
  .landlordRentalDetails .mobile-address-d-none {
    display: none !important;
  }

  .mobile-getsetup-body .step-num {
    max-width: 30px;
    margin: auto;
    height: 30px;
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-end: 10px;
  }

  .mobile-btn-accordation {
    position: relative !important;
    z-index: 9 !important;
  }

  .mobile-tenant-profile-accordation .iconinfo {
    height: auto !important;
  }

  .card-listing .card-body .listing-foot span {
    font-size: 12px;
  }

  .mobile-card-content {
    margin-top: 10px;
  }

  .stepper-wrapper {
    justify-content: center;
  }

  .property-percentage-box {
    display: block !important;
    padding-inline: 10px !important;
  }

  .property-percentage-box .max_rent_title {
    padding-top: 0;
    margin-top: 0;
  }

  .mobile-tenant-profile-accordation .tabs-item .row {
    padding-block: 10px;
  }

  .mobile-professional {
    padding: 10px !important;
  }

  .mobile-perdonal-info {
    padding: 10px !important;
  }

  .userscrollprofile-topbar .border-pd h4 {
    font-size: 18px;
  }

  .summary-checkes {
    padding: 10px !important;
  }

  .mobile-max-rent-heading h4 {
    padding-inline: 0 !important;
  }

  .mobile-max-rent-heading .col-sm-6 {
    padding-inline: 0 !important;
  }

  .mobile-max-rent-heading .d-flex.justify-content-between .col-sm-12 {
    padding-inline: 16px !important;
    padding-bottom: 10px;
    width: 100%;
  }

  /* .mobile-max-rent-heading .css-1o63r9r-MuiSlider-root {
    padding-inline: 10px !important;
  } */
  .properties-box li {
    display: block !important;
  }

  .mobile-flatmate-box {
    display: block !important;
    padding-inline: 10px;
  }

  .mobile-flatmate-box svg {
    width: 30px !important;
    height: 30px !important;
    margin-top: 10px;
  }

  .stepper-item {
    flex: 0 0 90px;
  }

  .stepper-wrapper {
    gap: 16px;
  }

  .cards-wrapper {
    flex-wrap: nowrap;
    display: inline-flex;
  }

  .university-img {
    width: 50px;
    object-fit: contain;
    top: 13px;
    left: 23px;
  }

  .choose-to-pay {
    padding: 20px;
  }

  .choose-to-pay h1 {
    font-size: 24px;
  }

  .paynow-btn {
    padding: 15px 10px;
    align-items: flex-start;
  }

  .paynow-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    flex: 0 0 70%;
  }

  .paynow-content p {
    font-size: 18px;
    white-space: nowrap;
  }

  .paynow-content svg {
    width: 32px;
    height: auto;
  }

  .three-rentpayment-sec {
    padding: 20px;
  }

  .check-rent {
    display: flex;
    align-items: center;
    gap: 3.125vw;
    flex: 0 0 50%;
  }

  .rent-left {
    flex-direction: column;
    gap: 20px;
    flex: 0 0 90%;
    align-items: flex-start;
  }

  .check-rent {
    gap: 30px;
  }

  .keyflex-sec svg {
    width: 32px;
    height: auto;
  }

  .skorprotect-sec {
    gap: 30px;
  }

  .confirm-left {
    flex-direction: column;
    flex: 0 0 100%;
    align-items: center;
    gap: 10px;
  }

  .confirm-continue {
    padding: 20px;
    flex-direction: column;
    gap: 20px;
  }

  .confirm-left h3 {
    text-align: left;
    margin: 0;
  }

  .reviewandconform {
    margin-bottom: 10px;
  }

  .check-deposit {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .depositoptions-sec {
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
  }

  .deposit-left {
    flex-direction: column;
    flex: 0 0 90%;
    align-items: flex-start;
    gap: 20px;
  }

  .keyflex-sec p {
    white-space: normal;
    font-size: 16px;
    line-height: normal;
  }

  .three-rentpayment-sec {
    padding: 20px;
  }

  .rental-details .rentail-headsec {
    gap: 25px;
    flex-direction: column;
  }

  .step-point {
    gap: 0;
    justify-content: space-around;
  }

  .skorfull-deposit {
    flex-direction: column;
  }

  .rentaldetails-body {
    padding: 20px;
  }

  .fulldeposit-sec {
    gap: 40px !important;
  }

  .flex-wrapper.bank-page {
    display: block;
  }

  .bank-page .greenbox-terms {
    padding: 20px;
    height: auto;
  }

  .bank-page .orange-line,
  .bank-page .white-line {
    display: none;
  }

  .go-back-home svg {
    height: 8vw;
    width: 8vw;
  }

  .bank-page .go-back-home span {
    color: #fff !important;
    font-size: 3vw;
  }

  .bank-page .what-we-heading {
    font-size: 8vw;
  }

  .bank-page .what-we-heading {
    font-size: 8vw;
  }

  .terms-heading img {
    width: 20vw;
    margin-top: 25px;
  }

  .bank-page .bank-body {
    padding: 20px;
    height: auto;
    overflow: auto;
  }

  .bank-body h3 {
    margin: 20px 0 10px;
  }

  .primaryaccordion .primaryaccordion-header .accordion-button {
    padding: 12px !important;
    font-size: 18px;
  }

  label.rounded-check.summaryinfobar.flexbarinner {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }

  .view_doc_btn {
    padding: 10px 12px !important;
    justify-content: center !important;
  }

  .scorebtnbar {
    flex-direction: column;
    gap: 10px;
  }

  .scorebtnbar .lleftbarbtn,
  .scorebtnbar .rytbarbtn {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .primaryaccordion .primaryaccordion-header .accordion-button .maintitle {
    font-size: 14px;
  }

  .primaryaccordion
    .primaryaccordion-header
    .accordion-button
    span.accordion_score {
    /* --------------------new responsive css (heena)----------------- */
    /* font-size: 14px;
    padding: 7px 16px; */
    font-size: 1.02vw;
    padding: 0.51vw 1.17vw;
  }

  span.submain {
  }

  .primaryaccordion .primaryaccordion-header .accordion-button span.submain {
    /* --------------------new responsive css (heena)----------------- */
    /* font-size: 12px; */
    font-size: 0.88vw;
  }

  .primaryaccordion .primaryaccordion-body {
    padding: 10px 0px;
  }

  .primaryaccordion .primaryaccordion-body h4.titlebar_new {
    margin-bottom: 0;
  }

  .link-property.prime-selectmulti .btn {
    height: auto;
    z-index: unset;
  }

  span.placeholder_string {
    margin: 3px 0 0 0;
    font-size: 12px;
    padding: 5px;
    border-radius: 8px;
  }

  .resend-text {
    max-width: 100%;
  }

  .Auth-wrapper .logobar {
    margin-bottom: 50px;
  }

  .number-properties {
    top: 63px !important;
  }

  .filter-div {
    row-gap: 1rem;
    justify-content: space-between;
  }

  body .property-filter > .filterDiv {
    flex: 0 0 48%;
  }

  .number-properties h3 {
    font-size: 16px;
  }

  #header .header-left {
    flex-wrap: nowrap;
  }

  .Property-wrapper {
    padding-top: 100px;
  }

  .loginform .gridbar {
    grid-template-columns: 100%;
  }

  .Notifications-wapper.opacity-wapper {
    /* display: grid;
    grid-template-columns: 100%;
    gap: 20px; */
    padding: 1rem 0 !important;
  }

  .edit-profile .tabs-box-pattern {
    margin-top: 0 !important;
  }

  /* .Notifications-wapper.opacity-wapper div {
    text-align: center;
  } */

  .Notifications-wapper.opacity-wapper div a.back-part-wapper {
    right: inherit !important;
  }

  a.whatsapp-logo img {
    height: 50px;
    width: 50px;
  }

  .link-property.prime-selectmulti {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 10px;
  }

  .Notifications-wapper.opacity-wapper div br {
    display: none;
  }

  .qr-link-copi {
    max-width: 100% !important;
    display: grid;
    grid-template-columns: 100%;
    gap: 12px;
    margin: 1rem 0 0 0 !important;
  }

  .qr-link-copi button {
    font-size: 14px;
  }

  .Property-wrapper .authbar-inner .right-contentbar .resend-text {
    padding: 0 !important;
  }

  .Property-wrapper .backbtn_bar {
    margin-bottom: 10px;
  }

  .Property-wrapper .authbar-inner {
  }

  .Property-wrapper .authbar-inner .right-contentbar {
    margin: 0 !important;
  }

  .terms-bar {
    display: grid;
    text-align: center;
    gap: 5px;
  }

  .blockelement {
    display: flex !important;
    justify-content: space-between !important;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    .form-check-label {
    padding: 15px 15px !important;
    border-radius: 12px !important;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    .imgbar {
    width: 60px;
    height: 60px;
  }

  .blockelement .col-md-4 {
    width: auto !important;
  }

  .second-aisde {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .auth-form .inlinecheckboxes {
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;
  }

  .Auth-wrapper .logobar {
    position: relative;
    top: inherit;
    left: inherit;
  }

  .Auth-wrapper .logobar img:not(.company-logo),
  .logo-setting {
    height: 35px;
    width: auto;
  }

  .auth-form .btn {
    padding: 20px 0;
    line-height: 2;
  }

  .sidebarKey {
    display: none;
  }

  .form-control.uni_select .css-13cymwt-control,
  .css-t3ipsp-control {
    /* height: auto !important; */
    height: 50px !important;
    border-radius: 8px !important;
  }

  .form-control.uni_select
    .css-13cymwt-control
    .css-1wy0on6
    .css-1u9des2-indicatorSeparator {
    width: 1px;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .form-control.uni_select .css-13cymwt-control .css-hlgwow {
    padding-left: 0.8rem;
  }

  .css-qbdosj-Input {
    display: block !important;
    margin: 0 !important;
    height: 40px;
  }

  .greenbox-terms {
    padding: 30px !important;
  }

  .greenbox-terms .go-back-home {
    position: relative;
    left: inherit !important;
    margin: 0 !important;
  }

  .greenbox-terms .p-0.container {
    max-width: 100% !important;
    padding: 0 !important;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .greenbox-terms .container {
    padding: 0;
  }

  .bank-body {
    padding: 40px 20px;
  }

  .bank-body p {
    font-size: 14px;
    line-height: 20px;
  }

  .bank-body h3 {
    font-size: 20px;
    margin: 30px 0 10px 0;
  }

  .footer-img {
    gap: 20px;
  }

  .footer-img img {
    width: 90px;
  }

  .css-1dimb5e-singleValue {
    overflow: visible !important;
  }

  .hero-foot .terms-bar {
    gap: 10px;
  }

  .qr-link-copi {
    padding: 10px 0;
  }

  .Auth-wrapper-2 {
    height: 100%;
    min-height: 100dvh;
  }

  .resend-text
    form.loginform
    .auth-form
    .forminput.row50
    .form-control.user-auth.uni_select {
    padding: 0;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    form
    .linkedbtn
    .btn-fill {
    padding: 15px 5px;
  }

  .hero-foot {
    display: flex;
    align-items: flex-end;
    padding-top: 40px;
  }

  .basicbox {
    padding: 10px 12px;
  }

  .auth-form .checkboxtc input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }

  .auth-form .gridbar_block {
    grid-template-columns: 100%;
    gap: 8px;
  }

  .summary-checkes .summaryinfobar {
    padding: 0;
  }

  .auth-form label.forminput {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .border-pd .col-md-6,
  .border-pd .col-md-12 {
    padding: 0;
  }

  .auth-form .form-control {
    border-radius: 12px;
    height: auto;
    padding: 15px 10px;
    font-size: 14px;
    line-height: normal;
    min-height: 47.5px;
    line-break: anywhere;
  }

  .auth-form .btngroup {
    display: grid;
    gap: 16px;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .or-bar {
    padding: 16px 0;
  }

  .password_condition {
    margin-top: 15px;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .auth-form
    .btngroup
    .signup-text {
    margin: 0px 0 !important;
  }

  .forminput.checkboxtc {
    align-items: center;
  }

  .password-div svg.eye-icons {
    font-size: 16px;
  }

  .Auth-bar .authbar .container .authbar-inner .rightbar .right-contentbar h4 {
    font-size: 16px;
    margin: 0;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    .form-check-label
    .contentbar
    h5 {
    font-size: 16px;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel {
    margin-bottom: 20px;
  }

  .Notifications-wapper.opacity-wapper svg.infoicon {
    top: 10px;
    right: 10px;
  }

  .profilerequestbar {
    flex: 0 0 80%;
  }

  .rented-property .Notifications-wapper {
    padding: 10px !important;
  }

  .search-property .custom-input {
    padding: 15px 15px 15px 50px !important;
  }

  .rented-property .Notifications-wapper .or-wapper {
    flex: 0 0 70%;
  }

  .search-property a img {
    left: 20px !important;
  }

  .number-properties a.btn.btngreen {
    text-align: left;
    padding: 0;
  }

  .number-properties {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin-bottom: 20px;
    gap: 20px;
    margin-top: 20px;
    padding: 10px 20px;
    padding: 30px 20px 20px 20px;
  }

  .title-kyc h2 {
    padding-bottom: 20px;
  }

  .tabs-box-pattern-2 .col-lg-6.col-11.m-auto.mb-md-0 {
    padding: 0;
    width: 100%;
  }

  .userscrollprofile-topbar {
    display: flex;
    flex-direction: column-reverse;
  }

  .userscrollprofile-topbar .Shortlisted-Properties {
    justify-content: center !important;
  }

  .userscrollprofile-topbar .Shortlisted-Properties .group-btn-bar .btnshare {
    background: #ffffff;
    border: 2px solid #253837;
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;
    color: #253837;
    padding: 16px 40px;
  }

  .userscrollprofile-topbar
    .Shortlisted-Properties
    .group-btn-bar
    .btnshare.btnfill {
    color: #ffffff;
    background: #253837;
  }

  .userscrollprofile-topbar .Shortlisted-Properties .group-btn-bar a {
    margin: 0 20px !important;
  }

  .userscrollprofile-topbar .Shortlisted-Properties a {
    font-size: 20px;
  }

  .userscrollprofile-topbar .Shortlisted-Properties a img {
    display: none;
  }

  .wrapper-score {
    width: 100%;
  }

  .Notifications-wapper .notification-leftbar {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .Notifications-wapper .notification-rightbar {
    flex: 0 0 20%;
    max-width: 20%;
    text-align: right;
  }

  .Notifications-row.rented-property .Notifications-wapper div {
    text-align: left !important;
  }

  .Notifications-wapper div:nth-child(2) h6 {
    font-size: 12px !important;
  }

  .Notifications-wapper img {
    opacity: 1;
    float: none;
    width: auto;
    position: relative;
    top: inherit;
  }

  .wrapper-score .wrapper-angle {
    max-width: 30%;
    margin: 0 auto;
  }

  .content-disable h5 {
    margin: 30px 0 15px 0;
  }

  .tabs-item .row {
    display: grid;
    gap: 5px;
  }

  .tabs-item .row .header-edit {
    margin: 0 !important;
  }

  .edit-profile.row .col-6 {
    width: 100%;
  }

  .tabs-item .row h4.text-center.mt-3.mb-5 {
    margin: 0 !important;
  }

  .finacial {
    width: 100% !important;
    display: grid !important;
    gap: 16px;
    justify-content: center !important;
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  .finacial .edit-tabs {
    margin: 0;
    width: 100%;
    max-width: 100% !important;
    padding: 0 0px;
    height: 2.4rem;
  }

  .tabs-item .row {
    /* --------------------new responsive css (3brain)----------------- */
    /* margin: 0; */
    margin-bottom: 2rem;
  }

  .tabs-box-pattern-2 .row {
    margin: 0;
    padding: 0;
  }

  .left-upload-wapper {
    width: 100% !important;
  }

  .profile-mobile {
    display: block;
    text-align: center;
  }

  .profile {
    font-size: 22px;
    line-height: 100%;
  }

  .no-data-profile {
    display: none;
  }

  .tabs-profile {
    padding: 20px !important;
  }

  #left-menu ul li.services-name {
    opacity: 1 !important;
    display: none;
  }

  #left-menu ul li.services-name a {
    background: #de4730;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Score-right {
    padding-left: 0;
  }

  .Score-content-list-2 {
    margin-bottom: 20px;
    flex: 0 0 50%;
    max-width: 100%;
  }

  #left-menu ul li a span svg {
    font-size: 26px;
    margin-right: 0;
    margin-bottom: 5px;
  }

  #left-menu {
    margin: 0 !important;
    height: auto !important;
    background: #ffffff;
    border-top: 1px solid #cacaca;
    box-shadow: 0px -2px 40px rgba(0, 0, 0, 0.08);
  }

  .custom-header h5 {
    font-size: 14px !important;
  }

  #left-menu ul li {
    flex: 1;
    max-width: unset;
    text-align: center;
    background: transparent !important;
    padding: 0 !important;
    position: relative !important;
  }

  #left-menu ul li a {
    display: block;
    width: 100% !important;
    padding: 20px 10px;
    border-top: 8px solid transparent;
    cursor: pointer;
    height: 96px;
  }

  #left-menu ul li a span {
    height: auto !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.6px;
  }

  #left-menu ul li.active a {
    border-color: #253837;
  }

  #left-menu ul {
    height: auto;
  }

  #left-menu ul li a img {
    width: 30px;
    filter: none !important;
    opacity: 1 !important;
    margin: 0 !important;
  }

  .shareQr .btngreen {
    width: 100%;
  }

  .shadow.mainwraper {
    padding: 90px 20px 60px 20px;
    margin-top: 0px;
  }

  .connectbankmodal .bankimagediv {
    display: none;
  }

  .didgital_step_form {
    gap: 10px;
  }

  .didgitally-bars .vreify-box {
    padding: 16px;
  }

  .didgitally-bars .vreify-box .verify-detail {
    gap: 10px;
  }

  .password-div svg.eye-icons {
    right: 10px;
  }

  #left-menu ul li.active a span {
    color: #253837 !important;
  }

  .profile-page.marless .Search-bar {
    width: 100%;
  }

  .profile-page.marless {
    /* padding-left: 10px !important; */
    margin-left: 0;
    /* padding-right: 10px !important; */
    gap: 20px;
    margin-bottom: 0px;
  }

  .tabs-box-pattern-3 {
    margin-top: 8rem !important;
  }

  .tabs-box-pattern {
    /* --------------------new responsive css (3brain)----------------- */
    /* margin-top: 4.6rem !important; */
    margin-top: 1rem !important;
    padding: 10px !important;
  }

  .tabs-box-pattern.mobile {
    margin-top: 0 !important;
  }

  .tabs-box-pattern.mobile .tabs-item {
    padding-top: 0 !important;
  }

  .margin-div {
    margin-top: 92px !important;
    padding: 10px !important;
    margin-bottom: 5rem !important;
  }

  .primaryaccordion .primaryaccordion-body .tabs-box-pattern {
    margin-top: 10px !important;
  }

  .edit-tabs {
    max-width: 70% !important;
    /* height: 56px; */
    background: #fafafa;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-Score {
    width: 100%;
    display: block !important;
  }

  .properties-box .flex-row h4 {
    margin: 0 !important;
  }

  .properties-box {
    background: #ffffff;
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border: 0.6px solid #dddddd;
    box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    /* margin-bottom: 20px; */
  }

  #logo {
    display: none !important;
  }

  #header .header-left {
    padding-left: 0px;
    padding: 10px 20px !important;
  }

  .custom-header {
    display: flex;
    height: auto !important;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    flex-wrap: wrap;
  }

  #header {
    height: auto !important;
  }

  .btn-fill2 {
    margin-bottom: 20px !important;
    margin-top: 0;
  }

  .custom-input {
    padding-left: 10px !important;
  }

  .info-wapper .react-tel-input .form-control {
    font-size: 0.8rem !important;
    border-radius: 0.3rem;
    padding-left: 2rem !important;
  }

  .info-wapper .react-tel-input .selected-flag {
    width: 1.5rem;
    border-radius: 0.2vw 0 0 0.2vw;
  }

  .info-wapper .react-tel-input .selected-flag .arrow {
    /* margin-top: -0.133vw; */
    left: 0.5rem;
    border-left: 0.17rem solid transparent;
    border-right: 0.17rem solid transparent;
    border-top: 0.17rem solid #555;
  }

  .info-wapper .react-tel-input .flag-dropdown {
  }

  .info-wapper .react-tel-input .flag-dropdown .country-list {
    margin: 0.4rem 0 0.2rem;
    box-shadow: 0.037rem 0.111rem 0.469rem rgba(0, 0, 0, 0.35);
    width: 15rem;
    max-height: 6.5rem;
    border-radius: 0 0 0.1rem 0.1rem;
  }

  .info-wapper .react-tel-input .selected-flag .arrow.up {
    border-bottom: 2.7px solid #555;
  }

  .info-wapper .react-tel-input .flag-dropdown .country-list .country {
    padding: 0.1rem 0.4rem;
    font-size: 0.8rem !important;
  }

  .info-wapper .react-tel-input .flag-dropdown .country-list .country .flag {
    margin-right: 0.4rem;
    margin-top: 0.1rem;
    width: 0.8rem;
    height: 0.5rem;
  }

  span.property_condition {
    font-size: 0.7rem;
    margin: 0.3rem;
  }

  .verify-wrap p {
    font-size: 0.7rem;
    margin: 0.4rem 0 0.4rem 0;
    gap: 0.3rem;
  }

  .verify-wrap p img {
    width: 0.5rem;
    height: 0.5rem;
  }

  .verificationbtn .btn-bro {
    font-size: 14px;
    gap: 0.4rem;
    padding: 17px 18px !important;
    border-radius: 0.4rem;
  }

  .verificationbtn .connect-bank svg {
    /* font-size: 18px; */
    font-size: 0.8rem;
  }

  .basicbox h3.Property {
    margin-bottom: 0.9rem;
  }

  .select-radio .form-check .form-check-label {
    font-size: 0.8rem;
  }

  .select-radio .form-check {
    padding: 0 !important;
    /* margin: 0 10px 0 0 !important; */
    margin: 0 0.2 0 0.2 !important;
  }

  .shareQr {
    width: 100%;
  }

  .tabs-item {
    margin: 10px !important;
  }

  .tabs-item {
    background: #ffffff !important;
    padding: 10px !important;
    margin-bottom: 25px;

    border: none !important;
    box-shadow: none !important;
    border-radius: 8px;
    padding-left: 20px !important;
    padding: 20px !important;
  }

  #left-menu ul li a span {
    width: 100%;
    display: flex;
    align-items: center !important;
    flex-direction: column !important;
    color: #cacaca !important;
  }

  #left-menu ul li a.active span {
  }

  #left-menu ul {
    padding: 0;
    margin: 0;
    display: flex !important;
    align-items: center !important;
  }

  #left-menu {
    top: auto !important;
    bottom: 0px !important;
    width: 100% !important;
    background-color: #ffffff !important;
  }

  .header-edit {
    position: relative;
    text-align: center !important;
  }

  .right-contentbar {
    max-width: 362px;
    margin: 51px auto !important;
    text-align: center;
  }

  .Auth-bar .authbar .container .authbar-inner .leftbar,
  .Auth-bar .authbar .container .authbar-inner .rightbar {
    flex: 0 0 100%;
    max-width: 1000%;
    padding: 0 0px;
    padding-left: 0 !important;
    margin-top: auto;
  }

  .checkboxbar {
    margin-bottom: 24px !important;
  }

  .Auth-bar .authbar .container .authbar-inner {
    display: block !important;
  }

  .leftbar {
    display: block !important;
  }

  .logobarwrapper .logobar .container .go-back-home {
    position: relative;
    margin: 0 !important;
    left: inherit !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .logobarwrapper .logobar .container {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .logobarwrapper .logobar .container .go-back-home svg {
    width: 30px;
    height: 30px;
  }

  .right-contentbar {
    max-width: 100%;
    margin: 60px 0 40px 0 !important;
  }

  .Auth-wrapper-2 .authbar-inner {
    padding: 0;
  }

  .Auth-wrapper-2 .logobar .container {
    flex-direction: column-reverse;
    padding: 0 0px !important;
  }

  .authbar-inner {
    width: 100%;
  }

  .setNoInfoColor {
    font-weight: 500;
    display: inline-flex !important;
  }

  .auth-form label.forminput {
    width: 100%;
  }

  .Auth-wrapper-2 .logobar .container .go-back-home {
    position: relative;
    left: inherit;
    margin: 0 !important;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .Auth-wrapper-2 .logobar .container img {
    margin-bottom: 20px;
  }

  .resend-text .otp-Verification .forminput input[type="tel"] {
    width: 44px !important;
    height: 55px !important;
  }

  .resend-text {
    max-width: 100%;
    padding: 20px 15px !important;
  }

  .resend-text .otp-Verification .forminput {
    margin: 0;
    gap: 6px;
  }

  .resend-text h4 {
    font-size: 18px !important;
  }

  .input-dropdown-inline {
    display: grid !important;
    gap: 10px;
    grid-template-columns: 100%;
  }

  .resend-text form.loginform .auth-form {
    margin: 30px 0 0 0 !important;
  }

  .logobarwrapper ul.progress-ul {
    margin: 40px 0 20px 0;
  }

  .auth-form .row100 {
    /* padding: 0 1rem; */
    gap: 0.5rem;
  }

  .resend-text form.loginform .auth-form .forminput.row50 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }

  .resend-text form.loginform .auth-form .btngroup.row100 {
    padding: 0;
  }

  .logobarwrapper .logobar .container .go-back-home span {
    font-size: 14px;
  }

  .Auth-bar .authbar .container .authbar-inner {
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0px;
  }

  .leftbar {
    display: flex !important;
    justify-content: center !important;
  }

  .leftbar h2 {
    font-size: 28px !important;
    line-height: 34px !important;
  }

  .logo-img {
    display: block;
    margin: auto;
    width: auto;
  }

  .row.basicbox.orangebox {
    display: block;
  }

  .stepformauthbar-inner {
    padding: 0 15px;
  }

  .Auth-wrapper {
    min-height: 100dvh;
    background-color: var(--base-primary);
    overflow: scroll;
  }

  .otp-content {
    text-align: center !important;
  }

  .text-white {
    font-size: 14px !important;
  }

  .sign-wapper {
    /* width: 362px; */
    max-width: 362px;
    height: 111px;
    /* padding: 37px 0px; */
    border: 1px solid var(--text-white);
    border-radius: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 13px !important;
  }

  .link-bar,
  .terms-bar a {
    font-size: 16px !important;
  }

  .list-info-wapper {
    display: none !important;
  }

  .right-content-width {
    width: 100% !important;
    padding-left: 0px !important;
    border: none !important;
  }

  .btn-fill2 {
    float: none !important;
    display: block !important;
    width: 100% !important;
    margin-top: 1 0px !important;
  }

  .right-content-info p {
    font-size: 16px;
    color: #797979;
    font-family: "Google Sans";
    margin-bottom: 10px;
    text-align: center !important;
  }

  .flex-wapper.flex-wapper2 {
    display: flex !important;
    justify-content: baseline !important;

    flex-wrap: wrap !important;
  }

  .left-upload-wapper.left-upload-wapper2 {
    width: 100%;
    padding-left: 0px !important;
  }

  .process-bar-wapper {
    width: 100% !important;
    text-align: center;
    height: auto !important;
    display: flex;
    align-items: center !important;
    justify-content: center;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .Score-right.home-section {
    width: 100% !important;

    background: #ffffff;
    box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 28px;
  }

  .select-radio {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .Shortlisted-Properties {
    display: flex;
    justify-content: space-between !important;
    margin-top: 30px;
  }

  .Score-left {
    width: 100% !important;
    float: none !important;
    text-align: center !important;
    margin: 0 auto !important;
  }

  .Score-right {
    width: 92% !important;
    float: none !important;
    margin: 0 auto !important;
  }

  .head-set h3 {
    font-family: "Google Sans";
    font-size: 16px;
    line-height: 19px;
    color: #212121;
    margin-bottom: 24px;
    text-align: center !important;
  }

  .buy-section {
    background: #ffffff;
    box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px !important;
  }

  button.btn-fill-border {
    display: block !important;
    width: 100% !important;
    margin: auto !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    height: 2rem;
    border-radius: 0.75rem;
    font-size: 0.8rem !important;
  }

  /* --------------------new responsive css (3brain)----------------- */
  .space-custom {
    /* padding: 25px !important; */
    padding: 5px 25px !important;
  }

  .admin-name2 {
    width: 4.495rem;
    height: 2.495rem;
    line-height: 3.933rem;
    font-size: 1.127rem;
  }

  .header-edit h3 {
    font-size: 1.175rem;
    line-height: 2;
    margin-bottom: 0;
  }

  .user-name {
    font-size: 1.055rem;
  }

  .edit-line h4 {
    font-size: 16px;
    line-height: 1.05rem;
  }

  .edit-line h3 {
    font-size: 14px;
    line-height: 1.02rem;
  }

  .reward-points.mobile {
    font-size: 18px;
  }

  .button-gap {
    margin: 0.5rem 0 !important;
  }

  .reward-head.mobile span {
    font-size: 18px !important;
  }

  .reward-head.mobile svg {
    width: 22px;
    height: 20px;
  }

  .employment-type-spacing label {
    margin-top: 1rem;
  }

  .employment-type-spacing div {
    height: 40px !important;
  }

  /* -------------------- end new responsive css (3brain)----------------- */

  .Auth-bar .authbar .container .authbar-inner .leftbar {
    border: none !important;
  }

  .Auth-bar .authbar .container .authbar-inner .rightbar .right-contentbar {
    max-width: 100%;
    padding: 20px 20px;
    margin: 0 !important;
  }

  .Auth-bar .authbar .container a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    .form-check-label
    img {
    width: 40px !important;
    height: 40px;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    input[type="checkbox"]:checked
    ~ label.form-check-label
    .contentbar
    h5 {
    margin: 0;
    padding-inline-start: 8px;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .btngroup
    .btn.btnorange {
    font-size: 16px !important;
  }

  .Auth-bar {
    height: 100vh !important;
    justify-content: center;
  }

  .Auth-bar .authbar .container .authbar-inner .leftbar img {
    display: none;
  }

  .Auth-bar .authbar .container .authbar-inner .rightbar .auth-shapetopright {
    display: none;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .btngroup
    .btn.btnoutline {
    padding: 20px 0;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .btngroup
    .btn.btnorange {
    padding: 20px 0;
  }

  .hero-foot .terms-bar {
    flex-direction: column;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    input[type="checkbox"]:checked
    ~ label.form-check-label {
    padding: 15px 5px;
  }

  .heading_lng_div {
    align-items: center;
    margin-bottom: 30px;
  }

  .Auth-bar .authbar .container {
    max-width: 100% !important;
    padding: 0 20px;
    width: 100% !important;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    input[type="checkbox"]:checked
    ~ label.form-check-label
    img {
    width: 40px;
    height: 40px;
  }

  .tab-with-editbtn .editbar {
    position: unset;
  }

  .basicbox .col-12 {
    padding: 0;
  }

  .flatmate_div_0.row {
    padding: 0;
  }

  .form-control.uni_select {
    border: none;
    padding: 0;
    /* margin-bottom: 15px; */
  }

  label.flatmate-title {
    margin-top: 15px;
  }

  .flatmate-rybar {
  }

  .middle-step::before {
    display: none;
  }

  .middle-step::after {
    display: none;
  }

  .getsetup-body h1 {
    padding-bottom: 20px;
    font-size: 20px;
  }

  .five-steps {
    margin-bottom: 20px;
    gap: 20px;
  }

  .Score-content-list h4 {
    font-size: 1.1rem !important;
  }

  .Score-content-list h3 {
    font-size: 1rem;
  }

  .summaryinfobar img,
  .Score-content-list img {
    width: 1rem;
    height: 1rem;
  }

  .rounded-check {
    font-size: 0.8rem;
  }

  .newmodalui.connectbankmodal .modal-dialog {
    max-width: 100% !important;
  }

  .connectbankdiv {
    padding: 1.5rem !important;
  }

  .connectbankdiv h3 {
    font-size: 1.3rem;
  }

  .newmodalui .modal-body .auth-form .forminput label.forminput {
    font-size: 1rem;
  }

  .newmodalui .modal-body .auth-form .forminput input,
  .newmodalui .modal-body .auth-form .forminput select {
    font-size: 1rem !important;
    line-height: normal;
    padding: 1rem;
    height: auto;
  }

  .newmodalui .modal-body .auth-form .forminput .bankname {
    font-size: 1rem;
    padding: 16px 0;
    /* margin-top: 10px; */
  }

  .bank-btm {
    font-size: 0.8rem;
  }

  .card-listing .card-body .listing-head h5 {
    font-size: 1rem;
  }

  .card-listing .card-body .listing-head p {
    font-size: 0.8rem;
  }

  .card-listing .card-body .head-block svg {
    flex: 0 0 1rem;
    font-size: 1rem;
  }

  .card-listing .videoframe {
    height: auto;
  }

  .tenant-home-documents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
  }

  .tenant-home-documents .card {
    margin-bottom: 0;
    border-radius: 12px;
  }

  .tenant-home-documents .videoframe {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  .tenant-home-documents img.upload_doc_icons {
    position: absolute;
    width: 5rem;
    height: 3rem;
    top: 0.37vw;
    left: 0.37vw;
  }

  .tenant-home-documents .card-body {
    padding: 0.5rem;
  }

  .tenant-home-documents .head-block .view-button {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a3ea64;
  }

  .tenant-home-documents .head-block svg {
    flex: unset;
    font-size: 1.3rem;
  }

  /* --------------------new responsive css (3brain)----------------- */
  .edittitle h4 {
    font-size: 1.125rem;
  }

  .edittitle svg {
    height: 2em;
    width: 2em;
  }

  .btngroupbar button {
    padding: 0.909rem 2.276rem;
    font-size: 0.89rem;
  }

  .Property {
    font-size: 1.131rem;
    margin-bottom: 1.5rem;
  }

  .info-wapper label {
    font-size: 14px !important;
    line-height: 1.015rem;
    margin-bottom: 0.413rem;
  }

  .edit-tabs .nav-item .nav-link {
    font-size: 0.7rem;
    height: 2rem;
  }

  .edit-line span img {
    width: 0.6rem;
    height: 0.6rem;
  }

  .change-password-btn svg {
    height: 0.8rem;
    width: 0.8rem;
  }

  body ul.infodorpdown li button.iconinfo svg {
    height: 24px;
    width: 24px;
  }

  .step-complete-reward .rewards-points {
    margin-top: 1rem;
    padding: 18px;
    font-size: 50px;
    width: 80%;
    margin: auto;
  }

  .step-complete-reward .greeting-text {
    font-size: 24px;
  }

  .common-gap {
    margin-bottom: 1rem !important;
  }

  .special-gap {
    margin-bottom: 1.7rem !important;
  }

  .btn.skip-btn {
    margin-top: 0;
  }

  .step-form-main-wrapper .steps-wrapper .orangetext {
    margin-top: 12px;
    margin-bottom: 1.7rem;
  }
}

@media (max-width: 574px) {
  .mobile-blockbookpayment {
    display: block !important;
  }

  .mobile-blockbookpayment .rytblock {
    justify-content: start !important;
    margin-top: 10px;
  }

  .infodorpdown .tooltip {
    width: 300px;
    padding: 15px;
  }

  .auth-email-div-label {
    font-size: 12px;
  }

  p.auth-email-div-p {
    font-size: 14px;
    line-break: anywhere;
  }

  .otp-Verification .otp-heading {
    font-size: 16px;
  }

  .right-contentbar {
    margin: 45px 0 40px 0 !important;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .checkboxbar
    .uilabel
    input[type="checkbox"]:checked
    ~ label.form-check-label {
    padding: 15px 5px;
  }

  .filter-div .filterDiv {
    flex: 0 0 100%;
    padding: 0;
    margin: 8px 0;
  }

  .modal-cancel-btn,
  .modal-submit-btn {
    padding: 14px 50px;
  }

  .basicbox .col-12 {
    padding: 0;
  }

  .flatmate_div_0.row {
    padding: 0;
  }

  .flatmate-rybar {
  }

  .tabs-box-pattern {
    margin-top: 0rem !important;
    padding: 10px !important;
  }

  .heading_lng_div {
    align-items: center;
    margin-bottom: 30px;
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .btngroup
    .btn.btnorange {
    padding: 20px 0;
    margin-top: 10px !important;
  }

  .hero-foot .terms-bar {
    flex-direction: column;
  }

  .Auth-bar {
    height: auto !important;
    justify-content: center;
    padding: 20px 0;
  }

  .Auth-bar .authbar .container .authbar-inner .rightbar .auth-shapetopright {
  }

  .Auth-bar
    .authbar
    .container
    .authbar-inner
    .rightbar
    .right-contentbar
    .btngroup
    .btn.btnoutline {
    padding: 20px 0;
  }

  .btngroupbar button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .rented-property .Notifications-wapper .or-wapper {
    flex: 0 0 100%;
  }

  .rented-property .Notifications-wapper .or-wapper h6 {
    font-size: 14px !important;
    line-height: 1 !important;
    margin-top: 10px;
  }

  .rented-property .Notifications-wapper .or-wapper br {
    display: none;
  }

  .userscrollprofile-topbar .Shortlisted-Properties .group-btn-bar .btnshare {
    background: #ffffff;
    border: 2px solid #253837;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #253837;
    padding: 14px 16px;
  }

  .userscrollprofile-topbar .Shortlisted-Properties .group-btn-bar a {
    margin: 0 5px !important;
  }

  #left-menu ul li a {
    padding: 10px 5px !important;
    display: flex;
    align-items: center;
    height: 75px;
    border-width: 5px;
  }

  #left-menu ul li a span svg {
    font-size: 18px;
  }

  #left-menu ul li a span {
    font-size: 12px !important;
  }

  body {
    background-color: var(--text-white) !important;
  }

  .Landlord-details {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .Landlord-details .col-sm-6.col-12 .edit-line {
    border-bottom: 0.5px solid #dddddd;
    padding-bottom: 16px !important;
    margin-bottom: 22px !important;
  }

  .Score-content div {
    flex: 0 0 48%;
  }

  .edit-tabs.profile-edit .nav-item .nav-link {
    height: 44px;
    max-width: 157px !important;
    font-size: 12px !important;
  }

  .wappper-location {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    flex-wrap: wrap !important;
  }

  .sign-wapper {
    /* width: 362px; */
    max-width: 276px;
    height: 111px;
    /* padding: 37px 0px; */
    border: 1px solid var(--text-white);
    border-radius: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 37px !important; */
    margin: auto;
  }

  .link-bar,
  .signup-text {
    font-size: 13px !important;
  }

  .inlinecheckbar label {
    font-weight: 500;
    font-size: 18px !important;
    line-height: 22px;
    vertical-align: super !important;
    padding: 15px 5px !important;
  }

  button.btn-fill-border {
    width: 220px;
    height: 52px !important;
    background: var(--text-white);
    border-radius: 12px;
    color: var(--base-primary);
    float: left !important;
    margin-top: 30px;
    margin-right: 20px;
    border: 1px solid var(--base-primary);
  }

  #main-content {
    padding: 0px !important;
  }

  .tabs-item {
    margin: 0px !important;
    padding: 0px !important;
  }

  .Score-right.home-section {
    padding: 15px !important;
  }

  .Property {
    font-size: 16px;
    line-height: 19px;
    color: #212121;
    font-family: "Google Sans";
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: left !important;
  }

  .share-it-profile {
    color: red;
    text-align: center;
    width: 100%;
    text-decoration: underline;
    padding: 0px !important;
  }

  .share-it-profile a {
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .edit-tabs .nav-item .nav-link {
    border-radius: 8px;
    /* width: 126px !important; */
    /* height: 45px; */
    /* margin-top: 20px; */
    color: #253837;
    font-family: "Google Sans";
  }

  .admin-name2 {
    /* width: 80px !important; */
    /* height: 80px !important; */
    /* background: #fff3f0; */
    border-radius: 50%;
    margin: 0 auto;
    /* font-size: 19px !important; */
    /* line-height: 67px !important; */
  }

  .edit-tabs {
    max-width: 80% !important;
  }

  .space-custom {
    padding: 15px !important;
    margin-bottom: 15px !important;
  }

  .Notifications-wapper.bg-wapper {
    padding: 24px 20px !important;
  }

  .wappper-location {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
  }

  .rental-property {
    width: 100% !important;
  }

  .header-edit span {
    position: absolute;
    left: 32px !important;
    top: -5px !important;
  }


  .Notifications-wapper {
    background: #fff;
    border-bottom: 1px solid #ddd6;
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
    padding: 25px;
    align-items: stretch;
    /* flex-wrap: wrap !important; */
  }

  .orange-wappper {
    box-shadow: none !important;
  }

  .or-wapper,
  .end-lease {
    width: 100% !important;
  }

  .end-lease {
    width: 100% !important;
    text-align: center !important;
  }

  tab-content > .active {
    padding: 10px !important;
  }

  .profilerequestbar {
    flex: 0 0 65%;
  }

  .row.edittitle {
    top: 90px;
  }

  .verificationbtn .connect-bank {
    padding: 15px;
    width: 100%;
  }

  /* .tabs-item .row {
    margin-bottom: 20px;
  } */

  .review-form-body {
    padding: 20px;
  }

  .cards-wrapper {
    justify-content: center;
  }

  .step-form-main-wrapper .steps-wrapper {
    margin: 0;
    max-width: 100%;
    margin-top: 15px;
  }

  .icon-text-box > img {
    width: 36px;
    height: 36px;
  }

  .step-absolute-header .container > div {
    flex-direction: column;
    gap: 15px;
  }

  .logobarwrapper ul.progress-ul {
    gap: 5px;
  }

  .tenant-info-wrapper {
    flex-direction: column;
  }

  .tenant-info-wrapper .reward-points-wrapper {
    margin-top: 15px;
    width: 100%;
  }

  .step-form-header > div {
    flex-direction: column;
    gap: 15px;
  }

  .step-form-main-wrapper:has(.step-complete-reward) .step-form-header > div {
    /* justify-content: center !important; */
    margin-right: 0;
  }

  .step-form-main-wrapper:has(.step-complete-reward)
    .step-form-header
    > div
    .back-btn-mobile {
    margin-bottom: 0;
  }

  .step-form-main-wrapper:has(.step-complete-reward)
    .step-form-header
    .step-name {
    margin-bottom: 20px;
  }

  .skore-dummmy {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }

  /* --------------------new responsive css (3brain)----------------- */
  .annual_income_tooltip .infodorpdown ul.tooltip {
    width: 55vw;
  }

  .annual_income_tooltip .infodorpdown ul.tooltip li p {
    font-size: 0.7rem;
  }

  .step-form-header .back-btn-mobile {
    align-self: flex-start;
  }

  img.upload_doc_icons {
    width: 4rem;
    height: 4rem;
  }

  body .btn,
  .step-complete-reward .step-go-home-btn {
    padding: 10px 26px;
    border-radius: 11.995px;
    font-size: 14px !important;
    line-height: 22.005px;
  }

  .share_landlord_info > svg {
    translate: 0 0;
    align-self: flex-start;
  }

  .share_landlord_info {
    margin-left: 0;
  }

  button.btn-fill-border.change-password-btn {
    border-radius: 0.5rem;
  }

  /* --------------------end new responsive css (3brain)----------------- */

  .step-form-main-wrapper .step-absolute-header {
    /* --------------------new responsive css (3brain)----------------- */
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 1rem;
  }

  .step-form-main-wrapper .step-absolute-header .step-form-name {
    justify-content: center;
    font-size: calc(1.3rem + 0.6vw);
  }

  .set-width-for-mob {
    max-width: 50%;
  }

  .step-form-main-wrapper .step-absolute-header .step-form-name > svg {
    width: 40px;
    height: 40px;
  }

  .skore-details .skore-details-content .skore-details-points {
    width: 100%;
    justify-content: center;
    padding: 12px 27.994px;
  }

  .pending-steps-wrapper.already-renting .tiles-main-heading {
    margin-bottom: 0;
  }

  .pending-steps-wrapper .tile-design .tile-btns-wrapper .incomplete-btn,
  .pending-steps-wrapper .tile-design .tile-btns-wrapper .completed-btn {
    font-size: 13.997px;
    padding: 14.5px 18.994px;
    min-width: 200.002px;
    border-radius: 11.995px;
  }

  .step-form-main-wrapper .step-absolute-header .back-btn {
    position: static;
    margin: 0;
  }

  .skore-details .skore-image-wrapper {
    border: none;
  }

  .skore-details .skore-details-content {
    padding: 0;
  }

  .step-form-main-wrapper .step-name {
    font-size: calc(1rem + 0.6vw);
    /* margin-bottom: 0 !important; */
  }

  .steps-wrapper .step-name {
    margin-bottom: 25px;
  }

  .two-items-row {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  .two-items-row .customradiobar {
    margin-bottom: 0;
  }

  .step-form-main-wrapper {
    padding: 15px;
  }

  .tenant-info-wrapper .reward-points-wrapper .reward-head {
    justify-content: center;
  }

  .tenant-info-wrapper .tenant-info .tenant-details,
  .tenant-info-wrapper .tenant-info .tenant-details .tenant-status {
    width: 100%;
  }

  .tenant-info-wrapper .tenant-contact {
    flex-direction: column;
  }

  .pending-steps-wrapper .row {
    flex-direction: column;
    gap: 15px;
  }

  .progress-bar-wrapper .progress-score {
    min-width: unset;
    padding: 15px;
  }

  .tile-design {
    flex-direction: column;
    gap: 15px;
  }

  .tile-design .tile-btns-wrapper {
    flex-direction: column;
    gap: 15px !important;
    width: 100%;
  }

  .tile-design .tile-btns-wrapper > * {
    width: 100%;
  }

  .tile-design .tile-btns-wrapper > .tile-points {
    justify-content: center;
  }

  .margin-tenant .margin-tenant-div {
    padding: 10px !important;
  }

  .tenant-info-wrapper .tenant-info .tenant-details .tenant-name {
    text-align: center;
  }

  .tenant-info-wrapper .tenant-info {
    width: 100%;
    flex-direction: column;
  }

  #sub-header-links .sub-head-links-wrapper {
    width: 100%;
    justify-content: space-between;
  }

  div.modal-dialog {
    max-width: 100%;
  }

  #sub-header-links .sub-link a {
    padding: 8px 8px;
    font-size: 0.9rem !important;
  }

  #sub-header-links .sub-link:not(:last-child) a:after {
    height: 40%;
  }

  #sub-header-links .sub-link.active a:after {
    display: block;
    right: -8px;
  }

  #header .header-left {
    padding: 10px 14px !important;
  }

  .second-aisde a {
    display: flex;
  }

  #header .header-left .firstaside {
    margin-bottom: 0;
    margin-inline-end: 4px;
    flex: 0 0 30%;
    width: 30%;
  }

  #header .header-left .firstaside h5 {
    max-width: 100%;
    white-space: pre-wrap;
  }

  .badge + img {
    height: 25.003px;
    width: 22px;
  }

  body .badge {
    height: 16.002px;
    width: 16.002px;
    top: -8.995px;
    left: 11.995px;
  }

  body #sub-header-links {
    margin-top: 76.005px !important;
  }

  ul.infodorpdown li button.iconinfo svg {
    height: 0.9rem;
    width: 0.9rem;
  }
}

@media (max-width: 490px) {
  .tenant-review-content {
    padding: 20px;
    text-align: center;
  }

  .edittitle h4 {
    font-size: 18px;
  }

  .btngroupbar button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .edit-tabs .nav-item .nav-link {
    max-width: 140px !important;
    font-size: 12px !important;
  }

  .edit-tabs .nav-item .nav-link.active {
    background: #253837 !important;
    color: #fff;
    font-family: "Google Sans";
    font-size: 12px !important;
  }

  .tabs-profile {
    padding: 10px !important;
  }

  .process-bar-wapper {
    align-items: flex-start !important;
    margin-bottom: 20px !important;
  }

  .content-disable button {
    width: 100% !important;
    display: block;
  }

  .card-items-style {
    font-size: 14px !important;
  }

  .card-error {
    color: red;
  }

  .admin-name {
    width: 42px !important;
    height: 43px !important;
    /* background: #fff3f0; */
    border-radius: 50%;
    text-align: center;
    margin-right: 0px !important;
    line-height: 41px !important;
    margin-bottom: 0px !important;
  }

  .custom-header h5 {
    font-size: 14px !important;
  }

  .buy-section {
    margin-bottom: 20px !important;
  }

  #left-menu > ul > li {
    font-size: 14px !important;
  }

  .profile-page {
    display: flex;
    justify-content: space-between;
    margin-left: 0px !important;
  }

  a {
    cursor: pointer;
    color: var(--base-primary);
    font-size: 15px !important;
    text-decoration: none;
  }

  .admin-name2 a {
    font-size: 17px !important;
  }

  .edit-tabs.profile-edit .nav-item .nav-link {
    height: 44px;
    width: 100% !important;
    font-size: 12px !important;
  }

  .Notifications-wapper.opacity-wapper h6 {
    font-size: 12px !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    line-height: 30px !important;
  }

  .Notifications-wapper p {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    line-height: 20px !important;
    /* --------------------new responsive css (3brain)----------------- */
    margin-left: 0px !important;
    font-size: 13px !important;
  }

  .Score-content {
    margin-top: 20px;
    margin-bottom: 25px !important;
    padding: 0 !important;
  }

  .btn {
    font-size: 14px !important;
  }

  .header-edit span {
    position: absolute;
    left: 25px !important;
    top: -8px !important;
  }

  .edit-tabs .nav-item .nav-link {
    max-width: 100% !important;
  }

  .tab-with-editbtn .editbar {
    padding: 0 0px;
    gap: 0.4rem;
  }

  .select-radio .form-check:last-child {
    margin-left: 0px !important;
  }

  .wrapper-score {
    width: 100% !important;
  }

  .wrapper-score .wrapper-angle {
    max-width: 70%;
    margin: 0 auto;
  }

  .wrapper-angle {
    width: 91% !important;
    display: block;
    margin: auto;
  }

  .Score-content h3 {
    font-size: 14px !important;
  }

  .Score-content h4 {
    font-size: 12px !important;
  }

  .content-disable h5 {
    margin: 10px 0px 10px 0px !important;
  }

  .edit-tabs {
    max-width: 90% !important;
  }

  .Notifications-wapper h6 {
    font-size: 0.78rem !important;
    line-height: 0.94rem;
    font-family: "Google Sans" !important;
  }

  .tbtn-st {
    margin-bottom: 15px;
  }

  .tenant-review {
    padding: 0 10px;
  }

  .tenant-review {
    padding: 0 10px;
  }

  .Search-bar.secondrysearchbar {
    width: 100%;
  }

  .Search-bar.secondrysearchbar .custom-input {
    width: 100% !important;
    padding-left: 30px !important;
  }

  .paymentdeailbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .middlestep::before {
    display: none;
  }

  .btn-up {
    top: 0;
  }

  .reviewandconform {
    padding: 20px;
  }

  .payment-body p:last-child {
    margin-bottom: 20px;
  }

  .payment-detail-content {
    padding: 10px;
    margin-bottom: 30px;
  }

  .payment-detail-content p span {
    padding-left: 0;
    font-size: 13px;
  }

  .mobile-custom {
    flex-wrap: wrap;
  }

  .upcoming-side {
    width: 100%;
  }

  .current-side {
    width: 100%;
  }

  .detail-body {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .mobile-full-btn {
    flex-direction: column;
    gap: 0 !important;
  }

  .mobile-full-btn button {
    width: 100%;
    text-align: center;
  }

  /* --------------------new responsive css (3brain)----------------- */
  .Auth-bar .authbar .container .authbar-inner .rightbar {
    /* margin-top: 30%; */
  }
}

@media (max-width: 400px) {
  .landloard-payment-box {
    padding: 0 10px !important;
  }

  .tbtn-st {
    margin-bottom: 15px;
  }

  .Notifications-wapper p {
    line-height: 2 !important;
    /* font-size: 12px !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    line-height: 20px !important; */
  }

  .Notifications-wapper-2.opacity-wapper h6 {
    font-family: "Google Sans";
    font-size: 12px !important;
  }

  /* --------------------new responsive css (3brain)----------------- */
  .annual_income_tooltip .infodorpdown ul.tooltip {
    width: 52vw;
  }

  .annual_income_tooltip .infodorpdown ul.tooltip li p {
    font-size: 0.8rem;
  }

  .Notifications-wapper h6 {
    font-size: 0.78rem;
    line-height: 0.94rem;
  }

  .header-edit span {
    position: absolute;
    left: 20px !important;
    top: -5px !important;
  }

  .orange-wappper {
    padding: 10px !important;
  }

  .edit-tabs {
    max-width: 95% !important;
  }

  .Notifications-wapper.bg-wapper {
    padding: 23px 8px !important;
  }

  .edit-tabs .nav-item .nav-link {
    max-width: 114px !important;
    margin: 0 auto !important;
  }

  .getsetup-body h1 {
    padding-bottom: 20px;
  }

  .five-steps {
    margin-bottom: 20px;
    gap: 20px;
  }

  .rental-datails h2 {
    color: #223635;
    text-align: right;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .rental-datails h6 {
    color: var(--Grey, #7f7f7f);
    text-align: center;
    font-family: "Google Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }

  .details-form label {
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin-bottom: 4px;
  }

  .details-form label span {
    color: var(--Orange, #ef7833);
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .details-form input {
    border-radius: 12px;
    border: 1px solid var(--Grey, #7f7f7f);
    background: #fff;
    padding: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  .form-check.radio-check {
    padding: 0;
  }

  .radio-check label {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .radio-check input {
    margin: 0 !important;
  }

  .check-radio {
    display: flex;
    gap: 183px;
    margin-top: 18px;
  }

  .details-form input::placeholder {
    color: var(--Grey, #7f7f7f);
    font-family: "Google Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .alert-text {
    font-size: 16px;
    margin: 20px 0;
  }

  .review-form {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    padding-top: 40px;
  }

  .review-form h2 {
    color: #223635;
    text-align: right;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .review-form-body {
    padding: 20px;
  }

  .body-inner-sec {
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 20px;
  }

  .body-inner-sec h6 {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .body-inner-sec p {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .body-inner-sec p:last-child {
    margin-bottom: 24px;
  }

  .tbtn-st {
    padding: 16px 40px;
    border-radius: 12px;
    border: 2px solid #a3eb64;
    background: transparent;
    width: 100%;
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 15px;
  }

  .tenant-review {
    padding: 0 50px;
  }

  .tenant-review
    .primaryaccordion
    .primaryaccordion-header
    .accordion-button
    .maintitle {
    color: #223637;
    text-align: right;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .close-btn {
    position: absolute;
    right: 17px;
    top: 13px;
  }

  .review-content h4 {
    color: #223637;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .review-content p {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .detail-page-heading {
    color: #223635;
    text-align: center;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .back-header {
    display: flex !important;
    align-items: center !important;
    gap: 16px !important;
    margin-bottom: 10px;
  }

  .back-header .detail-page-heading {
    margin-bottom: 0;
    font-size: 20px;
  }

  .payment-text {
    color: #223637;
    font-family: "Google Sans";
    font-size: 1.806vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px !important;
  }

  .upcoming-box {
    border-radius: 8px;
    border: 1px solid #dddddd;
    background: #ffffff;
    padding: 16px 24px 32px;
    margin-bottom: 19px;
  }

  .upcoming-text {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .upcoming-side {
    width: 65%;
  }

  .upcoming-table {
    width: 100%;
  }

  .upcoming-table .first-row {
    border-bottom: 1px solid #a3eb64;
    margin-bottom: 4px;
  }

  .upcoming-table .first-row th {
    padding-bottom: 4px;
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-table .second-row td {
    color: #223637;
    font-family: "Google Sans";
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 0.278vw;
  }

  .upcoming-table .second-row td:nth-child(2) p:first-child {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-table .second-row td:nth-child(2) p:nth-child(2) {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-table tbody tr td {
    padding: 8px;
  }

  .upcoming-table tbody tr:nth-child(even) {
    background-color: #f4ffea;
  }

  .upcoming-table thead tr th {
    padding: 8px;
  }

  .current-side {
    width: 35%;
  }

  .current-upper {
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 16px 24px 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 14px;
  }

  .current-upper h5 {
    color: #7f7f7f;
    text-align: center;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .current-upper h3 {
    color: #223637;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .waiting-tenant {
    border-radius: 8px;
    background: rgba(70, 177, 255, 0.1);
    padding: 12px;
    max-width: 180px;
    margin: 0 auto;
  }

  .waiting-tenant h4 span {
    color: var(--Dark-green, #253837);
    text-align: right;
    font-family: "Google Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 0px;
  }

  .waiting-tenant h4 {
    margin: 0;
  }

  .current-down {
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .current-down h4 {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .current-down h6 {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  .current-down p {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }

  .custom-gap {
    margin-bottom: 16px;
  }

  .edit-det-bt {
    border-radius: 0.833vw;
    border: 2px solid #a3eb64;
    padding: 1.389vw 2.778vw;
    background-color: transparent;
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .payment-detail-page {
    padding: 0 30px;
    margin-bottom: 63px;
  }

  /* tenant-flow */
  .paymentdetails-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .paymentdetails-body h5 {
    color: #223635;
    text-align: center;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }

  .middlestep {
    position: relative;
  }

  .middlestep::before {
    content: "";
    position: absolute;
    left: -180px;
    top: 50%;
    background-image: url("../../../public/images/bigwave.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 378px;
    height: 24px;
  }

  .custom-pb {
    padding-bottom: 45px;
  }

  .reviewandconform-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .payment-body {
    padding: 32px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    background: #ffffff;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 260px 362px;
  }

  .body-inner-sec {
    padding: 20px;
  }

  .body-inner-sec h6 {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .body-inner-sec p {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .body-inner-sec p:last-child {
    margin-bottom: 24px;
  }

  .payment-detail-content {
    width: 100%;
    border-radius: 8px;
    background: rgba(7, 86, 158, 0.1);
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.03);
    padding: 8px 12px;
    margin: 1.111vw 0 1.736vw 0;
  }

  .payment-detail-content p span {
    color: #223637;
    font-family: "Google Sans";
    font-size: 0.556vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 0.347vw;
  }

  .btn {
    padding: 10px 40px;
  }

  .tbtn-st {
    padding: 16px 40px;
    border-radius: 12px;
    border: 2px solid #a3eb64;
    background: transparent;
    width: 100%;
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .tenant-review {
    padding: 0 0px;
  }

  .tenant-review
    .primaryaccordion
    .primaryaccordion-header
    .accordion-button
    .maintitle {
    color: #223637;
    text-align: right;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .tenant-review-content {
    border-radius: 8px;
    border: 1px solid #a3eb64;
    background: #fff;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 50px;
  }

  .close-btn {
    position: absolute;
    right: 17px;
    top: 13px;
  }

  .review-content h4 {
    color: #223637;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .review-content p {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .detail-page-heading {
    color: #223635;
    text-align: center;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .back-header {
    display: flex;
    align-items: center;
    gap: 417px;
  }

  .detail-body {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .payment-text {
    color: #223637;
    font-family: "Google Sans";
    font-size: 1.806vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1.181vw;
  }

  .upcoming-box {
    border-radius: 0.556vw;
    border: 0.069vw solid #dddddd;
    background: #ffffff;
    padding: 10px;
    margin-bottom: 1.319vw;
    overflow: auto;
  }

  .upcoming-text {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .upcoming-side {
    width: 100%;
  }

  .upcoming-table {
    width: 100%;
  }

  .upcoming-table .first-row {
    border-bottom: 1px solid #a3eb64;
    margin-bottom: 4px;
  }

  .upcoming-table .first-row th {
    padding-bottom: 4px;
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-table .second-row td {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 4px;
  }

  .upcoming-table .second-row td:nth-child(2) p:first-child {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-table .second-row td:nth-child(2) p:nth-child(2) {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-table tbody tr td {
    padding: 8px;
  }

  .upcoming-table tbody tr:nth-child(even) {
    background-color: #f4ffea;
  }

  .upcoming-table thead tr th {
    padding: 8px;
  }

  .current-side {
    width: 100%;
  }

  .current-upper {
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 16px 24px 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 14px;
  }

  .current-upper h5 {
    color: #7f7f7f;
    text-align: center;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .current-upper h3 {
    color: #223637;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .waiting-tenant {
    border-radius: 8px;
    background: rgba(70, 177, 255, 0.1);
    padding: 12px;
    max-width: 180px;
    margin: 0 auto;
  }

  .waiting-tenant h4 span {
    color: var(--Dark-green, #253837);
    text-align: right;
    font-family: "Google Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 0px;
  }

  .waiting-tenant h4 {
    margin: 0;
  }

  .current-down {
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .current-down h4 {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .current-down h6 {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  .current-down p {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }

  .custom-gap {
    margin-bottom: 16px;
  }

  .edit-det-bt {
    border-radius: 12px;
    border: 2px solid #a3eb64;
    padding: 20px 40px;
    background-color: transparent;
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .payment-detail-page {
    padding: 0 30px;
    margin-bottom: 63px;
  }

  /* tenant-flow */
  .paymentdetails-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .paymentdetails-body h1 {
    color: #223635;
    text-align: center;
    font-family: "Google Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .paymentdetails-body h5 {
    color: #223635;
    text-align: center;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }

  .middlestep {
    position: relative;
  }

  .middlestep::before {
    content: "";
    position: absolute;
    left: -180px;
    top: 50%;
    background-image: url("../../../public/images/bigwave.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 378px;
    height: 24px;
  }

  .custom-pb {
    padding-bottom: 45px;
  }

  .reviewandconform-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .payment-body {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    background: #ffffff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reviewandconform-body h3 {
    color: #223637;
    text-align: right;
    font-family: "Google Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .payment-body h6 {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
  }

  .payment-body p {
    color: #223637;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  .payment-body p:last-child {
    margin-bottom: 40px;
  }

  .payment-body h5 {
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 0.833vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .change-payment {
    color: #7f7f7f;
    font-family: "Google Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: capitalize;
    border: 0;
    background: transparent;
    margin-top: 40px;
  }

  .payment-body input {
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--Grey, #7f7f7f);
    background: #ffffff;
    padding: 16px;
    height: 46px;
    margin-top: 4px;
  }

  .payment-body input:focus-visible {
    outline: 0;
  }

  .edit-payment-method button {
    border-radius: 12px;
    border: 2px solid #a3eb64;
    padding: 20px 40px;
    background-color: transparent;
    color: var(--Dark-green, #253837);
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .tenant-review-content svg {
    width: 100%;
  }

  .btn.btngreen.upbtn {
    position: relative;
    z-index: 555;
  }

  .custompb {
    padding-bottom: 29px;
  }

  .delete-link-btn {
    background: transparent;
    border-radius: 12px;
    border: 1px solid #a3eb64;
    padding: 8px;
    margin-right: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .delete-link-btn {
    background: transparent;
    border-radius: 12px;
    border: 1px solid #a3eb64;
    padding: 8px;
    margin-right: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .col-lg-6 {
    padding: 0;
  }

  .details-form .col-lg-12 {
    padding: 0;
  }

  #header .header-left {
    padding: 10px !important;
  }

  .second-aisde a {
    display: flex;
  }

  #header .header-left .firstaside {
    margin-bottom: 0;
    margin-inline-end: 4px;
  }

  .badge + img {
    height: 25.003px;
    width: 22px;
  }

  body .badge {
    height: 16.002px;
    width: 16.002px;
    top: -8.995px;
    left: 11.995px;
  }

  body #sub-header-links {
    margin-top: 76.005px !important;
  }

  /* --------------------new responsive css (3brain)----------------- */
  .tenant-badge-name.mobile {
    height: 54px;
    width: 54px;
    flex: 0 0 54px;
  }

  .tenant-badge-name {
    font-size: 22.005px;
  }
}

/* ..........for tab.......... */
@media (min-width: 1300px) and (max-width: 1600px) {
}

/* .............for dakstop.......... */
.dakstop-linkedinbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dakstop-scorebtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-profile-search-header {
  display: flex;
  flex-direction: row;
}

.add-property-form-box {
  justify-content: center;
}

.tenant-revisit-pdf-box {
  padding: 1.042vw;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.03) !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.more-doc-label {
  font-size: 1.25vw;
  line-height: 2.083vw;
}

.more-doc-label + .edit-status {
  margin-top: 0.556vw;
}

.upload-doc-plac-img {
  height: 2.75vw;
  width: 2.75vw;
}

.tenant-revisit-pdf-box h4 {
  color: #253837;
  text-align: center;
  font-size: 0.903vw;
  font-weight: 600;
  line-height: normal;
  padding-top: 0.903vw;
  margin-bottom: 0;
}

.tenant-revisit-pdf-box p {
  color: #7f7f7f;
  text-align: center;
  font-family: Poppins;
  font-size: 0.833vw;
  font-weight: 500;
  line-height: normal;
}

.list-style-disc li {
  list-style: disc;
}

.list-style-disc li::marker {
  color: #fff;
}

.clave-box-bottom.clave-box-setting .mobile-tenant-box {
  margin-bottom: 0.694vw !important;
}

.spacing-setting-wrapper .mobile-tenant-box {
  margin-bottom: 0;
}

.spacing-setting-wrapper .mobile-tenant-box .videoframe {
  height: 7.292vw;
}

.spacing-setting-wrapper .tenant-right-box {
  margin-top: 0;
}

.clave-box-setting .mobile-tenant-box.card {
  margin-bottom: 0;
}

.tenant-revisit-pdf-box label {
  margin-top: 0.972vw;
}

.tenant-revisit-pdf-box .btngreen {
  padding-block: 0.694vw;
  display: flex;
  align-items: center;
  gap: 0.694vw;
}

.tenant-revisit-pdf-box .btngreen img {
  width: 1.389vw;
  height: 1.389vw;
}

/* .tenant-left-box {
  line-break: anywhere;
} */
.tennat-eft-content-btn {
  display: block;
  color: #fff;
  font-family: Poppins;
  font-size: 1.111vw;
  font-weight: 500;
  padding-block-start: 1.25vw;
  text-align: center;
}

.tenant-left-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tenant-left-box img {
  width: 3.75vw;
  height: 3.75vw;
  /* margin-block-end: 0.694vw; */
}

.tenant-left-box-bottom .btngreen {
  padding: 0.833vw 1.25vw;
  display: flex;
  align-items: center;
  gap: 11px;
  text-transform: none;
}

.tenant-left-box-bottom .btngreen img {
  width: 0.833vw;
  height: 0.972vw;
}

.clave-box-bottom {
  padding-top: 1.667vw;
}

.clave-box-bottom h3 {
  color: #253837;
  font-family: Poppins;
  font-size: 1.25vw;
  font-weight: 600;
  margin-block-end: 1.25vw;
}

.tenant-right-box {
  margin: auto;
}

.tenant-light-box {
  /* opacity: 0.4; */
  background: #bbc1c1 !important;
}

.tenant-light-box .light-box-btn {
  /* opacity: 1; */
  background-color: #253837 !important;
  border-radius: 0.694vw;
  color: #a3eb64 !important;
}

.mobile-tenant-box iframe {
  width: 100%;
}

.mobile-tenant-box .videoframe iframe {
  height: 100%;
}

.mobile-tenant-box .videoframe {
  height: 11.111vw;
}

.mobile-tenant-box {
  box-shadow: 0vw 0.278vw 2.361vw 0.069vw rgba(0, 0, 0, 0.03);
}

.tenant-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}

.upload_doc_tooltip {
  position: absolute;
  right: 1.389vw;
  top: 1.042vw;
}

.tenant-card-footer .tenant-card-p {
  white-space: nowrap;
  width: 10.217vw;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.tenant-card-footer label img,
.reupload_img {
  margin-right: 10px;
  width: 1vw;
  height: 1vw;
}

.tenant-card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}

.mobile-tenant-box .leftbartext {
  flex: 0 0 100%;
}

body:has(.tenant-left-box) .tenant-right-box .row {
  justify-content: start;
}

.tenant-right-box .row {
  justify-content: center;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0.694vw;
}

.document-heading {
  margin-top: 0.556vw;
}

.grid-wrapper .col-md-4 {
  width: unset !important;
}

.grid-wrapper .calvepin-option-div.tenant-left-box {
  grid-row: span 2;
}

.grid-wrapper .tenant-revisit-pdf-box {
  height: unset !important;
}

.grid-wrapper .add-more-doc-card {
  background: #f6fdef !important;
  border-radius: 0.556vw;
  padding: 30px;
  height: 12.569vw;
}

.grid-wrapper .btn.btngreen.edit-status {
  background: #253837 !important;
  color: #fff !important;
  height: unset !important;
}

.grid-wrapper .add-more-doc-card .more-doc-label {
  font-size: 1.3vw;
  font-weight: 600;
}

.grid-wrapper .upload-btn {
  padding: 0.347vw 1.389vw;
  padding-block: 0.347vw;
}

.mobile-tenant-box.card {
  min-height: 12.569vw;
}
